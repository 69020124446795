import * as React from 'react';
import PropTypes from 'prop-types';
import {
    TimePicker,
} from '@mui/x-date-pickers-pro';
import {TextField} from "@mui/material";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import i18n from "../../../i18n/i18n";


// ======================================================================
// Date Filter Operators
// ======================================================================
function buildApplyDateFilterFn(filterItem, compareFn, showTime = false) {
    if (!filterItem.value) {
        return null;
    }

    const filterValueMs = filterItem.value.getTime();

    return ({value}) => {
        if (!value) {
            return false;
        }

        // Make a copy of the date to not reset the hours in the original object
        const dateCopy = new Date(value);
        dateCopy.setHours(
            showTime ? value.getHours() : 0,
            showTime ? value.getMinutes() : 0,
            0,
            0,
        );

        const cellValueMs = dateCopy.getTime();

        return compareFn(cellValueMs, filterValueMs);
    };
}

export function getTimeFilterOperators(showTime = false) {
    return [
        {
            value: 'is',
            getApplyFilterFn: (filterItem) => {
                return buildApplyDateFilterFn(
                    filterItem,
                    (value1, value2) => value1 === value2,
                    showTime,
                );
            },
            InputComponent: GridFilterTimeInput,
            InputComponentProps: {showTime},
        },
        {
            value: 'not',
            getApplyFilterFn: (filterItem) => {
                return buildApplyDateFilterFn(
                    filterItem,
                    (value1, value2) => value1 !== value2,
                    showTime,
                );
            },
            InputComponent: GridFilterTimeInput,
            InputComponentProps: {showTime},
        },
        {
            value: 'after',
            getApplyFilterFn: (filterItem) => {
                return buildApplyDateFilterFn(
                    filterItem,
                    (value1, value2) => value1 > value2,
                    showTime,
                );
            },
            InputComponent: GridFilterTimeInput,
            InputComponentProps: {showTime},
        },
        {
            value: 'onOrAfter',
            getApplyFilterFn: (filterItem) => {
                return buildApplyDateFilterFn(
                    filterItem,
                    (value1, value2) => value1 >= value2,
                    showTime,
                );
            },
            InputComponent: GridFilterTimeInput,
            InputComponentProps: {showTime},
        },
        {
            value: 'before',
            getApplyFilterFn: (filterItem) => {
                return buildApplyDateFilterFn(
                    filterItem,
                    (value1, value2) => value1 < value2,
                    showTime,
                );
            },
            InputComponent: GridFilterTimeInput,
            InputComponentProps: {showTime},
        },
        {
            value: 'onOrBefore',
            getApplyFilterFn: (filterItem) => {
                return buildApplyDateFilterFn(
                    filterItem,
                    (value1, value2) => value1 <= value2,
                    showTime,
                );
            },
            InputComponent: GridFilterTimeInput,
            InputComponentProps: {showTime},
        },
        // {
        //     value: 'isEmpty',
        //     getApplyFilterFn: () => {
        //         return ({ value }) => {
        //             return value == null;
        //         };
        //     },
        //     requiresFilterValue: false,
        // },
        // {
        //     value: 'isNotEmpty',
        //     getApplyFilterFn: () => {
        //         return ({ value }) => {
        //             return value != null;
        //         };
        //     },
        //     requiresFilterValue: false,
        // },
    ];
}

// ======================================================================
// Custom Date / Datetime filter field
// =====================================================================

export function GridFilterTimeInput(props) {
    const {item, applyValue} = props;

    const handleFilterChange = (newValue) => {
        if (!newValue || newValue == 'Invalid Date') {
            return;
        }
        const delayDebounceFn = setTimeout(() => {
            applyValue({...item, value: newValue});
        }, 200)
        return () => clearTimeout(delayDebounceFn)
    }

    const handleChangeDebounce = async (date) => {
        await searchAPIDebounced(date);
    };
    const searchAPIDebounced = AwesomeDebouncePromise(handleFilterChange, 200);

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
                sx={{color: 'rgba(0, 0, 0, 0.87'}}
                label={i18n.t('TIME')}
                ampm={false}
                value={item.value}
                onChange={(newValue) => {
                    try {
                        handleChangeDebounce(newValue)
                    } catch (e) {
                        console.error(e?.message);
                    }
                }}
                renderInput={(params) => <TextField size="small" {...params} />}
            />
        </LocalizationProvider>
    );
}

GridFilterTimeInput.propTypes = {
    apiRef: PropTypes.shape({
        current: PropTypes.object,
    }),
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        columnField: PropTypes.string.isRequired,
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        operatorValue: PropTypes.string,
        value: PropTypes.any,
    }).isRequired,
    showTime: PropTypes.bool,
};

