import {fork } from 'redux-saga/effects';
import {watchFetchData, watchFetchDataComplete, watchFetchTabs, watchSetTab} from './datatable';
import {watchGpsData} from "./gps";

/**
 * Combine all your sagas here
 * @returns {Generator<*, void, *>}
 */
export default function* rootSaga() {
    yield fork(watchFetchTabs)
    yield fork(watchFetchData)
    yield fork(watchSetTab)
    yield fork(watchGpsData)
    yield fork(watchFetchDataComplete)
}