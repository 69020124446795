import Grid from "@mui/material/Grid2";
import React, {useState} from "react";
import Map from "../../../Map/MapPtv";
import {CardActions, Divider, Typography} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import {MarkerInfo} from "../../../Map/Interfaces/MapInterfaces";
import i18n from "../../../../i18n/i18n";
import InfoIcon from "../../../Controls/InfoIcon";

interface SelectedLocation {
    longitude: number;
    latitude: number;
}

interface MapLocationDialogProps {
    locations?: Location[];
    handleLocationVerified: (verified: SelectedLocation) => void;
    handleClose: () => void;
}

export default function MapLocationDialog({
                                              locations = [],
                                              handleLocationVerified,
                                              handleClose
                                          }: MapLocationDialogProps) {
    const [selectedLocationIndex, setSelectedLocationIndex] = useState<number | null>(null);
    const [marker, setMarker] = useState<MarkerInfo[] | undefined>([]);

    const handleWaypointSelected = (waypoint: any, action: string) => {
        setMarker(waypoint);
        if (action === "markerPlacement") {
            setSelectedLocationIndex(null)
        }
        handleLocationVerified(waypoint)
        setMarker([waypoint])
    };


    return (
        <>
            <Grid container spacing={2}>
                <Grid size={8} sx={{border: '1px solid grey', height: 500}}>
                    <Map
                        // mapOverlay={['traffic-patterns', 'truck-restrictions', 'low-emission-zones', 'toll']}
                        markers={marker}
                        mapControls={['scale', 'navigation']}
                        isRoute={false}
                        rightClickOptions={{
                            allowContextMenu: true,
                            contextMenuOptions: {
                                allowMarkerPlacement: true,
                            },
                        }}
                        onContextMenuAction={handleWaypointSelected}
                    />
                </Grid>
                <Grid size={4} sx={{maxHeight: 500, overflowX: 'auto'}}>
                    <Typography variant="h6">
                        {i18n.t('TITLE_MAP_VERIFY_LOCATION_TITLE')}
                        <InfoIcon disabled={false} title={i18n.t('TOOLTIP_LOCATION_INFO')}/>
                    </Typography>
                    <Typography variant="caption">
                        {i18n.t('TITLE_MAP_VERIFY_LOCATION_DESCRIPTION')}
                    </Typography>
                    {
                        locations && locations?.map((item: any, i) => {
                            const isSelected = selectedLocationIndex === i;
                            const noSelection = selectedLocationIndex === null || selectedLocationIndex === undefined;
                            const colorSelected = isSelected ? 'white' : 'secondary.typography';
                            return (
                                <Card
                                    key={i}
                                    sx={{
                                        marginBottom: 1,
                                        marginTop: 1,
                                        transition: 'transform 0.3s, opacity 0.3s',
                                        opacity: isSelected || noSelection ? 1 : 0.7,
                                        border: isSelected ? '2px solid #EE6E2C' : '1px solid grey',
                                        boxShadow: isSelected ? '0px 4px 15px rgba(0, 0, 0, 0.2)' : 'none',
                                        backgroundColor: isSelected ? ' #EE6E2C' : 'transparent',
                                    }}
                                >
                                    <CardContent sx={{paddingBottom: 0}}>
                                        <Typography variant="body2" sx={{color: colorSelected}}>
                                            {[item?.street_name, item?.house_no, item?.house_no_addition].filter(Boolean).join(' ')}
                                        </Typography>
                                        <Typography variant="body2" sx={{color: colorSelected}}>
                                            {[item?.postal_code, item?.city].filter(Boolean).join(' ')}
                                        </Typography>
                                        <Typography variant="body2" sx={{color: colorSelected}}>
                                            {item?.country}
                                        </Typography>
                                    </CardContent>
                                    <CardActions sx={{margin: 0}}>
                                        {
                                            !isSelected && (
                                                <Button
                                                    size={'small'}
                                                    variant={'outlined'}
                                                    onClick={() => {
                                                        handleWaypointSelected({
                                                            longitude: item.longitude,
                                                            latitude: item.latitude,
                                                            color: 'blue'
                                                        }, 'button')
                                                        setSelectedLocationIndex(i);
                                                    }}
                                                >
                                                    {i18n.t('BUTTON_CHOOSE_LOCATION')}
                                                </Button>
                                            )
                                        }
                                    </CardActions>
                                </Card>
                            );
                        })
                    }
                </Grid>
            </Grid>
            <Button onClick={handleClose} variant={"contained"} sx={{
                float: 'right',
                marginTop: 1
            }}>{i18n.t('BUTTON_CLOSE')}</Button>
        </>
    )
}