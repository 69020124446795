import {createStore, applyMiddleware, combineReducers} from 'redux';
import { composeWithDevTools } from '@redux-devtools/extension';
import dataReducer from './reducers/dataTable';
import authReducer from './reducers/auth';
import gpsReducer from './reducers/gps';
import registerReducer from './reducers/register';
import templateAuditReducer from './reducers/templateAudit';
import resetPasswordReducer from './reducers/resetPassword';
import createSagaMiddleware from 'redux-saga';
import rootSaga from "./sages/sagas";
import { thunk } from 'redux-thunk';

const sagaMiddleware = createSagaMiddleware();

const composeEnhancers = composeWithDevTools({
    name: `Redux`,
    realtime: true,
    trace: process.env.REACT_APP_ENV === 'Development' ? true : false,
    traceLimit: 25
});

const rootReducer = combineReducers({
    data: dataReducer,
    gps: gpsReducer,
    auth: authReducer,
    reg: registerReducer,
    templateAudit: templateAuditReducer,
    resetPassword: resetPasswordReducer
});

const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(thunk, sagaMiddleware))
);

sagaMiddleware.run(rootSaga);

export default store;