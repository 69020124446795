import PropTypes from "prop-types";
import {Chip, ChipProps} from "@mui/material";
import React from "react";
import {Tooltip} from '@mui/material';

interface Props {
    origin: string;
}

export default function DataOriginChip(props: Props) {

    let titleTranslation: ChipProps['label'];
    let titleTooltip: React.ReactNode;
    let color: ChipProps['color'];

    switch (props.origin) {
        case 'vihb':
            titleTranslation = 'Niwo'
            titleTooltip = "Deze locatie komt uit openbare VIHB-registratie (NIWO)";
            color = 'secondary';
            break;
        case 'relations':
            titleTranslation = 'Relaties'
            titleTooltip = "Deze locatie komt uit uw eigen relaties";
            color = 'info';
            break;
        case 'companies':
            titleTranslation = 'FormKlub'
            titleTooltip = "Deze locatie komt uit geregistreerde bedrijven binnen FormKlub";
            color = 'primary';
            break;
        case 'weighBridge':
            titleTranslation = 'Weegbrug'
            titleTooltip = "Deze locatie komt uit onze lijst van gekoppelde weegbruggen";
            color = 'success';
            break;
        default:
            titleTranslation = 'Onbekend'
            titleTooltip = "Onbekend";
            color = 'default';
            break;
    }

    return (
        <Tooltip title={titleTooltip}>
            <Chip color={color} label={titleTranslation}/>
        </Tooltip>
    )
}

DataOriginChip.propTypes = {
    origin: PropTypes.string,
}