import {
    getGridBooleanOperators,
    getGridNumericOperators,
    getGridSingleSelectOperators,
    getGridStringOperators
} from "@mui/x-data-grid-premium";
import CustomSingleSelectFilter from "../../components/DataTable/Filters/CustomSingleSelectFilter";
import CustomColorSelectFilter from "../../components/DataTable/Filters/CustomColorSelectFilter";
import {getDateFilterOperators} from "../../components/DataTable/Filters/CustomDateFilters";
import {formatUTCOrLocalDate} from "../../shared/dateHelper";
import {getTimeFilterOperators} from "../../components/DataTable/Filters/CustomTimeFilters";
import React from "react";
import {renderEditLicensePlate, renderLicensePlate} from "../../components/DataTable/CellRenderers/LicensePlate";
import {renderCheckbox, renderEditCheckbox} from "../../components/DataTable/CellRenderers/Checkbox";
import {renderEditDate} from "../../components/DataTable/CellRenderers/Date";
import {renderEditTime} from "../../components/DataTable/CellRenderers/Time";
import {renderColorIndicator} from "../../components/DataTable/CellRenderers/ColorIndicator";
import {renderEditSingleSelect} from "../../components/DataTable/CellRenderers/SelectDropdown";
import {renderEditStateChip} from "../../components/DataTable/CellRenderers/StateChip";
import {renderEditDialogSearch} from "../../components/DataTable/CellRenderers/DialogSearch";


/**
 * Helper function go get nested value
 * @param obj
 * @param path
 * @returns {undefined|*}
 */
export function getValueByPath(obj, path) {
    const pathArray = path.split('.');
    let value = obj;

    for (const key of pathArray) {
        if (value && typeof value === 'object' && key in value) {
            value = value[key];
        } else {
            return undefined;
        }
    }
    return value;
}

/**
 * Helper function to apply exclusions to operators
 * @param operators
 * @param exclusions
 * @returns {*}
 */
const filterOperators = (operators, exclusions) =>
    operators.filter(operator => !exclusions.includes(operator.value));


/**
 * Set filteroperators
 * @param column
 */
export const setFilterOperators = (column) => {
    // Early exit if the column is not filterable
    if (!column.filterable) return;

    // Define exclusion logic per type
    const exclusions = {
        string: ['startsWith', 'endsWith', 'isAnyOf', 'doesNotContain', 'doesNotEqual'],
        number: ['startsWith', 'endsWith', 'isAnyOf'],
        date: ['startsWith', 'endsWith', 'isEmpty', 'isNotEmpty', 'isAnyOf'],
        boolean: ['startsWith', 'endsWith', 'isEmpty', 'isNotEmpty', 'isAnyOf'],
    };

    // Retrieve the appropriate operators based on the column type
    switch (column.type) {
        case 'string':
            column.filterOperators = filterOperators(getGridStringOperators(), exclusions.string);
            break;
        case 'number':
            column.filterOperators = filterOperators(getGridNumericOperators(), exclusions.number);
            break;
        case 'date':
            column.filterOperators = filterOperators(getDateFilterOperators(), exclusions.date);
            break;
        case 'boolean':
            column.filterOperators = filterOperators(getGridBooleanOperators(), exclusions.boolean);
            break;
        case 'dateTime':
            column.filterOperators = getDateFilterOperators();
            column.valueGetter = formatUTCOrLocalDate;
            break;
        default:
            if (column.renderType === 'time') {
                column.filterOperators = getTimeFilterOperators(true);
            }
            break;
    }
};

/**
 * Function to handle filterField adjustments
 * @param column
 */
export const handleFilterField = (column) => {
    if ((column.filterField) && column.filterField !== column.field) {
        const oldFieldName = column.field;

        if (column.filterField) {
            column.field = column.filterField;
        }
        column.valueGetter = (_params, row) => getValueByPath(row, oldFieldName);
    }
};

/**
 * Function to handle single select filter operators
 * @param column
 * @param index
 * @param tableFilters
 */
export const setSingleSelectFilterOperators = (column, index, tableFilters) => {
    if (column.type === 'singleSelect' && column.filterable) {
        column.filterOperators = getGridSingleSelectOperators().map((operator) => ({
            ...operator,
            InputComponent: operator.InputComponent ? typeof CustomSingleSelectFilter !== 'undefined' ? CustomSingleSelectFilter : undefined : undefined,
            InputComponentProps: {extra: {column}, tableFilters}
        }));
    }
};

/**
 * Function to handle custom color select filter
 * @param column
 * @param index
 */
export const setCustomColorSelectFilter = (column, index) => {
    if (column.field === 'show_color' && column.type === 'singleSelect' && column.filterable) {
        column.filterOperators = getGridSingleSelectOperators()
            .map((operator) => (
                {
                    ...operator,
                    InputComponent: operator.InputComponent ? typeof CustomColorSelectFilter !== 'undefined' ? CustomColorSelectFilter : undefined : undefined,
                    InputComponentProps: {extra: {column}, index: index}
                }
            ))
    }
};

/**
 *
 * @param column
 */
export const getNestedValues = (column) => {
    if (column.field.includes(".")) {
        column.valueGetter = (value, row) => {
            return getValueByPath(row, column.field);
        };
    }
};

/**
 *
 * @param column
 */
export const setAggregableFields = (column) => {
    const aggregableFields = ['tours', 'weight', 'cubic', 'netWeight', 'netCubic', 'overload'];

    if (!column.aggregable || !aggregableFields.includes(column.field)) return;

    column.headerAlign = column.headerAlign || 'right';

    column.valueFormatter = (value) => {
        const decimalPlaces = column.decimal || 0;
        return Number(value).toFixed(decimalPlaces).replace(".", ",");
    };

    column.valueGetter = (value, row) => {
        if (!value) return null;

        return typeof row.id === 'number'
            ? Number(row.formattedValue || value) // Render for tableCell
            : <span style={{color: '#B53F00', fontWeight: 500}}>{row.formattedValue || value}</span>; // Value in table footer
    };
};

/**
 *
 * @param column
 */
export const setPercentageFields = (column) => {
    const percentageFields = ['overload_percentage', 'load_factor', 'overloadPercentage', 'loadFactor'];

    if (!percentageFields.includes(column.field)) return;

    column.renderCell = ({row, value}) => {
        const overloadPercentage = Number(row.overload_percentage || row.overloadPercentage);
        const decimalPlaces = column.decimal || 1;
        const formattedValue = value ? Number(value).toFixed(decimalPlaces).replace(".", ",") : value;

        let color = '#000000';
        let fontWeight = 'normal';

        if (overloadPercentage > 0.1) {
            if (overloadPercentage <= 5) {
                color = '#ED6C02';
            } else {
                color = '#D32F2F';
            }
            fontWeight = 'bold';
        }

        return (
            <span style={{color, fontWeight}}>
                {formattedValue}{formattedValue ? '%' : null}
            </span>
        );
    };
};

/**
 *
 * @param column
 * @param tableFilters
 */
export const setGroupOnColumn = (column, tableFilters) => {
    if (!column.groupable) return;

    const currentColumn = column.filterField;

    column.groupingValueGetter = (value) => {
        const filterValue = tableFilters?.[currentColumn]?.[value];
        return filterValue || value || '';
    };

    column.renderCell = ({rowNode, field, value}) => {
        // Check if this is a group row and if the field matches the grouping field
        if (rowNode.type === 'group' && field === rowNode.groupingField) {
            return '';
        }
        return value;
    };

    if (column.field === 'id') {
        column.valueSetter = (value) => value;
    }
};

/**
 *
 * @param column
 */
export const setRenderFields = (column) => {
    //LicensePlate
    if (column.field === 'license_plate' || column.field === 'licensePlate' || column.field === 'license_plate_trailer' || column.field === 'licensePlateTrailer') {
        column.renderCell = renderLicensePlate
    }
    //Checkbox
    if (column.renderType === 'checkbox') {
        column.renderCell = renderCheckbox
    }
    //Color indicator
    if (column.renderType === 'colorIndicator') {
        column.renderCell = renderColorIndicator
    }
}

/**
 *
 * @param column
 * @param index
 */
export const setRenderEditFields = (column, index) => {

    if (!column.editable) {
        return;
    }

    if ((column.field === 'license_plate' || column.field === 'licensePlate' || column.field === 'license_plate_trailer' || column.field === 'licensePlateTrailer')) {
        column.renderEditCell = renderEditLicensePlate
    }

    if (column.renderType === 'checkbox') {
        column.renderEditCell = renderEditCheckbox
    }

    if ((column.type === 'date' || column.type === 'datetime')) {
        column.renderEditCell = renderEditDate
    }

    if (column.renderType === 'time') {
        column.renderEditCell = renderEditTime
    }

    // Default Single Select
    if (column.type === 'singleSelect') {
        column.renderEditCell = renderEditSingleSelect
    }

    if ((column.field === 'state' || column.field === 'state_id')) {
        column.renderEditCell = (params) => renderEditStateChip(params, index)
    }

    if (column.renderType === 'inlineEditSearchVIHB') {
        column.renderEditCell = renderEditDialogSearch
    }
}

