import * as actionsTypes from '../actions/actionTypes'
import {updateObject} from "../utility";
import update from "immutability-helper";

const initialState = {
    token:null,
    user: {
        email : null,
        given_name : null,
        family_name: null,
        name:null,
        sub :null,
        roles:null,
        isAdmin: false,
    },
    error:null,
    loading:null,
    message:null,
    action:null,
    authRedirectPath: '/',
    scopes: {
        loginAllowed:false
    }
}
const setAuthRedirectPath =(state, action) => {
    return updateObject(state, {authRedirectPath: action.path})
}

const authStart = (state) => {
    return updateObject(state, {error:null,loading:true});
}
const authSuccess = (state, action) => {
    return updateObject(state, {
        token: action.token,
        error:null,
        loading:false,
    });
}
const initAuthUpdate = (state) => {
    return updateObject(state, {error:null,loading:false,message:false});
}

const startUpdateAccount = (state) => {
    return updateObject(state, {error:null,loading:true});
}

const accountUpdated = (state, action) => {
    return updateObject(state, {error:null,loading:false,action: action.action, message: action.message});
}

const ErrorUpdateAccount = (state, action) => {
    return updateObject(state, {error:action.error,loading:false});
}

const authIdentity = (state, action) => {
    let isAdmin = false;
    if(action.jwt !== undefined && action.jwt.roles !== undefined) {
        action.jwt.roles.split(' ').map((role) => {
            if(role === 'admin'){
                isAdmin = true;
            }
        })
    }

    const jwt = { ...action.jwt, ...{ "isAdmin" : isAdmin }}

    return updateObject(state, {
        user: jwt,
    });
}


const authFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading:false
    });
}

const authLogout = ()=>{
    return initialState;
}

const setAuthScopes = (state, action) => {
    return updateObject(state, {
        scopes: action.scopes,
    });
}

const setScopeData = (state, action) => {
    const object = action.data
    const index = action.scope
    return update(state, {scopes: {[index]: {$merge:object}}});
}


const resourceRegistered = (state, action) => {
    const index = action.scope
    const resource = action.resource
    const isActive = action.isActive
    const initialized = action.initialized;
    const loginAllowed = action.loginAllowed;

    let object = action.data
    let registered = true;

    if(!isActive){
        registered = false;
        object = null;
    }
    let extraFields = {}
    if(action.extraFields){
        extraFields = action.extraFields
    }
    return update(state, {scopes: {[index]: {$merge: {data : object , registered: registered, resource: resource,initialized: initialized, isActive: isActive,loginAllowed:loginAllowed, ...extraFields}}}});
}

const resourceRegisteredFailed = (state, action) => {
    const object = {}
    object.registered = false;
    object.initialized = true;
    object.loginAllowed = false;
    object.introspect = false;

    const scopes =  updateObject(state.scopes,{ [action.scope] : object})

    return updateObject(state, {
        scopes: scopes
    });
}

const resetLogin = (state, action) => {
    const path = action.path

    return update(state , {$merge : {token: null,authRedirectPath: path,error:null, accountVerified: false,loginAllowed:false, user: null,scopes:[]}});
}

const setActiveCompany = (state, action) => {
    const object = action.data
    return update(state, {scopes: {['PEER']: {$merge:object}}});
}

const selectRegions = (state, action) => {
    const object = action.data
    return update(state, {scopes: {['PEER']: {$merge:object}}});
}

const selectProjects = (state, action) => {
    const object = action.data
    return update(state, {scopes: {['PEER']: {$merge:object}}});
}

const setRights = (state, action) => {
    const object = action?.data
    const newArray = [];

    if (object?.items) {
        object.items.map(item => {
            const newRow = {};
            newRow.state_id = item.state_id;
            newRow.company_id = item.company_id;
            newRow.region_id = item.region_id;
            newRow.project_id = item.project_id;
            newRow.client_id = item.client_id;
            newRow.template_id = item?.template_id;
            newRow.item_name = item.item_name;
            newRow.authItemChild = item.authItemChilds
            newArray.push(newRow)
        })
    }
    return update(state, {scopes: {['PEER']: {auth: {$set: newArray}}}});
}

const reducer = (state = initialState,action) => {
    switch (action.type){
        case actionsTypes.AUTH_START: return authStart(state,action);
        case actionsTypes.AUTH_SUCCESS: return authSuccess(state,action);
        case actionsTypes.AUTH_FAIL: return authFail(state,action);
        case actionsTypes.AUTH_LOGOUT: return authLogout(state,action);
        case actionsTypes.SET_AUTH_REDIRECT_PATH: return setAuthRedirectPath(state,action);
        case actionsTypes.AUTH_SET_SCOPES: return setAuthScopes(state,action);
        case actionsTypes.AUTH_SET_SCOPE_DATA: return setScopeData(state,action);
        case actionsTypes.AUTH_IDENTITY: return authIdentity(state,action);
        case actionsTypes.SET_AUTH_RESOURCE_REGISTERED: return resourceRegistered(state,action);
        case actionsTypes.SET_AUTH_RESOURCE_REGISTERED_FAILED: return resourceRegisteredFailed(state,action);
        case actionsTypes.AUTH_RESET_LOGIN: return resetLogin(state,action);
        case actionsTypes.SET_ACTIVE_COMPANY: return setActiveCompany(state, action);
        case actionsTypes.SET_SELECTED_REGIONS: return selectRegions(state, action);
        case actionsTypes.SET_SELECTED_PROJECTS: return selectProjects(state, action);
        case actionsTypes.INIT_AUTH_UPDATE: return initAuthUpdate(state, action);
        case actionsTypes.START_UPDATE_ACCOUNT: return startUpdateAccount(state, action);
        case actionsTypes.USER_UPDATED: return accountUpdated(state, action);
        case actionsTypes.PASSWORD_CHANGE_FAIL: return ErrorUpdateAccount(state, action);
        case actionsTypes.SET_AUTH_ITEM_CHILD: return setRights(state, action);
        default: return state;
    }
}

export default reducer;