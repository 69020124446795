import React, {useEffect, useState} from 'react';
import { styled } from '@mui/material/styles';
import {FixedSizeList as List} from "react-window";
import Grid from "@mui/material/Grid2";
import SearchBar from "../../../Controls/SearchBar";
import PropTypes from "prop-types";
import axios from "../../../../api/axios-peer";
import {fetchDataFail} from "../../../../store/actions/dataTableAction";
import {useDispatch, useSelector} from "react-redux";
import {Typography} from "@mui/material";
import {getScopeFormklub} from "../../../../store/selectors/scopeSelector";

const PREFIX = 'SearchProcessingMethodDialog';

const classes = {
    rowEven: `${PREFIX}-rowEven`,
    rowOdd: `${PREFIX}-rowOdd`,
    emptyContainer: `${PREFIX}-emptyContainer`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() => ({
    [`& .${classes.rowEven}`]: {
        background: "#F5F5F5",
        padding: '20px',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        cursor: 'pointer',
        "& .MuiSvgIcon-root": {color: "rgba(0, 0, 0, 0.54)",},
        '&:hover': {
            backgroundColor: '#D2D8DD',
            "& .MuiSvgIcon-root": {color: "#203A56",},
        },
    },

    [`& .${classes.rowOdd}`]: {
        background: "#FFFFFF",
        padding: '20px',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        cursor: 'pointer',
        "& .MuiSvgIcon-root": {color: "rgba(0, 0, 0, 0.54)",},
        '&:hover': {
            backgroundColor: '#D2D8DD',
            "& .MuiSvgIcon-root": {color: "#203A56",},
        }
    },

    [`& .${classes.emptyContainer}`]: {
        background: "#F5F5F5",
        padding: '20px',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        height: '100px',
        marginTop: '20px',
    }
}));

export default function SearchProcessingMethodDialog(props) {

    const dispatch = useDispatch();

    const [searchInput, setSearchInput] = useState('');
    const [emptylist, setEmptyList] = useState(false);
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState(data);
    const resourceFormklub = useSelector(state => getScopeFormklub(state))

    // ======================
    // Get Data
    // ======================
    useEffect(() => {
        let isMounted = true;
        axios({
            url: 'processing-method', baseURL: resourceFormklub.resource?.defaults.baseURL,
        }).then(response => {
            if (isMounted) setData(response.data);
        }).catch(error => {
            dispatch(fetchDataFail(error.message))
        })
        return () => {
            isMounted = false
        };
    }, [])


    // =====================
    // Handle Selected Company
    // =====================
    const onItemClickHandler = (selectedItem) => {
        props.handleProcessingMethod(selectedItem)
        setFilteredData(data);
    }

    // =====================
    // Handle Search
    // =====================
    const handleChange = (searchInput) => {

        setSearchInput(searchInput);
        if(!data.length){
            return;
        }
        //Filtered data
        const result = data.filter((item) => {
            if (item.code === null || item.description === null) {
                return
            }
            return item.code.toLocaleLowerCase().includes(searchInput) || item.description.toLocaleLowerCase().includes(searchInput);
        });

        setFilteredData(result);
    }
    const clearFilter = () => {
        setFilteredData(data);
        setEmptyList(false)
    }

    // =====================
    // Check if list is empty
    // =====================
    useEffect(() => {
        if (filteredData === undefined || filteredData === null) {
            return
        }
        if (filteredData.length === 0 && searchInput.length > 0) {
            setEmptyList(true);
        } else {
            setEmptyList(false);
        }
    }, [searchInput])

    // =====================
    // Filtered array
    // =====================
    useEffect(() => {
        if (data === undefined || data === null) {
            return
        }
        setFilteredData(data)
    }, [data])

    // =====================
    // Company rows
    // =====================

    const list = filteredData;

    const renderRow = ({index, style}) => (
        <div>
            <div key={list[index].id} style={style} className={index % 2 === 0 ? classes.rowEven : classes.rowOdd}
                 onClick={onItemClickHandler.bind(this, list[index])}>
                <Typography variant="body1"><strong>Code:</strong> {list[index].code}</Typography>
                <Typography variant="body1"><strong>Omschrijving:</strong> {list[index].description}</Typography>
            </div>
        </div>
    );

    return (
        <Root>
            <Grid container>
                <Grid size={12}>
                    <SearchBar
                        handleChange={handleChange}
                        clearFilter={clearFilter}
                    />
                </Grid>
            </Grid>
            {
                emptylist ? (
                    <div>
                        <div className={classes.emptyContainer}>
                            <Typography variant="body2">Geen resultaten gevonden</Typography><br/>
                        </div>
                    </div>
                ) : (
                    <List
                        height={700}
                        itemCount={list ? list.length : null}
                        itemSize={100}
                    >
                        {renderRow}
                    </List>
                )
            }
            <br/>
        </Root>
    );
}

SearchProcessingMethodDialog.propTypes = {
    handleProcessingMethod: PropTypes.func,
    data: PropTypes.array,
};