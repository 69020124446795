import React, {useCallback, useEffect, useRef, useState} from 'react';
import ReactMapGL, {Layer, LayerProps, MapEvent, Source} from 'react-map-gl';
import {HandlePopup, MapLayer, MapProps, MarkerInfo} from "./Interfaces/MapInterfaces";
import Controls from "./Components/Controls";
import OverlayControl from "./Components/OverlayControl";
import Markers from './Components/Markers';
import PopupComponent from "./Components/Popup";
import {fetchRoute} from "../../store/actions/PtvMapActions";
import {getActiveCompanyPtvApi} from "../../store/selectors/companySelector";
import {useSelector} from "react-redux";
import {dataEdited} from "../../store/selectors/actionsRightsSelector";

const MAP_STYLE_URL = "https://vectormaps-resources.myptv.com/styles/latest/standard.json";
const lineLayerStyle: LayerProps = {
    id: 'route',
    type: 'line',
    paint: {
        'line-color': '#2882C8',
        'line-width': 5
    }
};
const Map: React.FC<MapProps> = (props) => {
    const apiKey = useSelector(state => getActiveCompanyPtvApi(state));
    const [viewport, setViewport] = useState(props.viewport || {
        longitude: 4.4930,
        latitude: 52.1585,
        zoom: 8,
        pitch: 0,
        bearing: 0,
    });
    const mapRef = useRef<any>(null);
    const [initialized, setInitialized] = useState(false);
    const [mapStyle, setMapStyle] = useState(MAP_STYLE_URL);
    const [popupInfo, setPopupInfo] = useState<MarkerInfo | null>(null);
    const [routePolyline, setRoutePolyline] = useState<any>(null); // Store polyline as GeoJSON
    const [waypoints, setWaypoints] = useState<MarkerInfo[]>(props.markers || []); // Store polyline as GeoJSON

    /**
     * Initialize Map
     */
    const transformRequest = useCallback(
        (url?: string, resourceType?: string): { url: string; headers: { ApiKey?: string } } => {
            let requiredUrl = url || "";

            if (resourceType && resourceType === "Tile") {
                if (url?.includes("trafficPatterns")) {
                    requiredUrl += "&referenceTime=" + new Date().toISOString();
                }
                return {
                    url: requiredUrl,
                    headers: {ApiKey: " " + apiKey},
                };
            }

            return {url: requiredUrl, headers: {}};
        },
        []
    );

    /**
     * Handle popup visibility
     * @param value
     */
    const handlePopup: HandlePopup = (value: MarkerInfo | null) => {
        setPopupInfo(value)
    }

    /**
     * Initialize Events
     */
    const initializeEvents = () => {
        // const map: any | undefined = mapRef.current?.getMap();
        // const style = map?.getStyle();
        setInitialized(true)
    };

    /**
     * Update Overlay layers
     * @param overlays
     */
    const updateOverlays = (overlays: string[]) => {
        const map: any | undefined = mapRef?.current?.getMap();
        if (!map) {
            return;
        }
        const style = map?.getStyle();

        style?.layers.forEach((layer: MapLayer) => {
            if (layer?.metadata && layer.metadata["ptv:layer-group"]) {
                map?.setLayoutProperty(
                    layer.id,
                    'visibility',
                    overlays.includes(layer.metadata["ptv:layer-group"]) ? "visible" : "none"
                );
            }
        });
    }

    /**
     * Render overlay Popup
     */
    const overlayPopup = () => {
        if (initialized && (props.mapOverlay && props.mapOverlay.length)) {
            return <OverlayControl onChange={updateOverlays} mapOverlay={props.mapOverlay}/>
        }
        return null
    }

    /**
     * Get routing info
     */
    useEffect(() => {
        if (!props.isRoute) {
            return;
        }
        const fetchRouteData = async () => {
            try {
                const routeInfo = await fetchRoute(waypoints, apiKey);
                if (routeInfo) {
                    setRoutePolyline(JSON.parse(routeInfo.polyline));
                    console.log(`Travel time: ${routeInfo.travelTime}`);
                    console.log(`Distance: ${routeInfo.distance}`);
                }
            } catch (error) {
                console.error('Error fetching route data:', error);
            }
        };

        fetchRouteData();

    }, []);

    /**
     * Set markers for map
     */
    useEffect(() => {
        if (props.markers && props.markers.length === 1) {
            const waypoint = props.markers[0];
            setViewport({
                latitude: waypoint.latitude,
                longitude: waypoint.longitude,
                zoom: 12,
            });
            setWaypoints([waypoint]);
        } else {
            setWaypoints(props.markers || []);
        }
    }, [props.markers]);


    /**
     * Right Click on map to place custom marker
     * @param event
     */
    const handleRightClick = (event: MapEvent) => {
        event.preventDefault();
        const {rightClickOptions, onContextMenuAction} = props;

        if (!rightClickOptions) {
            return;
        }
        const {allowContextMenu, contextMenuOptions} = rightClickOptions;

        if (!allowContextMenu) {
            console.error('no rights to context menu')
            return;
        }

        //Set waypoint Map
        if (contextMenuOptions.allowMarkerPlacement) {
            const waypoint: MarkerInfo = {
                longitude: event?.lngLat[0],
                latitude: event?.lngLat[1],
                color: 'green',
            };
            setWaypoints([waypoint]);

            //Custom Extra action on placing marker
            if (onContextMenuAction) {
                onContextMenuAction(waypoint, 'markerPlacement')
            }
        }
    };

    return (
        <>
            <ReactMapGL
                ref={mapRef}
                width='100%'
                height='100%'
                mapStyle={mapStyle}
                transformRequest={transformRequest}
                onLoad={() => initializeEvents()}
                onContextMenu={handleRightClick}
                onViewportChange={setViewport}
                {...viewport}
            >
                <>
                    <Controls mapControls={props.mapControls}/>
                    <Markers markers={waypoints} handlePopup={handlePopup} isRoute={props.isRoute}/>
                    {/*@todo based on items in mapOverlay overlay options must be shown*/}
                    <PopupComponent handlePopup={handlePopup} popupInfo={popupInfo}/>
                </>
                {routePolyline && props.isRoute && (
                    <Source type="geojson" data={routePolyline}>
                        <Layer {...lineLayerStyle} />
                    </Source>
                )}
            </ReactMapGL>
            {overlayPopup()}
        </>
    );
};

export default Map
