import TemplateFormCarrier from "./TemplateFormCarrier";
import TemplateFormDisposer from "./TemplateFormDisposer";
import TemplateFormInvoice from "./TemplateFormInvoice";
import TemplateFormAgent from "./TemplateFormAgent";
import TemplateFormOrigin from "./TemplateFormOrigin";
import TemplateFormDestination from "./TemplateFormDestination";
import TemplateFormSender from "./TemplateFormSender";
import TemplateFormSettings from "./TemplateFormSettings";
import TemplateFormGoodsItems from "./TemplateFormGoodsItems";
import TemplateFormFooter from "./TemplateFormFooter";
import TemplateFormWeightbridge from "./TemplateFormWeightbridge";
import TemplateFormActivity from "./TemplateFormActivity";
import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Grid2";
import customClasses from "./TemplateForm.module.css";
import {Formik, Form} from 'formik';
import formInitialValues from './FormModel/formInitialValues';
import formModel from './FormModel/formModel';
import PropTypes, {oneOfType} from "prop-types";
import {Alert, AlertTitle} from "@mui/material";
import {useSelector} from "react-redux";
import TemplateFormReportStatement from "./TemplateFormReportStatement";
import {getTableItems} from "../../../store/selectors/datatableSelector";
import TemplateFormIntro from "./TemplateFormIntro";
import {getValidationSchema} from "./FormModel/formValidationSchema";
import {getCompanyTemplateLocationCheck} from "../../../store/selectors/companySelector";
import {deepEqual} from "../../../shared/utility";

export default function TemplateForm(props) {
    const savedData = props.data ?? null;
    const {formField} = formModel;
    const myRef = React.createRef()
    const projectItems = useSelector(state => getTableItems(state, 'project'));
    const [selectedProject, setSelectedProject] = useState({});
    const projectId = props.formRef?.current?.values?.project_id || savedData.project_id || ''
    const disabledWithCertainState = ((savedData?.state_id === '30' && props.action !== 'copy') || (savedData?.state_id === '50') || savedData?.state_id === '80');
    const locationCheckEnabled = useSelector(state => getCompanyTemplateLocationCheck(state));
    const [transportedByRequired, setTransportedByRequired] = useState(false);
    const validationSchema = getValidationSchema(locationCheckEnabled);

    /**
     * Submit templateform
     * @param {object} values
     * @param {boolean} dataEdited
     */
    const handleSubmit = (values, dataEdited) => {
        props.handleData(values, dataEdited);
    }

    /**
     * Scroll to the provided reference
     * @param ref - The reference to scroll into view
     */
    const scrollToRef = (ref) => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    };

    /**
     * Handle submit errors and scroll to top if needed
     */
    useEffect(() => {
        if (props.errors || transportedByRequired) {
            scrollToRef(myRef);
        }
    }, [props.errors, transportedByRequired]);

    /**
     * Render errors in an Alert component
     * @returns {JSX.Element | null} - Rendered error alerts or null if no errors
     */
    const renderErrors = () => {
        if (transportedByRequired) {
            return (
                <Alert severity="error">
                    <AlertTitle>Fout in blok 5: Ontvanger/inzamelaar/ vervoerder</AlertTitle>
                    VIHB-nummer is verplicht
                </Alert>
            );
        }

        if (props.errors?.length) {
            return props.errors.map((error, errorIndex) => {
                return Object.entries(error).map(([field, messages]) => (
                    <Alert key={`${field}-${errorIndex}`} severity="error">
                        <AlertTitle>Fout(en) bij: {field}</AlertTitle>
                        {messages.map((message, messageIndex) => (
                            <React.Fragment key={messageIndex}>
                                <span>{message}</span>
                                <br />
                            </React.Fragment>
                        ))}
                    </Alert>
                ));
            });
        }
        return null;
    };

    // Render the template errors
    let templateErrors = renderErrors();

    //Handle submit errors
    useEffect(() => {
        if (!projectItems || !projectItems.length || !projectId) {
            return;
        }
        const found = projectItems.find((element) => {
            if (element.id === savedData.project_id) {
                return element;
            }
        })
        setSelectedProject(found)
    }, [projectId])

    return <React.Fragment>
        <div ref={myRef}/>

        {templateErrors}

        <Formik
            initialValues={savedData ?? formInitialValues}
            validationSchema={validationSchema[0]}
            onSubmit={async (template) => {
                const newTemplate = template
                const oldTemplate = savedData ?? formInitialValues;
                const dataChanged = await deepEqual(newTemplate, oldTemplate)

                //Temp fix so vihb is required for carrier
                const transportedByType = template.transported_by_type || 5;
                const carrierPartyName = template.carrierparty?.name;
                const carrierPartyVihb = template.carrierparty?.identifier_value;
                const vihbIsEmpty = ((transportedByType === 4 || transportedByType === 5) && carrierPartyName && !carrierPartyVihb);
                if (vihbIsEmpty) {
                    setTransportedByRequired(true);
                    return;
                }

                transportedByRequired && setTransportedByRequired(false);
                handleSubmit(template, !dataChanged)
            }}
            innerRef={props.formRef}>
            {({
                  initialValues,
                  setFieldValue,
                  setFieldError,
                  values,
                  errors,
                  touched
              }) => (
                <Form id="templateForm" className={customClasses.templateForm}>
                    <Grid container className={customClasses.templateGridContainer}
                          direction="row"
                          justifyContent="space-around"
                          alignItems="flex-start">
                        <Grid container>
                            {/*====================================================================================================================*/}
                            <Grid size={{xs: 12, md: 6}}>
                                <Grid className={customClasses.container} size={12}>
                                    {/*==Intro Text==*/}
                                    <TemplateFormIntro
                                        values={values}
                                    />
                                </Grid>
                            </Grid>
                            {/*====================================================================================================================*/}
                            <Grid container
                                  className={`${customClasses.containerBorderLeft} ${customClasses.disabledContainer}`}
                                  size={{xs: 12, md: 6}}>
                                {/*==Block settings==*/}
                                <TemplateFormSettings
                                    values={values}
                                    action={props.action}
                                    setFieldValue={setFieldValue}
                                    disabled={true}
                                    formField={formField}
                                    initialValues={initialValues}
                                    copyId={props.copyId}
                                    handleCopyId={props.handleCopyId}
                                />
                            </Grid>
                            {/*====================================================================================================================*/}
                            <Grid container direction="row" className={customClasses.containerRequired} size={{
                                xs: 12,
                                md: 6
                            }}>
                                {/*==Block 1==*/}
                                <TemplateFormSender
                                    values={values}
                                    action={props.action}
                                    project={selectedProject}
                                    setFieldError={setFieldError}
                                    setFieldValue={setFieldValue}
                                    disabled={props.disabled}
                                    formField={formField}
                                    autoFillReplaceData={props.autoFillReplaceDataAllowed}
                                    templateFormInitialised={props.templateFormInitialised}
                                />
                            </Grid>
                            {/*====================================================================================================================*/}
                            <Grid container className={`${customClasses.containerBorderLeft} ${customClasses.disabledContainer}`} size={{
                                xs: 12,
                                md: 6
                            }}>
                                <TemplateFormActivity
                                    initialValues={savedData ?? formInitialValues}
                                    setFieldValue={setFieldValue}
                                    disabled={true}
                                    formField={formField}
                                    values={values}
                                    savedData={savedData}
                                    action={props.action}
                                />
                            </Grid>
                            {/*/!*====================================================================================================================*!/*/}
                            <Grid container className={customClasses.container} size={{xs: 12, md: 6}}>
                                {/*==Block 2==*/}
                                <TemplateFormInvoice
                                    values={values}
                                    action={props.action}
                                    data={props.data}
                                    project={selectedProject}
                                    setFieldValue={setFieldValue}
                                    disabled={props.disabled}
                                    formField={formField}
                                    templateFormInitialised={props.templateFormInitialised}
                                />
                            </Grid>
                            {/*/!*====================================================================================================================*!/*/}
                            <Grid container className={`${customClasses.containerBorderLeft} ${customClasses.disabledContainer}`} size={{
                                xs: 12,
                                md: 6
                            }}>
                                {/*==Weegbrug info==*/}
                                <TemplateFormWeightbridge
                                    action={props.action}
                                    initialValues={savedData ?? formInitialValues}
                                    values={values}
                                    data={props.data}
                                    setFieldValue={setFieldValue}
                                    disabled={true}
                                    formField={formField}
                                    handleBlur={props.handleBlur}
                                />
                            </Grid>
                            {/*/!*====================================================================================================================*!/*/}
                            <Grid container className={customClasses.containerRequired} size={{xs: 12, md: 6}}>
                                {/*==Block 3A==*/}
                                <TemplateFormDisposer
                                    action={props.action}
                                    values={values}
                                    project={selectedProject}
                                    disabled={props.disabled}
                                    formField={formField}
                                    data={props.data}
                                    setFieldValue={setFieldValue}
                                    autoFillReplaceData={props.autoFillReplaceDataAllowed}
                                    templateFormInitialised={props.templateFormInitialised}
                                />
                            </Grid>
                            {/*/!*====================================================================================================================*!/*/}
                            <Grid container className={customClasses.containerRequired} size={{xs: 12, md: 6}}>
                                {/*==Block 3B==*/}
                                <TemplateFormOrigin
                                    project={selectedProject}
                                    setFieldValue={setFieldValue}
                                    disabled={props.disabled}
                                    formField={formField}
                                    values={values}
                                    data={props.data}
                                    errors={errors}
                                    touched={touched}
                                />
                            </Grid>
                            {/*/!*====================================================================================================================*!/*/}
                            <Grid container className={customClasses.containerMaybeRequired} size={{
                                xs: 12,
                                md: 6
                            }}>
                                {/*==Block 4A==*/}
                                <TemplateFormAgent
                                    setFieldValue={setFieldValue}
                                    disabled={true}
                                    formField={formField}
                                    organisationData={props.organisationData}
                                />
                            </Grid>
                            {/*/!*====================================================================================================================*!/*/}
                            <Grid container className={customClasses.containerRequired} size={{xs: 12, md: 6}}>
                                {/*==Block 4B==*/}
                                <TemplateFormDestination
                                    project={selectedProject}
                                    setFieldValue={setFieldValue}
                                    disabled={props.disabled}
                                    formField={formField}
                                    values={values}
                                    data={props.data}
                                />
                            </Grid>
                            {/*/!*====================================================================================================================*!/*/}
                            <Grid container className={customClasses.containerRequired} size={12}>
                                {/*==Block 5==*/}
                                <TemplateFormCarrier
                                    action={props.action}
                                    project={selectedProject}
                                    setFieldValue={setFieldValue}
                                    disabled={(disabledWithCertainState) || props.disabled}
                                    formField={formField}
                                    values={values}
                                    autoFillReplaceData={props.autoFillReplaceDataAllowed}
                                    templateFormInitialised={props.templateFormInitialised}
                                    handleSetTemplateFormInitialised={props.handleSetTemplateFormInitialised}
                                />
                            </Grid>
                            {/*/!*====================================================================================================================*!/*/}
                            <Grid container className={customClasses.containerRequired} size={12}>
                                {/*==Block 6==*/}
                                <TemplateFormGoodsItems
                                    disabled={props.disabled}
                                    euralData={props.euralData}
                                    values={values}
                                    formField={formField}
                                    setFieldValue={setFieldValue}
                                    // processingData={props.processingMethodData}
                                />
                            </Grid>
                            {/*/!*====================================================================================================================*!/*/}
                            {
                                values.form_type === "B2" ? (
                                    <Grid container className={customClasses.containerRequired} size={12}>
                                        <TemplateFormReportStatement
                                            disabled={props.disabled}
                                            values={values}
                                            formField={formField}/>
                                    </Grid>
                                ) : ('')
                            }
                            {/*/!*====================================================================================================================*!/*/}
                            <Grid container size={12}><br/><br/></Grid>
                            {/*====================================================================================================================*/}
                            <Grid container className={customClasses.footerContainer} size={12}>
                                {/*==OUDFooter==*/}
                                <TemplateFormFooter
                                    disabled={props.disabled}
                                    form_type={values.form_type}
                                    formField={formField}
                                />
                            </Grid>
                            {/*====================================================================================================================*/}
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    </React.Fragment>;
};

TemplateForm.propTypes = {
    data: PropTypes.object,
    formRef: PropTypes.object,
    handleData: PropTypes.func,
    templateFormInitialised: PropTypes.bool,
    handleSetTemplateFormInitialised: PropTypes.func,
    disabled: PropTypes.bool,
    action: PropTypes.oneOf(['add', 'edit', 'view', 'copy', 'reviewer', 'distribution']).isRequired,
    copyId: PropTypes.number,
    handleCopyId: PropTypes.func,
    autoFillReplaceDataAllowed: PropTypes.bool,
    errors: oneOfType([PropTypes.array, PropTypes.string]),
    handleBlur: PropTypes.func,
    organisationData: PropTypes.array,
    euralData: PropTypes.array
};
