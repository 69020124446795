import axios, {InternalAxiosRequestConfig} from 'axios';
import {isUuidValid} from "../shared/utility";

const instance = axios.create({
    baseURL: process.env.REACT_APP_PEER_URL,
    withCredentials: true,
    headers: {
        'Content-Type': `application/json`,
        Accept: `application/json`
    }
});


instance.interceptors.request.use(
    function (config: InternalAxiosRequestConfig) {
        config.headers = config.headers ?? {};
        config.url = config.url ?? '';

        const token = localStorage.getItem('token')
        const loggedin = localStorage.getItem('loggedin')

        if (token != null) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        if (config.url !== '/authorize/introspect') {
            let questionMarked = config.url.includes("?");
            const activeCompany = localStorage.getItem("companySelected") || '';

            const isValid = isUuidValid(activeCompany)

            if(!isValid && loggedin){
                localStorage.removeItem("companySelected")
                localStorage.removeItem("regionsSelected")
                localStorage.removeItem("loggedin")
                localStorage.removeItem("token")
                throw new Error('Bedrijf is niet geldig')
            }

            if (activeCompany != null) {
                config.url += (questionMarked ? "&" : '?') + "company_uuid=" + activeCompany;
                questionMarked = true;
            }

            if (config.url.includes('no-region-filter') === false) {
                const regionsSelected = localStorage.getItem("regionsSelected") ?? '[]';
                const selectedRegions: [] = JSON.parse(regionsSelected);
                if (selectedRegions != null) {
                    config.url += (questionMarked ? "&" : '?') + "selected_regions=" + selectedRegions.join(',');
                }
            }
        }

        return config;
    }, function (err) {
        return Promise.reject(err);
    });


//instance.defaults.withCredentials = true

export default instance;