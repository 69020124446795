import Grid from "@mui/material/Grid2";
import React, {useState} from "react";
import customClasses from "./TemplateForm.module.css";
import PropTypes from "prop-types";
import SearchEuralDialog from "./../Templates/DialogDetails/SearchEuralDialog";
import SimpleDialog from "../../Dialog/SimpleDialog";
import ConfirmDialogWithContent from "../../Dialog/ConfirmDialogWithContent";
import SearchProcessingMethodDialog from "../Templates/DialogDetails/SearchProcessingMethodDialog";
import {FormTextField} from "./Components/FormTextField";
import {FastField} from "formik";
import {Typography} from "@mui/material";

export default function TemplateFormGoodsItems(props) {
    const group = "gooditem";

    const {
        formField: {
            template_waste_stream_id,
            gooditem_description,
            gooditem_eural_code,
            gooditem_processing_method,
            gooditem_estimated_unit_value,
        }
    } = props;

    const [openDialog, setOpenDialog] = useState(false);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [processingDialog, setProcessingDialog] = useState(false);
    const [eural, setEural] = useState([]);

    // =====================
    // Handle Open and Close dialogs
    // =====================
    const openModal = () => {
        setOpenDialog(true);
    }
    const closeModal = () => {
        setOpenDialog(false);
    };
    const openConfirm = () => {
        setOpenConfirmDialog(true);
    }
    const closeConfirm = () => {
        setOpenConfirmDialog(false);
    };

    const openProcessingDialog = () => {
        setProcessingDialog(true);
    }
    const closeProcessingDialog = () => {
        setProcessingDialog(false);
    };


    // ==========================================
    // Handle Dialog actions
    // ==========================================
    const handleConfirmDialogYes = () => {
        props.setFieldValue(group + '.eural_code', eural.code);
        props.setFieldValue(group + '.description', eural.description);
        closeConfirm()
    }
    const handleConfirmDialogNo = () => {
        props.setFieldValue(group + '.eural_code', eural.code);
        closeConfirm()
    }

    // ==========================================
    // Handle Selected Eural
    // ==========================================
    const handleEuralSelected = (selectedEural) => {
        if (props.values.gooditem.description === '') {
            setEural(selectedEural)
            props.setFieldValue(group + '.eural_code', selectedEural.code || '');
            props.setFieldValue(group + '.description', selectedEural.description || '');
        } else {
            setEural(selectedEural)
            if (selectedEural.description !== props.values.gooditem.description) {
                openConfirm()
            }
        }
        closeModal();
    };

    // =====================
    // Handle selected company
    // =====================
    const handleProcessingMethod = (selectedItem) => {
        props.setFieldValue(group + '.processing_method', selectedItem.code);
        closeProcessingDialog();
    };

    // ==========================================
    // Dialogs
    // ==========================================
    // ConfirmMessage
    const confirmDialogMessage = <React.Fragment>
        <p>Wil je de ingevulde gebruikelijke benaming vervangen door onderstaande omschrijving?</p>

        <strong>Van:</strong> <br/>
        {props.values.gooditem.description}
        <br/> <br/>
        <strong>Naar:</strong><br/>
        {eural.description}
    </React.Fragment>

    // ConfirmDialog
    const dialogConfirm = <ConfirmDialogWithContent title={"Gebruikelijke benaming vervangen"}
                                                    content={confirmDialogMessage} open={openConfirmDialog}
                                                    handleClose={closeConfirm} handleNo={handleConfirmDialogNo}
                                                    handleAgreed={handleConfirmDialogYes}/>;

    //Search Eural dialog
    const dialog = <SimpleDialog
        title="Zoek Eural code"
        fields={props}
        size="lg"
        fullWidth={true}
        open={openDialog}
        handleClose={closeModal}>
        <SearchEuralDialog
            data={props.euralData || []}
            handleEuralSelected={handleEuralSelected}
        />
    </SimpleDialog>

    // =====================
    // Search processing Dialog
    // =====================
    const processingMethodDialog = <SimpleDialog
        title="Zoek verwerkings methode"
        fields={props}
        size="lg"
        fullWidth={true}
        open={processingDialog}
        handleClose={closeProcessingDialog}>
        <SearchProcessingMethodDialog
            data={props.processingData || []}
            handleProcessingMethod={handleProcessingMethod}
        />
    </SimpleDialog>

    // ==========================================
    // Eural block
    // ==========================================
    return <React.Fragment>
        {dialog}
        {dialogConfirm}
        {processingMethodDialog}
        {/*===========================================================================*/}
        {/*Block Divider*/}
        {/*===========================================================================*/}
        <Grid className={customClasses.borderDividerFull} container size={12}/>
        <div className={customClasses.blockIdentifier}><strong>6</strong></div>
        {/*===========================================================================*/}
        {/*Block Content
        {/*===========================================================================*/}

        <Grid container size={12}>
            <Grid container size={{xs: 12, md: 2}}>
                <Grid size={12} className={customClasses.labelHeader}>
                    <strong style={{hyphens: 'auto'}}>{template_waste_stream_id.label}</strong>
                    <Typography sx={{
                        fontSize: 10,
                        fontStyle: 'italic',
                        textTransform: 'uppercase'
                    }}>{props.values.form_type === 'B2' ? (template_waste_stream_id.labelExtra) : null}</Typography>
                </Grid>
                <Grid size={12} className={customClasses.whiteFiller}></Grid>
                <Grid size={12}>
                    <FastField
                        autoComplete="new-password"
                        name={template_waste_stream_id.name}
                        disabled={props.disabled}
                        sizeClass={`${customClasses.inputFull} ${customClasses.containerMaybeRequired}`}
                        component={FormTextField}
                    />
                </Grid>
            </Grid>

            <Grid container size={{xs: 12, md: 5}}>
                <Grid size={12} className={customClasses.labelHeader}>
                    <strong style={{hyphens: 'auto'}}>{gooditem_description.label}</strong>
                    <Typography sx={{
                        fontSize: 10,
                        fontStyle: 'italic',
                        textTransform: 'uppercase'
                    }}>{props.values.form_type === 'B2' ? (gooditem_description.labelExtra) : null}</Typography>

                </Grid>
                <Grid size={12} className={customClasses.whiteFiller}></Grid>
                <Grid size={12}>
                    <FastField
                        autoComplete="new-password"
                        name={group + "." + gooditem_description.name}
                        disabled={props.disabled}
                        sizeClass={customClasses.inputFull}
                        component={FormTextField}
                    />
                </Grid>
            </Grid>
            <Grid container size={{xs: 12, md: 1}}>
                <Grid size={12} className={customClasses.labelHeader}>
                    <strong style={{hyphens: 'auto'}}>Aantal / verpakking</strong>
                </Grid>
                <Grid size={12} className={customClasses.whiteFiller}></Grid>
                <Grid size={12} className={customClasses.emptyInput}>
                    -
                </Grid>
            </Grid>
            <Grid container size={{xs: 12, md: 1}}>
                <Grid size={12} className={customClasses.labelHeader}>
                    <strong>{gooditem_eural_code.label}</strong>
                </Grid>
                <Grid size={12} className={customClasses.whiteFiller}></Grid>
                <Grid size={12}>
                    <FastField
                        readOnly={true}
                        autoComplete="new-password"
                        name={group + "." + gooditem_eural_code.name}
                        disabled={props.disabled}
                        sizeClass={customClasses.inputFull}
                        onClick={() => !props.disabled ? openModal() : ''}
                        component={FormTextField}
                    />
                </Grid>
            </Grid>
            <Grid container size={{xs: 12, md: 1}}>
                <Grid size={12} className={customClasses.labelHeader}>
                    <strong>{gooditem_processing_method.label}</strong>
                </Grid>
                <Grid size={12} className={customClasses.whiteFiller}></Grid>
                <Grid size={12}>
                    <FastField
                        readOnly={true}
                        autoComplete="new-password"
                        name={group + "." + gooditem_processing_method.name}
                        disabled={props.disabled}
                        sizeClass={customClasses.inputFull}
                        onClick={() => !props.disabled ? openProcessingDialog() : ''}
                        component={FormTextField}
                    />
                </Grid>
            </Grid>
            <Grid container size={{xs: 12, md: 1}}>
                <Grid size={12} className={customClasses.labelHeader}>
                    <strong>{gooditem_estimated_unit_value.label}</strong>
                </Grid>
                <Grid size={12} className={customClasses.whiteFiller}></Grid>
                <Grid size={12}>
                    <FastField
                        autoComplete="new-password"
                        name={group + "." + gooditem_estimated_unit_value.name}
                        disabled={props.disabled}
                        sizeClass={customClasses.inputFull}
                        type='number'
                        component={FormTextField}
                    />
                </Grid>
            </Grid>
            {
                props.values.form_type !== 'B2' ? (
                    <Grid container size={{xs: 12, md: 1}}>
                        <Grid size={12} className={customClasses.labelHeader}>
                            <strong>gewogen hoeveelheid (kg)</strong>
                        </Grid>
                        <Grid size={12} className={customClasses.whiteFiller}></Grid>
                        <Grid size={12} className={`${customClasses.emptyInput} ${customClasses.container}`}>
                            -
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container size={{xs: 12, md: 1}}>
                        <Grid size={12} className={customClasses.labelHeader} sx={{backgroundColor: '#ffffff'}}/>
                        <Grid size={12} className={customClasses.whiteFiller}/>
                        <Grid size={12} className={`${customClasses.emptyInput} ${customClasses.container}`}/>
                    </Grid>
                )
            }
        </Grid>
    </React.Fragment>
}

//     return <React.Fragment>
//         {dialog}
//         {dialogConfirm}
//         {processingMethodDialog}
//         {/*===========================================================================*/}
//         {/*Block Divider*/}
//         {/*===========================================================================*/}
//         <Grid className={customClasses.borderDividerFull} container item xs={12}/>
//         <div className={customClasses.blockIdentifier}>6</div>
//         {/*===========================================================================*/}
//         {/*Block Content
//         {/*===========================================================================*/}
//         <Grid container item xs={12} md={12}>
//             <Grid xs={2}>
//                 <strong>{template_waste_stream_id.label}</strong>
//             </Grid>
//             <Grid xs={5}>
//                 <strong> {gooditem_description.label}</strong>
//             </Grid>
//             <Grid xs={1}>
//                 <strong> Aantal / verpakking</strong>
//             </Grid>
//             <Grid xs={1}>
//                 <strong>{gooditem_eural_code.label}</strong>
//             </Grid>
//             <Grid xs={1}>
//                 <strong>{gooditem_processing_method.label}</strong>
//             </Grid>
//             <Grid className={customClasses.container} item xs={1}>
//                 <strong>{gooditem_estimated_unit_value.label}</strong>
//             </Grid>
//             <Grid xs={1}>
//                 <strong>gewogen hoeveelheid (kg)</strong>
//             </Grid>
//         </Grid>
//         {/*======*/}
//         <Grid className={customClasses.container} container item xs={12}>
//
//         </Grid>
//         {/*======*/}
//
//         <Grid className={customClasses.containerMaybeRequired} item xs={2}>
//             <FastField
//                 autoComplete="new-password"
//                 name={template_waste_stream_id.name}
//                 disabled={props.disabled}
//                 sizeClass={customClasses.inputFull}
//                 component={FormTextField}
//             />
//         </Grid>
//         <Grid xs={5}>
//             <FastField
//                 autoComplete="new-password"
//                 name={group + "." + gooditem_description.name}
//                 disabled={props.disabled}
//                 sizeClass={customClasses.inputFull}
//                 component={FormTextField}
//             />
//         </Grid>
//         <Grid xs={1}>
//             -
//         </Grid>
//         <Grid xs={1}>
//             <FastField
//                 autoComplete="new-password"
//                 name={group + "." + gooditem_eural_code.name}
//                 disabled={props.disabled}
//                 sizeClass={customClasses.inputFull}
//                 onClick={openModal}
//                 component={FormTextField}
//             />
//         </Grid>
//         <Grid xs={1}>
//             <FastField
//                 autoComplete="new-password"
//                 name={group + "." + gooditem_processing_method.name}
//                 disabled={props.disabled}
//                 sizeClass={customClasses.inputFull}
//                 onClick={openProcessingDialog}
//                 component={FormTextField}
//             />
//         </Grid>
//         <Grid xs={1}>
//             <FastField
//                 autoComplete="new-password"
//                 name={group + "." + gooditem_estimated_unit_value.name}
//                 disabled={props.disabled}
//                 sizeClass={customClasses.inputFull}
//                 type='number'
//                 component={FormTextField}
//             />
//         </Grid>
//         <Grid className={customClasses.container} item xs={1}>
//             -
//         </Grid>
//
//     </React.Fragment>
// }

TemplateFormGoodsItems.propTypes = {
    disabled: PropTypes.bool.isRequired,
    formField: PropTypes.object.isRequired,
    setFieldValue: PropTypes.func,
    euralData: PropTypes.array,
    values: PropTypes.object,
    processingData: PropTypes.array,
};