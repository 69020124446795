import React from 'react';
import {Box} from '@mui/material';
import {FullscreenControl, GeolocateControl, NavigationControl, ScaleControl} from "react-map-gl";
import {MapControls} from "../Interfaces/MapInterfaces";

function Controls({mapControls = []}: MapControls) {
    return (
        <>
            {mapControls.includes('scale') && (
                <Box
                    sx={{
                        position: 'absolute',
                        bottom: 22,
                        left: 10,
                    }}
                >
                    <ScaleControl/>
                </Box>
            )}
            {mapControls.includes('fullscreen') && (
                <Box
                    sx={{
                        position: 'absolute',
                        bottom: 190,
                        right: 50,
                    }}
                >
                    <FullscreenControl/>
                </Box>
            )}
            {mapControls.includes('geo') && (
                <Box
                    sx={{
                        position: 'absolute',
                        bottom: 150,
                        right: 50,
                    }}
                >
                    <GeolocateControl/>
                </Box>
            )}
            {mapControls.includes('navigation') && (
                <Box
                    sx={{
                        position: 'absolute',
                        bottom: 110,
                        right: 50,
                    }}
                >
                    <NavigationControl/>
                </Box>
            )}
        </>
    );
}
export default React.memo(Controls);