import {GridEditModes, useGridApiContext, useGridRootProps} from "@mui/x-data-grid-premium";
import * as React from "react";
import PropTypes from "prop-types";
import Select from "@mui/material/Select";
import i18n from "../../../i18n/i18n";

//Edit Field
function EditSingleSelect(props) {
    const {id, value, field, colDef} = props;
    const rootProps = useGridRootProps();
    const apiRef = useGridApiContext();

    const handleChange = async (event) => {
        const isValid = await apiRef.current.setEditCellValue({id, field, value: event.target.value});

        if (isValid && rootProps.editMode === GridEditModes.Cell) {
            apiRef.current.stopCellEditMode({id, field, cellToFocusAfter: 'below'});
        }
    };

    return (
        <Select
            value={value}
            onChange={handleChange}
            size="small"
            sx={{height: 1, width: '100%'}}
            native
        >{
            colDef.valueOptions.length > 0 ? (
                colDef.valueOptions.map((item, i) => {
                    return <option key={i} style={{width: '100%'}} value={item}>{item}</option>
                })
            ) : (
                <option style={{width: '100%'}}>{i18n.t('NO_OPTIONS')}</option>
            )
        }
        </Select>
    );
}

EditSingleSelect.propTypes = {
    field: PropTypes.string.isRequired,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    colDef: PropTypes.object

};

// ===========================
// Render singleSelect
// ===========================
export function rendeSingleSelect(params) {
    if (params.value == null) {
        return '';
    }
    return <></>;
}

// ===========================
// Render Edit Single Select
// ===========================
export function renderEditSingleSelect(params) {
    return <EditSingleSelect {...params} />;
}