import React, {useEffect} from "react";
import {styled} from '@mui/material/styles';
import {
    Autocomplete,
    Checkbox, Chip,
    IconButton, InputAdornment, ListItemText, MenuItem,
    TextField,
    Tooltip
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {setSelectedRegions} from "../../../store/actions/auth";
import {Cancel, CenterFocusWeak} from "@mui/icons-material";
import WindowDimensions from "../../WindowDimensions/WindowDimensions";
import {getActiveCompany} from "../../../store/selectors/companySelector";
import {getActiveRegions, getAvailableRegions} from "../../../store/selectors/regionSelector";
import {isTableLoading} from "../../../store/selectors/datatableSelector";
import i18n from "../../../i18n/i18n";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const StyledAutocomplete = styled(Autocomplete)(({theme}) => ({
    padding: '8px',
    border: 'none',
    [theme.breakpoints.down('lg')]: {
        paddingRight: "20px",
    },
    "& .MuiAutocomplete-inputRoot": {
        flexWrap: "nowrap", // Prevent wrapping of input/chips container
    },
    "& .MuiAutocomplete-tag": {
        whiteSpace: "nowrap", // Prevents chip text from wrapping
    },
    "& .MuiAutocomplete-root": {
        display: "flex",
        overflowX: "auto", // Enables horizontal scrolling
        overflowY: "hidden",
    },
    "& .MuiAutocomplete-popper": {
        maxWidth: "300px", // Ensure the dropdown maintains consistent size
        minWidth: "300px",
    },
}));

const StyledIconButton = styled(IconButton)(({theme}) => ({
    color: '#FBE2D4',
    '&.Mui-disabled': {
        color: '#FBE2D4',
        opacity: 0.8
    },
}));

const StyledChip = styled(Chip)(({theme}) => ({
    marginRight: 4,
    cursor: "pointer",
    backgroundColor: '#FBE2D4',
    color: '#EE6E2C',
    border: 'none',
    boxShadow: ' inset 1px 1px 1px #d66327, 0 0 1px #d66327',
    fontSize: '0.9rem',
    fontWeight: 500,
    justifyItems: 'flex-end',
    textTransform: 'capitalize',
    [theme.breakpoints.down('lg')]: {
        fontSize: '0.7rem',
    },
    '&.Mui-disabled': {
        backgroundColor: '#FBE2D4',
        color: '#EE6E2C',
        opacity: 0.8
    },
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "100%",
}));

export default function HeaderRegionSelector() {
    const selectedCompany = useSelector(state => getActiveCompany(state));
    const availableRegions = useSelector(state => getAvailableRegions(state));
    const selectedRegions = useSelector(state => getActiveRegions(state));
    const loading = useSelector(state => isTableLoading(state));
    const [open, setOpen] = React.useState(false);
    const [regions, setRegions] = React.useState(selectedRegions || []);
    const {width} = WindowDimensions();
    const dispatch = useDispatch();
    const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
    const checkedIcon = <CheckBoxIcon fontSize="small"/>;
    const disabledOneRegion = availableRegions.length === 1;

    /**
     * Close header autocomplete
     * Should trigger when user closes autocomplete
     */
    const onCloseAutocomplete = () => {
        if (regions && regions?.length === 0) {
            return;
        }

        //Convert to right format
        const updatedRegions = regions.reduce((acc, item) => {
            acc[item.UUID] = item;
            return acc;
        }, {});

        //Set regions
        dispatch(setSelectedRegions(updatedRegions));
        setOpen(false);
    }

    /**
     * Open header autocomplete
     */
    const onOpenAutocomplete = () => {
        setOpen(true);
    }

    /**
     * When selecting checkbox
     * @param {object} event
     * @param {array} values
     * @param {string} action
     */
    const onChangeAutocomplete = (event, values, action) => {

        if (event.target.tagName === 'path' || event.target.tagName === 'svg') {
            const updatedRegions = values.reduce((acc, item) => {
                acc[item.UUID] = item;
                return acc;
            }, {});

            dispatch(setSelectedRegions(updatedRegions));
        }
        setRegions(values)
    };

    /**
     * Toggle autocomplete dropdown
     */
    const toggleAutocomplete = () => {
        //Cannot close when no region is selected
        if (open && regions.length === 0) {
            return;
        }
        setOpen(!open)
    }

    useEffect(() => {
        setRegions(selectedRegions)
    }, [selectedCompany, selectedRegions])

    if (!selectedCompany || !availableRegions.length) {
        return "";
    }

    return (
        <StyledAutocomplete
            multiple
            fullWidth
            id="region-autcomplete"
            options={availableRegions || []}
            getOptionDisabled={(option) => {
                return (
                    disabledOneRegion ||
                    (regions.length === 1 && regions.some(region => region.UUID === option.UUID))
                );
            }}
            disableCloseOnSelect
            getOptionLabel={(option) => option.name}
            disabled={disabledOneRegion}
            readOnly={disabledOneRegion}
            onOpen={onOpenAutocomplete}
            onClose={onCloseAutocomplete}
            open={open}
            onChange={onChangeAutocomplete}
            limitTags={width > 768 ? 6 : 0}
            disableClearable
            slotProps={{
                popper: {style: {width: 'fit-content'}, placement: 'bottom-end'},
            }}
            ListboxProps={{
                sx: {
                    alignItems: 'flex-end'
                },
            }}
            renderOption={(props, option) => {
                const {key, ...optionProps} = props;
                const isSelected = regions.some(region => region.UUID === option.UUID);

                return (
                    <MenuItem key={key} sx={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                    }} {...optionProps} >
                        <Checkbox
                            size={"small"}
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{marginRight: 8}}
                            checked={isSelected}
                            disabled={disabledOneRegion || regions.length === 1}
                        />
                        <ListItemText primary={option.name}/>
                    </MenuItem>
                );
            }}
            value={regions}
            renderTags={(tagValue, getTagProps) => {
                return tagValue.map((option, index) => {
                    const {key, ...tagProps} = getTagProps({index});
                    const isDisabled = disabledOneRegion === 1 || regions.length === 1;
                    return (
                        <StyledChip
                            {...tagProps}
                            variant={"outlined"}
                            deleteIcon={
                                !isDisabled ? (
                                    <Tooltip title={i18n.t('TOOLTIP_HIDE_REGIONS')}>
                                        <Cancel
                                            disabled={isDisabled}
                                            sx={{color: '#EE6E2C !important'}}
                                            onMouseDown={(event) => event.stopPropagation()}
                                        />
                                    </Tooltip>
                                ) : null
                            }
                            sx={{zIndex: 999}}
                            key={option.UUID}
                            label={option.name}
                            disabled={isDisabled}
                        />
                    );
                });
            }}
            renderInput={(params) => {
                return (
                    <TextField
                        {...params}
                        sx={{
                            "& fieldset": {border: 'none'},
                            '& .MuiAutocomplete-popupIndicator': {
                                color: '#FBE2D4',
                                display: disabledOneRegion ? 'none' : 'flex'
                            },
                            '&.Mui-disabled': {
                                backgroundColor: '#FBE2D4',
                                color: '#EE6E2C',
                                opacity: 0.8
                            },
                        }}
                        slotProps={{
                            input: {
                                ...params.InputProps,
                                startAdornment: (
                                    <>
                                        <InputAdornment position="start">
                                            <Tooltip title={i18n.t('REGION')}>
                                            <span>
                                                <StyledIconButton
                                                    disabled={availableRegions.length === 1}  // Disable the button when only one region is available
                                                    onClick={toggleAutocomplete}
                                                    size="small"
                                                >
                                                    <CenterFocusWeak/>
                                                </StyledIconButton>
                                            </span>
                                            </Tooltip>
                                        </InputAdornment>
                                        {params.InputProps.startAdornment}
                                    </>
                                ),
                            },
                        }}
                    />
                );
            }}
        />
    );
}