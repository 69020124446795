import React from "react";
import {TextField, Typography} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import customClasses from "./Registration.module.css";
import i18n from "../../../i18n/i18n";
import Grid from '@mui/material/Grid2';

export default function RegistrationTabTemplate() {
    return (
        <div>
            <Typography align='left' variant="h6">{i18n.t('CREATE_TEMPLATES')}</Typography>
            <Grid container align="left">
                <Grid size={12}>
                    <Typography align='left' variant="body2">
                        {i18n.t('CREATE_TEMPLATES_INFO_1')}<br/>
                        {i18n.t('CREATE_TEMPLATES_INFO_2')}
                    </Typography>
                </Grid>
                <Grid size={12}>
                    <a className={customClasses.a} href="mailto:info@scoretrace.com">
                    <TextField
                        id="input-with-icon-textfield"
                        variant="outlined"
                        margin="normal"
                        label={i18n.t('EMAIL_ADDRESS')}
                        value="info@scoretrace.com"
                        disabled
                        slotProps={{
                            input: {
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <EmailIcon/>
                                    </InputAdornment>
                                ),
                            }
                        }}/></a>
                </Grid>
                <Grid size={12}>
                    <a className={customClasses.a} href="tel:0172436373">
                    <TextField
                        id="input-with-icon-textfield"
                        variant="outlined"
                        label={i18n.t('PHONE')}
                        margin="normal"
                        value="0172-436373"
                        disabled
                        slotProps={{
                            input: {
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <PhoneIcon/>
                                    </InputAdornment>
                                ),
                            }
                        }}/></a>
                </Grid>

            </Grid>
        </div>
    )
}