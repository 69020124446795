import {FormControl, TextField, Typography} from "@mui/material";
import customClasses from "./Check.module.css";
import Button from "@mui/material/Button";
import React from "react";
import {useFormik} from "formik";
import * as yup from "yup";
import {setCommentData} from "../../../../store/actions/templateAudit";
import {useDispatch} from "react-redux";
import InfoIcon from '@mui/icons-material/Info';
import PropTypes from "prop-types";
import Grid from '@mui/material/Grid2';
import i18n from "../../../../i18n/i18n";

const validationSchema = yup.object({
    comment: yup
        .string()
        .nullable(),
    template_id: yup
        .number()
        .required(i18n.t('FIELD_IS_MANDATORY')),
    template_auditor_id: yup
        .number()
        .required(i18n.t('FIELD_IS_MANDATORY')),
    is_approved: yup
        .boolean()
        .required(i18n.t('FIELD_IS_MANDATORY'))
});

export default function CommentForm(props) {
    const dispatch = useDispatch();
    const formik = useFormik({
        initialValues: {
            comment: '',
            template_id: props.template_id,
            template_auditor_id: props.template_auditor_id,
            is_approved: 0
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            dispatch(setCommentData(values));
        }
    });

    const onApprovedClick = () => {
        formik.setFieldValue('is_approved', 1);
        formik.submitForm();
    }

    const onDeniedClick = () => {
        formik.setFieldValue("is_approved", 0);
        formik.submitForm();
    }

    return <FormControl fullWidth>
        <TextField
            label={i18n.t('REMARKS')}
            id="outlined-size-normal"
            placeholder={i18n.t('TYPE_REMARK')}
            variant="outlined"
            name="comment"
            multiline
            minRows={5}
            fullWidth
            onChange={formik.handleChange}
            value={formik.values.comment || ""}
            error={formik.touched.comment && Boolean(formik.errors.comment)}
            helpertext={formik.touched.comment && formik.errors.comment}
        />
        <Grid container spacing={3} className={customClasses.buttonContainer}>
            <Grid size={{xs: 12, md: 6}}>
                <Button
                    variant="contained"
                    color="error"
                    fullWidth
                    className={customClasses.button}
                    onClick={() => onDeniedClick()}>
                    {i18n.t('BUTTON_DISAPPROVE')}
                </Button>
            </Grid>
            <Grid size={{xs: 12, md: 6}}>
                <Button
                    variant="contained"
                    color="success"
                    fullWidth
                    className={customClasses.button}
                    onClick={() => onApprovedClick()}>
                    {i18n.t('BUTTON_APPROVE')}
                </Button>
            </Grid>
        </Grid>

        <div className={customClasses.mobileScrollInfo}>
            <InfoIcon/><Typography style={{paddingLeft: '10px'}} variant='caption'> {i18n.t('TOOLTIP_SCROLL_DOWN')}</Typography>
        </div>
    </FormControl>
}

CommentForm.propTypes = {
    template_id: PropTypes.number,
    template_auditor_id: PropTypes.number,
}