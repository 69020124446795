import React, {useEffect} from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './Fonts/dax-medium.woff';
import './Fonts/dax-light.otf';
import * as serviceWorker from './serviceWorker';
import './index.css';
import {BrowserRouter, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType} from "react-router-dom";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/react";
import store from '../src/store/store'

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
        }),
        Sentry.replayIntegration(),
        Sentry.captureConsoleIntegration({ levels: ['error'] }),
    ],
    release: "rudy@" + process.env.REACT_APP_VERSION,
    enabled: process.env.REACT_APP_ENV === 'Production',
    tracesSampleRate: process.env.REACT_APP_ENV === 'Production' ? 0.1 : 1.0,
    replaysSessionSampleRate: process.env.REACT_APP_ENV === 'Production' ? 0.1 : 1.0,
    replaysOnErrorSampleRate: 1.0,
});

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
);

// Service worker registration
serviceWorker.unregister();