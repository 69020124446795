import * as React from 'react';
import Select from '@mui/material/Select';
import PropTypes from "prop-types";
import {InputLabel} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import i18n from "../../../i18n/i18n";

export default function CustomSingleSelectFilter(props) {
    const {
        item,
        tableFilters,
        applyValue,
        extra
    } = props;

    const correctField = extra.column.filterField || extra.column.field;
    const handleFilterChange = (event) => {
        if(!event.target.value){
            applyValue({...item, value: ''});
            return;
        }
        applyValue({...item, value: event.target.value});
    };
    return (
        <FormControl fullWidth>
            <InputLabel shrink id="demo-simple-select-label">{i18n.t('VALUE')}</InputLabel>
            <Select
                value={item.value || ''}
                onChange={handleFilterChange}
                size="small"
                sx={{height: 1, width: '100%'}}
                native
                label={i18n.t('VALUE')}
                name={correctField}
                variant={extra.column.variant || 'outlined'}
            >
                <option key={0} style={{width: '100%'}} value={null}></option>
                {
                    tableFilters[correctField] ? (
                        Object.keys(tableFilters[correctField]).map((item, i => {
                            return <option key={i} style={{width: '100%'}}
                                           value={i}>{tableFilters[correctField][i]}</option>
                        }))
                    ) : (
                        <option style={{width: '100%'}}>{i18n.t('NO_OPTIONS')}</option>
                    )
                }
            </Select>
        </FormControl>
    );
}

CustomSingleSelectFilter.propTypes={
    item: PropTypes.object.isRequired,
    tableFilters: PropTypes.object.isRequired,
    applyValue: PropTypes.func.isRequired,
    extra: PropTypes.object.isRequired,
};