import React from "react";
import {Button, TextField} from "@mui/material";
import i18n from "../../../i18n/i18n";
import PropTypes from "prop-types";
import Grid from '@mui/material/Grid2';

export default function LoginEmail(props) {
    return(
        <React.Fragment>
            <Grid container spacing={4} alignItems="flex-end">
                <Grid size={12}>
                    <TextField
                        id="email"
                        label={i18n.t('EMAIL_ADDRESS')}
                        type="email"
                        value={props.email}
                        onChange={props.handleEmailChange}
                        error={Boolean(props.emailError)}
                        helperText={props.emailError}
                        fullWidth
                        autoFocus
                        required />
                </Grid>
            </Grid>
            {/*<Grid container alignItems="center" justifyContent="space-between">*/}
            {/*    <Grid>*/}
            {/*        <FormControlLabel control={*/}
            {/*            <Checkbox*/}
            {/*                color="primary"*/}
            {/*                onClick={props.rememberMeToggle}*/}
            {/*            />*/}
            {/*        } label="Onthoud mij" />*/}
            {/*    </Grid>*/}
            {/*</Grid>*/}
            <Grid container justifyContent="center" style={{ marginTop: '10px' }}>
                <Button
                    onClick={props.toggleLoginAccount}
                    type="submit"
                    variant="contained"
                    fullWidth
                    style={{ textTransform: "none" }}>{i18n.t('BUTTON_NEXT')}</Button>
            </Grid>
        </React.Fragment>
    );
}

LoginEmail.propTypes = {
    email : PropTypes.string,
    handleEmailChange : PropTypes.func.isRequired,
    emailError : PropTypes.string,
    toggleLoginAccount : PropTypes.func.isRequired,
}