import React from "react";
import {TextField, Typography} from "@mui/material";
import i18n from "../../../i18n/i18n";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid2";

export default function RegistrationTabContact(props) {
    return (
        <React.Fragment>
            <Typography align='left' variant="h6">{i18n.t('CONTACT_DATA')}</Typography>
            <Grid container align="left">
                <Grid size={{xs:12, sm:8}}>
                    <TextField
                        id="tabContact-inputFirstName"
                        label={i18n.t('FIRST_NAME')}
                        type="text"
                        fullWidth
                        autoFocus
                        margin="normal"
                        variant="outlined"
                        required
                        error={Boolean(props.firstName.error.length)}
                        value={props.firstName.value}
                        onChange={props.inputChangeFirstName}/>
                </Grid>
                <Grid size={{xs:12, sm:4}}>
                    <TextField
                        id="tabContact-inputInsertion"
                        label={i18n.t('INSERTION')}
                        type="text"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        value={props.insertion.value}
                        onChange={props.inputChangeInsertion}/>
                </Grid>
                <Grid size={12}>
                    <TextField
                        id="tabContact-inputLastName"
                        label={i18n.t('LAST_NAME')}
                        type="text"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        required
                        error={Boolean(props.lastName.error.length)}
                        value={props.lastName.value}
                        onChange={props.inputChangeLastName}/>
                </Grid>
                <Grid size={12}>
                    <TextField
                        id="tabContact-inputPhone"
                        label={i18n.t('PHONE')}
                        type="tel"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        autoComplete="tel"
                        name="phone"
                        required
                        error={Boolean(props.phone.error.length)}
                        value={props.phone.value}
                        onChange={props.inputChangePhone}/>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

RegistrationTabContact.propTypes = {
    firstName : PropTypes.object,
    lastName : PropTypes.object,
    phone : PropTypes.object,
    insertion : PropTypes.object,
    inputChangeFirstName : PropTypes.func,
    inputChangeLastName : PropTypes.func,
    inputChangePhone : PropTypes.func,
    inputChangeInsertion : PropTypes.func,
}