import React from "react";
import {Button, Typography} from "@mui/material";
import customClasses from '../Auth.module.css';
import i18n from "../../../i18n/i18n";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid2";

export default function ResetPasswordSuccess(props) {

    return (
        <Grid className={customClasses.contentSpacing} container alignItems="flex-end" justifyContent="flex-end">
            <Grid size={12}>
                <Typography align='center' variant="h6">
                    {i18n.t('PASSWORD_CHANGED')}
                </Typography>
            </Grid>
            <Grid size={12}>
                <br/>
                <Button
                    onClick={props.onClick}
                    variant="contained"
                    color="primary"
                    size="medium"
                    fullWidth
                    style={{textTransform: "none"}}>{i18n.t('BACK_TO_LOGIN')}
                </Button>
            </Grid>
        </Grid>
    )
}

ResetPasswordSuccess.propTypes = {
    onClick: PropTypes.func.isRequired,
}
