import axios from "../../api/axios-peer";
import {MarkerInfo} from "../../components/Map/Interfaces/MapInterfaces";
import {AxiosError} from "axios";

interface Props {
    data: LocationOrigin;
}

interface LocationOrigin {
    description: string | null;
    address_street_name: string | null;
    address_house_no: string | null;
    address_house_no_addition?: string | null;
    address_postal_code: string | null;
    address_city_name: string | null;
    address_country: string | null;
}

interface Location {
    description: string;
    latitude: number;
    longitude: number;
    country: string;
    country_code: string;
    province: string;
    provinceshort: string | null;
    city: string;
    postal_code: string;
    street_name: string;
    house_no: string;
    score: number;
}

/**
 * get geo coordinates based on address data
 * @param data
 */
const getGeoLocation = async (
    {data}: Props
): Promise<Location[]> => {
    let searchData: Location[] = [];

    try {
        const response = await axios.post(`${process.env.REACT_APP_PEER_URL}/template-location/geo`,
            data,
        );
        searchData = response.data;
    } catch (error) {
        console.error('Error during search:', error);
        return [];
    }

    return searchData;
};

export default getGeoLocation;


// Define the structure for the API response
type RouteResult = {
    travelTime: number;
    distance: number;
    polyline: string;
};


/**
 * Fetches route information based on waypoints.
 * @param waypoints An array of [latitude, longitude] tuples representing waypoints.
 * @param apiKey The API key for authentication.
 * @returns A Promise containing the formatted travel time and distance, or undefined if there's an error.
 */
export async function fetchRoute(
    waypoints: MarkerInfo[],
    apiKey: string
): Promise<{ travelTime: string; distance: string; polyline: string } | undefined> {
    if (waypoints.length < 2) {
        console.error("At least two waypoints are required to fetch a route.");
        return undefined;
    }
    const waypointsQuery = waypoints
        .map((point) => `${point.latitude}, ${point.longitude}`)
        .join('&waypoints=');

    try {
        const response = await fetch(
            `https://api.myptv.com/routing/v1/routes?waypoints=${waypointsQuery}&results=POLYLINE&options[trafficMode]=AVERAGE`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    apiKey: apiKey,
                },
            }
        );

        if (!response.ok) {
            throw new Error(`Error fetching route: ${response.statusText}`);
        }

        const result: RouteResult = await response.json();

        // Assuming convertTime and convertDistance are utility functions returning strings
        const travelTime = convertTime(result.travelTime);
        const distance = convertDistance(result.distance);
        const polyline = result.polyline;

        return {travelTime, distance, polyline};
    } catch (ex: any) {
        console.error("Failed to fetch route:", ex?.message);
        return undefined;
    }
}

function convertDistance(distance: number) {
    const km = distance / 1000;
    const rkm = Math.floor(km);

    const meters = distance - (rkm * 1000);
    const rmeters = Math.round(meters);

    if (rkm === 0) {
        return rmeters + "m";
    }
    return rkm + "." + rmeters + "km";
}

function convertTime(time: number) {
    const hours = time / 60 / 60;
    const rhours = Math.floor(hours);
    const minutes = (hours - rhours) * 60;
    const rminutes = Math.floor(minutes);
    const seconds = time - ((rhours * 3600) + (rminutes * 60));

    var travelTime = []
    if (rhours !== 0)
        travelTime.push(rhours + "h");
    if (rminutes !== 0)
        travelTime.push(rminutes + "min");
    if (seconds !== 0)
        travelTime.push(seconds + "s");

    return travelTime.join(" ");
}