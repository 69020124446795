import * as actionTypes from '../actions/actionTypes';
import {updateObject} from "../../shared/utility";
import update from 'immutability-helper';

const initialState = {
    loading: false,
    error: false,
    alert: {
        state: false,
        variant: 'default',
        count: 0,
        message: '',
        persist: false,
        duration: 5000
    },
    index: null,
    isEdited: false,
    isDisabled: true,
    addFormOpen: false,
    tableExport: {}
}

const startLoading = (state, action) => {
    const updatedItem = updateObject(state[action.index], {
        // initialized: state[action.index]?.initialized ? true : false,
        loading: true,
        initialized: false,
    });

    return updateObject(state, {
        index: action.index,
        [action.index]: updatedItem,
    });
};

const stopLoading = (state, action) => {
    const updatedItem = updateObject(state[action.index], {
        loading: false,
    });

    return updateObject(state, {
        index: action.index,
        [action.index]: updatedItem,
    });
};

const setToggleLoading = (state, action) => {
    return updateObject(state, {loading: action.state});
}

const setError = (state, action) => {
    if (!action.index) {
        return updateObject(state, {loading: false, error: action.error});

    }
    const items = update(state[action.index], {
        loading: {$set: false},
        error: {$set: action.error},
    })

    return updateObject(state, {[action.index]: items});
}

const setAlert = (state, action) => {
    let count = 0;
    if (state) {
        count = state.alert.count + 1;
    }
    const items = update(state['alert'], {
        state: {$set: action.state},
        variant: {$set: action.variant || 'default'},
        message: {$set: action.message || ''},
        persist: {$set: action.persist || false},
        duration: {$set: action.duration || 5000},
        count: {$set: count}
    })

    return updateObject(state, {
        alert: items,
        loading: false,
    });
}

const fetchDataSucces = (state, action) => {
    const index = action.index;

    if (state[index] !== undefined && state[index].currentTab !== undefined && state[index].currentTab !== null) {
        action.object.currentTab = state[index].currentTab;
    } else if ((state[index] === undefined || state[index].currentTab === undefined) && action.object.defaultTabIndex !== null) {
        action.object.currentTab = action.object.defaultTabIndex;
    }
    action.object.loading = false;
    action.object.expanded = [];
    action.object.selectedRows = [];

    return update(state, {$merge: {[index]: action.object}});
};

const saveData = (state, action) => {
    const index = action.index;
    const dataId = action.dataId;   //Number or array with ids of rows
    const object = action.data;

    let items = [...state[index]?.items || []];

    if (state[index] !== undefined) {
        //If array more rows should be updated
        if (Array.isArray(dataId)) {
            dataId.forEach(id => {
                const updateItem = object.find(item => item.id === id);
                if (updateItem) {
                    const itemIndex = items.findIndex(item => item.id === id);
                    if (itemIndex !== -1) {
                        items = update(items, {[itemIndex]: {$set: updateItem}});
                    }
                }
            });
        } else {
            // Update single item
            if (Number.isInteger(dataId) && dataId >= 0 && dataId < items.length) {
                const itemAtIndex = items[dataId];

                if (itemAtIndex) {
                    // Update the item at the given index
                    items = update(items, {[dataId]: {$set: object}});
                } else {
                    console.error('Item does not exist at index:', dataId);
                }
            } else {
                console.error('Invalid index:', dataId);
            }
        }
    } else {
        return updateObject(state, {isEdited: false, isDisabled: true});
    }

    return updateObject(state, {isEdited: false, isDisabled: true, [index]: {...state[index], items}});
}


const insertData = (state, action) => {
    const index = action.index
    const object = action.data

    return update(state, {[index]: {items: {$push: object}}});
}

const deleteData = (state, action) => {
    const index = action.index
    const dataId = action.dataId
    const items = update(state[index], {items: {$splice: [[dataId, 1]]}, selected: {$set: false}})
    return updateObject(state, {[index]: items});
}

const setOpenRecord = (state, action) => {
    const index = action.index
    const items = update(state[index], {openRecord: {$set: action.openRecord ?? false}, action: {$set: action.action}})

    return updateObject(state, {
        [index]: items,
        isEdited: false,
        isDisabled: true,
    });
}

const setSelectedTableRows = (state, action) => {
    const index = action.index
    const items = update(state[index], {selectedRows: {$set: action.selectedRows ?? []}})

    return updateObject(state, {
        [index]: items,
    });
}

// const setExpandedTableRows = (state, action) => {
//     const index = action.index
//     const toggled = action.toggled
//
//
//     console.log(action)
//     const value = state[index].expanded !== undefined && state[index].expanded[toggled.rowId] !== undefined && state[index].expanded[toggled.rowId] === toggled.actionComponent ? false : toggled.actionComponent
//     console.log(value, 'value')
//     const items = update(state[index], {expanded: {$merge: {[toggled.rowId]: value}}})
//     console.log(items)
//     return updateObject(state, {
//         [index]: items,
//     });
// }

const setExpandedTableRows = (state, action) => {
    const {index, toggled} = action;
    const expanded = state[index].expanded || {};

    const newExpanded = {...expanded};
    if (newExpanded[toggled.rowId]) {
        delete newExpanded[toggled.rowId];
    } else {
        newExpanded[toggled.rowId] = toggled.actionComponent;
    }
    // Update the state with the new expanded object
    const items = update(state[index], {expanded: {$set: newExpanded}});
    return updateObject(state, {
        [index]: items,
    });
}

const setDialogSelected = (state, action) => {
    const index = action.index

    const items = update(state[index], {
        open: {$set: action.open},
        openRecord: {$set: action.openRecord ?? false},
        action: {$set: action.action}
    })

    return updateObject(state, {
        [index]: items,
        isEdited: false,
        isDisabled: action.disabled,
    });
}

const setHandle = (state, action) => {
    return updateObject(state, {handle: action.handle});
}

const setAddFormOpen = (state, action) => {
    return updateObject(state, {addFormOpen: action.isOpen});
}

const setCurrentTab = (state, action) => {
    const index = action.index
    const items = update(state[index], {currentTab: {$set: action.tab}})

    return updateObject(state, {
        [index]: items
    });
}

const setEdited = (state, action) => {
    return updateObject(state, {isEdited: action.isEdited});
}

const setDisabled = (state) => {
    return updateObject(state, {isDisabled: false});
}

const reset = () => {
    return initialState;
}

const exportDataTable = (state, action) => {
    const index = action.index;
    const company = action.company;
    const existingItems = JSON.parse(localStorage.getItem('tableSettings')) || {};

    if (Object.keys(existingItems).length !== 0 && Object.prototype.hasOwnProperty.call(existingItems, company)) {
        existingItems[company][index] = action.exportTable;
    } else {
        existingItems[company] = {};
        existingItems[company][index] = action.exportTable;
    }

    localStorage.setItem('tableSettings', JSON.stringify(existingItems));

    return update(state, {
        [index]: {
            $apply: (item = {}) => update(item, {
                initialized: {$set: false},
                tableExport: {$set: action.exportTable}
            })
        }
    });
};

const clearAllTableSettings = (state) => {
    return updateObject(state, {
        tableExport: {}
    });
}
const clearTableSettings = (state, action) => {
    const index = action.index;
    return updateObject(state, {
        tableExport: {[index]: {}}
    });
}

const updateDataTableRecord = (state, action) => {
    const index = action.index
    const dataId = action.dataId
    const object = action.data

    let items = [];
    if (state[index] !== undefined) {
        items = update(state[index], {items: {$merge: {[dataId]: object}}})
    }

    return updateObject(state, {isEdited: false, [index]: items});
}

const removeDataTableRecord = (state, action) => {
    const index = action.index
    const rowIndex = action.rowIndex
    let items = [];
    if (state[index] !== undefined) {
        items = update(state[index], {items: {$splice: [[rowIndex, 1]]}, openRecord: {$set: false}})
    }

    return updateObject(state, {isEdited: false, [index]: items});
}

const clearDataTableIndex = (state, action) => {
    const index = action.index
    return update(state, {
        [index]: {$set: {}}
    });
}

const setDataTableAction = (state, action) => {
    const index = action.index
    const items = update(state[index], {action: {$set: action.action}})

    return updateObject(state, {
        [index]: items
    });
}

const setDataTableMode = (state, action) => {
    const index = action.index
    const tableMode = action.state
    const rowIndex = action.rowIndex

    let items = [];
    if (state[index] !== undefined) {
        // items = update(state[index], {$merge: {mode: tableMode}})
        items = update(state[index], {items: {[rowIndex]: {$merge: {mode: tableMode}}}})
    }

    return updateObject(state, {isEdited: false, [index]: items});
}

const updateDataTableSingleValue = (state, action) => {
    const index = action.index
    let items = [];
    if (state[index] !== undefined) {
        items = update(state[index], {items: {[action.rowIndex]: {$merge: {[action.columnName]: action.newValue}}}})
    }

    return updateObject(state, {[index]: items});
}

const successFetchTabs = (state, action) => {
    const index = action.index;
    const data = action.data;

    const updatedState = updateObject(state[index], {
        tabs: data.tabs,
        defaultTabIndex: data.defaultTabIndex,
        tabsInitialized: true,
    });

    return {
        ...state,
        [index]: updatedState,
    };
}
const requestError = (state, action) => {

    const index = action.index;

    const updatedState = updateObject(state[index], {
        columns: [],
    });

    return {
        ...state,
        [index]: updatedState,
    };

}

const setInitialized = (state, action) => {
    const index = action.index;
    const updatedState = updateObject(state[index], {
        initialized: true,
    })

    return {
        ...state,
        [index]: updatedState,
    };
};

//Update columns with Muidatagrid settings
const successSetColumns = (state, action) => {
    const index = action.index;
    const columns = action.newColumns;

    let updatedItems = [];
    if (state[index]) {
        updatedItems = [...columns];
    }

    const updatedState = updateObject(state[index], {
        initialized: true,
        columns: updatedItems
    })

    return {
        ...state,
        [index]: updatedState,
    };
}

const successFetchData = (state, action) => {
    const index = action.index;
    const data = action.data;

    // Assuming 'update' is a utility function that merges the provided 'data' with existing 'state[index].items'
    let updatedItems = [];
    if (state[index]) {
        updatedItems = [...data.items];
    }

    // Assuming 'updateObject' is a utility function to merge the provided 'data' with the existing state[index]
    const updatedState = updateObject(state[index], {
        items: updatedItems,
        meta: data._meta,
        links: data._links,
        columns: data.columns,
        filters: data.filters,
        tabs: data.tabs,
        open: false,
        expanded: [],
        // loading: false,
        openRecord: false,
        selectedRows: [],
        defaultTabIndex: data.defaultTabIndex,
    });

    // Finally, update the state with the new 'updatedState'
    return {
        ...state,
        [index]: updatedState,
    };
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_LOADING:
            return startLoading(state, action);
        case actionTypes.STOP_LOADING:
            return stopLoading(state, action);
        case actionTypes.TOGGLE_LOADING:
            return setToggleLoading(state, action);
        case actionTypes.FETCH_DATA_TABLE_START:
            return startLoading(state, action);
        case actionTypes.FETCH_DATA_TABLE_SUCCESS:
            return fetchDataSucces(state, action);
        case actionTypes.FETCH_DATA_TABLE_FAIL:
            return setError(state, action);
        case actionTypes.UPDATE_DATA_TABLE_RECORD:
            return updateDataTableRecord(state, action);
        case actionTypes.INSERT_DATA_TABLE_RECORD:
            return updateDataTableRecord(state, action);
        case actionTypes.REMOVE_DATA_TABLE_RECORD:
            return removeDataTableRecord(state, action);
        case actionTypes.CLEAR_DATA_TABLE_INDEX:
            return clearDataTableIndex(state, action);
        case actionTypes.SET_DATA_TABLE_ACTION:
            return setDataTableAction(state, action);
        case actionTypes.SET_DATA_TABLE_MODE:
            return setDataTableMode(state, action);
        case actionTypes.UPDATE_DATA_TABLE_VALUE:
            return updateDataTableSingleValue(state, action);

        case actionTypes.SET_ERROR:
            return setError(state, action);
        case actionTypes.SET_ALERT:
            return setAlert(state, action);
        case actionTypes.SELECT_DATA_TABLE_ROW:
            return setOpenRecord(state, action);
        case actionTypes.CLOSE_DATA_TABLE_ROW:
            return setOpenRecord(state, action);
        case actionTypes.SELECT_TABLE_ROWS:
            return setSelectedTableRows(state, action);
        case actionTypes.EXPAND_TABLE_ROWS:
            return setExpandedTableRows(state, action);
        case actionTypes.OPEN_DIALOG_TABLE:
            return setDialogSelected(state, action);
        case actionTypes.CLOSE_DIALOG_TABLE:
            return setDialogSelected(state, action);
        case actionTypes.SET_ACTION_HANDLE:
            return setHandle(state, action);
        case actionTypes.SAVE_DATA_SUCCESS:
            return saveData(state, action);
        case actionTypes.ADD_DATA_SUCCESS:
            return insertData(state, action);
        case actionTypes.DELETE_DATA_SUCCESS:
            return deleteData(state, action);
        case actionTypes.FORM_EDITED:
            return setEdited(state, action);
        case actionTypes.SET_DISABLED:
            return setDisabled(state, action);
        case actionTypes.SET_ADD_FORM_OPEN:
            return setAddFormOpen(state, action);
        case actionTypes.SET_TAB_SUCCESS_SAGA:
            return setCurrentTab(state, action);
        case actionTypes.RESET_DATA_TABLE:
            return reset(state, action);
        case actionTypes.EXPORT_DATA_TABLE:
            return exportDataTable(state, action);
        case actionTypes.CLEAR_ALL_DATA_TABLE_SETTINGS:
            return clearAllTableSettings(state, action);
        case actionTypes.CLEAR_DATA_TABLE_SETTINGS:
            return clearTableSettings(state, action);
        case actionTypes.SET_TAB_FAILURE_SAGA :
        case actionTypes.FETCH_TABS_FAILURE_SAGA :
        case actionTypes.FETCH_DATA_FAILURE_SAGA :
            return requestError(state, action);
        case actionTypes.FETCH_DATA_SUCCESS_SAGA :
            return successFetchData(state, action);
        case actionTypes.SET_DATA_MUIDATAGRID_SUCCESS_SAGA:
            return successSetColumns(state, action);
        case actionTypes.FETCH_TABS_SUCCESS_SAGA :
            return successFetchTabs(state, action);
        case actionTypes.SET_TABLE_INITIALIZED :
            return setInitialized(state, action);
        default:
            return state;
    }
}

export default reducer;