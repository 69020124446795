import React, {} from "react";
import PropTypes from "prop-types";
import {Tooltip} from "@mui/material";
import Info from '@mui/icons-material/Info';

const InfoIcon = (props) => {
    return (
        <Tooltip
            disabled={props.disabled}
            placement={props.labelDirection}
            title={!props.disabled ? props.title : ''}>
            <Info
                style={props.style}
                sx={{marginLeft: props.marginLeft || '5px', cursor: 'pointer'}}
                color={props.disabled ? 'disabled' : props.color || 'secondary'}
                disabled={props.disabled || false}
            />
        </Tooltip>
    )
}

export default InfoIcon;

InfoIcon.propTypes = {
    marginLeft: PropTypes.string,
    color: PropTypes.string,
    disabled: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    style: PropTypes.object,
    noPadding: PropTypes.bool,
    labelDirection: PropTypes.oneOf(['bottom-end', 'bottom-start', 'bottom', 'left-end', 'left-start', 'left', 'right-start', 'right-end', 'right', 'top-end', 'top-start', 'top']),
};