// sagas.js
import {put, call, takeLatest, takeEvery, all, delay} from 'redux-saga/effects';
import {
    FETCH_DATA_REQUEST_SAGA,
    FETCH_DATA_FAILURE_SAGA,
    FETCH_DATA_SUCCESS_SAGA,
    FETCH_TABS_REQUEST_SAGA,
    FETCH_TABS_SUCCESS_SAGA,
    FETCH_TABS_FAILURE_SAGA,
    SET_LOADING,
    SET_TAB_REQUEST_SAGA,
    SET_TAB_SUCCESS_SAGA,
    SET_TAB_FAILURE_SAGA,
    STOP_LOADING,
    SET_DATA_MUIDATAGRID_REQUEST_SAGA, SET_DATA_MUIDATAGRID_SUCCESS_SAGA, SET_DATA_MUIDATAGRID_FAILURE_SAGA, SET_ALERT,
} from "../actions/actionTypes";
import axios from "../../api/axios-peer";

import {
    getNestedValues,
    setAggregableFields,
    setCustomColorSelectFilter,
    setFilterOperators, setGroupOnColumn, setPercentageFields, setRenderEditFields, setRenderFields,
    setSingleSelectFilterOperators
} from "./datatableFunctions";

/**
 *
 * @param action
 * @returns {Generator<*, void, *>}
 */
function* fetchDataSaga(action) {
    try {
        const {
            url,
            index,
            numberOfRow,
            page,
            sortOrder,
            urlAddition,
            searchParams,
            activeTabFilter,
            resource,
            callback,
            routeFilter
        } = action;

        yield put({type: SET_LOADING, index});

        // Construct the URL with the query parameters
        let fullUrl = url;

        let questionmarked = url.includes("?");

        if (numberOfRow !== null) {
            if (questionmarked === false) {
                questionmarked = true;
                fullUrl += "?";
            } else {
                fullUrl += "&";
            }
            fullUrl += "per-page=" + numberOfRow;
        }

        if (page !== null) {
            if (questionmarked === false) {
                questionmarked = true;
                fullUrl += "?";
            } else {
                fullUrl += "&";
            }
            fullUrl += "page=" + (page + 1);
        }

        if (urlAddition !== null) {
            if (questionmarked === false) {
                questionmarked = true;
                fullUrl += "?";
            } else {
                fullUrl += "&";
            }
            fullUrl += urlAddition;
        }

        if (searchParams !== null) {
            if (questionmarked === false) {
                questionmarked = true;
                fullUrl += "?";
            } else {
                fullUrl += "&";
            }
            fullUrl += searchParams;
        }

        if (routeFilter !== null) {
            if (questionmarked === false) {
                questionmarked = true;
                fullUrl += "?";
            } else {
                fullUrl += "&";
            }
            fullUrl += routeFilter;
        }

        if (activeTabFilter) {
            if (questionmarked === false) {
                questionmarked = true;
                fullUrl += "?";
            } else {
                fullUrl += "&";
            }

            fullUrl += activeTabFilter;
        }

        if (sortOrder !== null) {
            if (questionmarked === false) {
                fullUrl += "?";
            } else {
                fullUrl += "&";
            }
            fullUrl += "sort=" + sortOrder;
        }

        // Use the provided resource or the default axios
        const rest = resource || axios;

        // Make the API request using the provided HTTP client library
        const response = yield call(rest.get, fullUrl, {responseType: 'json'});

        // If a callback is provided, invoke it with the response data
        if (callback) {
            callback(response.data);
        }
        // Dispatch the success action with the response data
        yield put({type: FETCH_DATA_SUCCESS_SAGA, index, data: response.data});
        yield put({
            type: SET_DATA_MUIDATAGRID_REQUEST_SAGA,
            index,
            data: response.data?.columns || [],
            tableFilters: response.data?.filters || []
        });
    } catch (error) {
        yield put({type: STOP_LOADING, index: action.index});
        yield put({
            type: SET_ALERT,
            index: action.index,
            message: action.index + ': ' + error.message,
            state: true,
            variant: 'error'
        });
        yield put({type: FETCH_DATA_FAILURE_SAGA, index: action.index, error: action.index + ': ' + error.message});
    } finally {
        // yield put({type: STOP_LOADING, index: action.index});
    }
}

/**
 *
 * @param action
 * @returns {Generator<*, void, *>}
 */
function* fetchTabsSaga(action) {
    try {
        const {
            url,
            index,
            resource
        } = action;

        let fullUrl = url;

        fullUrl += fullUrl.endsWith('/') ? "tabs" : "/tabs"

        // Use the provided resource or the default axios
        const rest = resource || axios;

        // Make the API request using the provided HTTP client library
        const response = yield call(rest.get, fullUrl, {responseType: 'json'});
        // Dispatch the success action with the response data
        yield put({type: FETCH_TABS_SUCCESS_SAGA, index, data: response.data});
    } catch (error) {
        // Dispatch the failure action in case of an error
        yield put({type: FETCH_TABS_FAILURE_SAGA, index: action.index, error: error.message});
        yield put({
            type: SET_ALERT,
            index: action.index,
            message: action.index + ': ' + error.message,
            state: true,
            variant: 'error'
        });
    }
}

/**
 *
 * @param action
 * @returns {Generator<*, void, *>}
 */
function* setTabsSaga(action) {
    try {
        const {
            index,
            tab,
            currentTab
        } = action;
        yield put({type: SET_TAB_SUCCESS_SAGA, index, tab, currentTab});
    } catch (error) {
        // Dispatch the failure action in case of an error
        yield put({type: SET_TAB_FAILURE_SAGA, index: action.index, error: `${error.message} - cannot set tab`});
    }
}

/**
 * Helper function to process each column
 *
 * @param item
 * @param columnIndex
 * @param index
 * @param tableFilters
 * @returns {Generator<*, *, *>}
 */
function* processColumn(item, columnIndex, index, tableFilters) {
    if (!item.field) return item;

    const newItem = {...item};

    yield all([
        call(setFilterOperators, newItem),
        // call(handleFilterField, newItem),
        call(setSingleSelectFilterOperators, newItem, columnIndex, tableFilters),
        call(setCustomColorSelectFilter, newItem, index),
        call(getNestedValues, newItem),
        call(setGroupOnColumn, newItem, tableFilters),
        call(setAggregableFields, newItem),
        call(setPercentageFields, newItem),
        call(setRenderFields, newItem),
        call(setRenderEditFields, newItem, index),
    ]);

    return newItem;
}

/**
 * Update certain custom column info for Mui Data Grid
 *
 * @param action
 * @returns {Generator<*, void, *>}
 */
function* setDataMuiDataGrid(action) {
    try {
        const {index, data, tableFilters} = action;

        const chunkSize = 100;
        let newColumns = [];

        for (let i = 0; i < data.length; i += chunkSize) {
            const chunk = data.slice(i, i + chunkSize);
            const processedChunk = yield all(
                chunk.map((item, chunkIndex) =>
                    call(processColumn, item, i + chunkIndex, index, tableFilters)
                )
            );
            newColumns = [...newColumns, ...processedChunk];

            yield delay(0); // `delay(0)` allows other sagas to run in between chunks
        }
        yield put({type: SET_DATA_MUIDATAGRID_SUCCESS_SAGA, index, newColumns});
    } catch (error) {
        // Dispatch the failure action in case of an error
        yield put({
            type: SET_DATA_MUIDATAGRID_FAILURE_SAGA,
            index: action.index,
            error: `${error.message} - cannot set data correctly for mui`
        });
    } finally {
        yield put({type: STOP_LOADING, index: action.index});
    }
}

/**
 * Watch for FETCH_TABS_REQUEST and run fetchTabsSaga on latest occurrence
 * @returns {Generator<*, void, *>}
 */
export function* watchFetchTabs() {
    yield takeEvery(FETCH_TABS_REQUEST_SAGA, fetchTabsSaga);
}

/**
 * Watch for FETCH_DATA_REQUEST and run fetchDataSaga on latest occurrence
 * @returns {Generator<*, void, *>}
 */
export function* watchFetchData() {
    yield takeEvery(FETCH_DATA_REQUEST_SAGA, fetchDataSaga);
}

/**
 * Watch for FETCH_DATA_REQUEST and run fetchDataSaga on latest occurrence
 * @returns {Generator<*, void, *>}
 */
export function* watchSetTab() {
    yield takeLatest(SET_TAB_REQUEST_SAGA, setTabsSaga);
}

/**
 * Watch for FETCH_DATA_REQUEST and run fetchDataSaga on latest occurrence
 * @returns {Generator<*, void, *>}
 */
export function* watchFetchDataComplete() {
    yield takeEvery(SET_DATA_MUIDATAGRID_REQUEST_SAGA, setDataMuiDataGrid);
}