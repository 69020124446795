import "../Styling/OverlayControl.css";
import React, {useEffect, useState} from 'react';
import {FormControlLabel, Checkbox, Typography, Paper, Box} from '@mui/material';
import {OverlayControls} from "../Interfaces/MapInterfaces";
import i18n from "../../../i18n/i18n";


const OverlayControl = (props: OverlayControls) => {
    const {mapOverlay = [], onChange} = props;

    const [trafficPatternsChecked, setTrafficPatternsChecked] = useState(false);
    const [truckRestrictionsChecked, setTruckRestrictionsChecked] = useState(false);
    const [lowEmissionZonesChecked, setLowEmissionZonesChecked] = useState(false);
    const [tollRoadsChecked, setTollRoadsChecked] = useState(false);

    useEffect(() => {
        setTrafficPatternsChecked(mapOverlay.includes('traffic-patterns'));
        setTruckRestrictionsChecked(mapOverlay.includes('truck-restrictions'));
        setLowEmissionZonesChecked(mapOverlay.includes('low-emission-zones'));
        setTollRoadsChecked(mapOverlay.includes('toll'));
    }, [mapOverlay]);

    useEffect(() => {
        const overlays = [];
        if (trafficPatternsChecked) {
            overlays.push("traffic-patterns");
        }
        if (truckRestrictionsChecked) {
            overlays.push("truck-restrictions");
        }
        if (lowEmissionZonesChecked) {
            overlays.push("low-emission-zones");
        }
        if (tollRoadsChecked) {
            overlays.push("toll");
        }
        onChange(overlays);
    }, [trafficPatternsChecked, truckRestrictionsChecked, lowEmissionZonesChecked, tollRoadsChecked, onChange]);

    return (
        <Paper
            className="control-panel overlay-control "
            sx={{
                backgroundColor: 'rgba(128, 128, 128, 0.5)',
                borderRadius: 2
            }}
        >
            <Typography variant={'h6'}>Overlay</Typography>
            {mapOverlay.includes('traffic-patterns') && (
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={trafficPatternsChecked}
                            onChange={() => setTrafficPatternsChecked(!trafficPatternsChecked)}
                        />
                    }
                    label={<Typography variant="body1">{i18n.t('TITLE_MAP_TRAFFIC_PATTERNS')}</Typography>}
                />
            )}
            {mapOverlay.includes('truck-restrictions') && (
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={truckRestrictionsChecked}
                            onChange={() => setTruckRestrictionsChecked(!truckRestrictionsChecked)}
                        />
                    }
                    label={<Typography variant="body1">{i18n.t('TITLE_MAP_TRUCK_RESTRICTIONS')}</Typography>}
                />
            )}
            {mapOverlay.includes('low-emission-zones') && (
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={lowEmissionZonesChecked}
                            onChange={() => setLowEmissionZonesChecked(!lowEmissionZonesChecked)}
                        />
                    }
                    label={<Typography variant="body1">{i18n.t('TITLE_MAP_LOW_EMISSION')}</Typography>}
                />
            )}
            {mapOverlay.includes('toll') && (
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={tollRoadsChecked}
                            onChange={() => setTollRoadsChecked(!tollRoadsChecked)}
                        />
                    }
                    label={<Typography variant="body1">{i18n.t('TITLE_MAP_TOLL_ROADS')}</Typography>}
                />
            )}
        </Paper>
    );
};

export default OverlayControl;
