import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Grid2";
import TemplateTextareaAutosize from './Components/TemplateTextArea';
import PropTypes from "prop-types";
import {fetchContent} from "../../../shared/download";
import {useParams} from "react-router-dom";
import i18n from "../../../i18n/i18n";

export default function TemplateFormSettings(props) {

    const [logoLoaded, setLogoLoaded] = useState(false);
    const [checkSource, setCheckSource] = useState(false);
    const {auth_key} = useParams();

    const {
        formField: {
            template_project_description,
        }
    } = props;

    //Empty id if form is opened trough copy icon so new template will be created
    useEffect(() => {
        if (props.action === 'copy' && !props.copyId) {
            props.handleCopyId(props.values.id)
            props.setFieldValue("id", null)
            if (props.values.location_origin_id) {
                props.setFieldValue("location_origin_id", undefined)
            }
        } else {
            props.setFieldValue("id", props.values.id)
        }
    }, [props.action])


    // ==============================
    // Convert image
    // ==============================
    const setFetchedDialogContent = (response) => {
        const contentType = response.headers['content-type'];
        if (contentType.includes('base64')) {
            const type = contentType.replace('+base64', '');
            props.setFieldValue("logo_image", "data:" + type + ";base64, " + response.data);
            setLogoLoaded(true)
            return
        }
        const data = response.data;
        const base64 = Buffer.from(data).toString("base64");
        props.setFieldValue("logo_image", "data:" + contentType + ";base64, " + base64);
        setLogoLoaded(true)
    }

    useEffect(() => {
        if (!props.values) {
            return;
        }
        if (props.values.logo_image) {
            let url = props.values.logo_image;

            setCheckSource(props.values.logo_image.substring(0, 4))
            if (auth_key) {
                url = props.values.logo_image + '&auth_key=' + auth_key
            }
            fetchContent(url, null, null, null, (response) => setFetchedDialogContent(response), auth_key)
        }
    }, [])

    // ==============================
    // TemplateFormSettings
    // ==============================
    return <React.Fragment>
        <Grid container size={12}>
            <Grid container size={12}>
                <h2>{i18n.t('SETTINGS')}</h2>
            </Grid>
            <Grid container size={{xs: 12, sm: 6}}>
                {
                    logoLoaded || checkSource === 'data' ? (
                        <React.Fragment>
                            <h4>Afbeelding</h4>
                            <img alt="logo"
                                 draggable={false}
                                 src={props.values.logo_image}
                                 style={{maxWidth: '80%', height: 'auto', objectFit: "contain"}}/>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <h4>{!props.disabled ? "Upload afbeelding" : ''}</h4>
                        </React.Fragment>
                    )
                }
            </Grid>
            <Grid container size={{xs: 12, sm: 6}}>
                <h4>{template_project_description.label}</h4>
                <br/><br/>
                <TemplateTextareaAutosize
                    disabled={props.disabled}
                    name={template_project_description.name}
                />
            </Grid>
        </Grid>
    </React.Fragment>
}

TemplateFormSettings.propTypes = {
    disabled: PropTypes.bool.isRequired,
    formField: PropTypes.object.isRequired,
    generalData: PropTypes.object,
    setFieldValue: PropTypes.func.isRequired,
    values: PropTypes.object,
    action: PropTypes.string,
    copyId: PropTypes.number,
    handleCopyId: PropTypes.func,
};