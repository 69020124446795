import React, {useState} from 'react';
import {styled} from '@mui/material/styles';
import {FixedSizeList} from "react-window";
import Grid from "@mui/material/Grid2";
import BusinessIcon from '@mui/icons-material/Business';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SearchBar from "../../../Controls/SearchBar";
import PropTypes from "prop-types";
import WindowDimensions from "../../../WindowDimensions/WindowDimensions";
import axios from "../../../../api/axios-peer";
import {fetchDataFail} from "../../../../store/actions/dataTableAction";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import {useDispatch} from "react-redux";
import LoadingBar from "../../../Animation/LoadingBar";
import {Typography} from "@mui/material";

const PREFIX = 'SearchVihbDialog';

const classes = {
    rowEven: `${PREFIX}-rowEven`,
    rowOdd: `${PREFIX}-rowOdd`,
    emptyContainer: `${PREFIX}-emptyContainer`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() => ({
    [`& .${classes.rowEven}`]: {
        background: "#F5F5F5",
        padding: '20px',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        cursor: 'pointer',
        "& .MuiSvgIcon-root": {color: "rgba(0, 0, 0, 0.54)",},
        '&:hover': {
            backgroundColor: '#D2D8DD',
            "& .MuiSvgIcon-root": {color: "#203A56",},
        },
    },

    [`& .${classes.rowOdd}`]: {
        background: "#FFFFFF",
        padding: '20px',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        cursor: 'pointer',
        "& .MuiSvgIcon-root": {color: "rgba(0, 0, 0, 0.54)",},
        '&:hover': {
            backgroundColor: '#D2D8DD',
            "& .MuiSvgIcon-root": {color: "#203A56",},
        }
    },

    [`& .${classes.emptyContainer}`]: {
        background: "#F5F5F5",
        padding: '20px',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        minHeight: '100px',
        marginTop: '20px',
    }
}));

export default function SearchVihbDialog(props) {


    const dispatch = useDispatch();
    const data = props.data;
    const [loading, setLoading] = useState(false);
    const [filteredData, setFilteredData] = useState(props.data);
    const {height, width} = WindowDimensions();

    const handleChange = async text => {
        await searchAPIDebounced(text);
    };

    const search = (filter) => {
        if (filter === undefined || filter === "") {
            return;
        }
        if (filter.length < 3) {
            setFilteredData([]);
            return;
        }

        setLoading(true);
        let isMounted = true;
        axios({
            url: 'vihb?per-page=&page=1&search=' + filter + '&filterNotInUse=false',
        }).then(response => {
            if (isMounted) setFilteredData(response.data);
            if (isMounted) setLoading(false);
        }).catch(error => {
            dispatch(fetchDataFail(error.message))
        })
        return () => {
            isMounted = false
        };
    }

    const searchAPIDebounced = AwesomeDebouncePromise(search, 500);

    // =====================
    // Handle Selected Company
    // =====================
    const onItemClickHandler = (selectedCompany) => {
        props.handleCompanySelected(selectedCompany)
        setFilteredData(data);
    }

    const clearFilter = () => {
        setFilteredData([]);
    }

    const noItemsLabel = "Vul tenminste 3 karakters in om te zoeken";
    // =====================
    // Company rows
    // =====================
    const list = filteredData.items;
    const renderRow = ({index, style}) => {
        return (
            <div key={list[index].id} style={style} className={index % 2 === 0 ? classes.rowEven : classes.rowOdd}
                 onClick={onItemClickHandler.bind(this, list[index])}>
                <Grid container>
                    <Grid size={{xs: 2, md: 1}}>
                        <BusinessIcon/>
                    </Grid>
                    <Grid size={{xs: 10, md: 5}}>
                        <Typography variant="body2">{list[index].name}</Typography>
                        <Typography variant="body2">Vihb: {list[index].vihb_full}</Typography>
                        <Typography variant="body2">Kvk: {list[index].kvk}</Typography>
                    </Grid>
                    <Grid size={{xs: 2, md: 1}}>
                        <LocationOnIcon/>
                    </Grid>
                    <Grid size={{xs: 10, md: 5}}>
                        {/*<strong>Adres:</strong><br/>*/}
                        <Typography variant="body2">{list[index].street_name} {list[index].building_number} {list[index].building_number_addition}</Typography>
                        <Typography variant="body2">{list[index].postal_zone}</Typography>
                        <Typography variant="body2">{list[index].city_name}</Typography>
                        <br/>
                    </Grid>
                </Grid>
            </div>
        )
    };

    return (
        <Root>
            <Grid container>
                <Grid size={12}>
                    <SearchBar
                        handleChange={handleChange}
                        clearFilter={clearFilter}
                    />
                </Grid>
            </Grid>
            {
                !filteredData.items ? (
                    <div>
                        <div className={classes.emptyContainer}>
                            <Typography variant="body2">{noItemsLabel}</Typography>
                        </div>
                    </div>
                ) : (
                    <FixedSizeList
                        height={700}
                        itemCount={filteredData.items ? filteredData.items.length : null}
                        itemSize={width < 768 ? 190 : 100}
                    >
                        {renderRow}
                    </FixedSizeList>
                )
            }
            <br/>
            {loading ? <LoadingBar/> : null}
        </Root>
    );
}

SearchVihbDialog.propTypes = {
    handleCompanySelected: PropTypes.func.isRequired,
    data: PropTypes.array
};