import axios, {AxiosInstance, AxiosRequestConfig, AxiosResponse} from 'axios';
import formklub from "../api/axios-formklub";
import peer from "../api/axios-peer";

/**
 *
 * @param string
 */
const isValidHttpUrl = (string: string) => {
    let url;

    try {
        url = new URL(string);
    } catch (_e) {
        return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
}

/**
 *
 * @param url
 * @param contentType
 * @param contentEncoding
 * @param loadingCallback
 * @param contentCallback
 */
type LoadingCallback = (isLoading: boolean) => void;
type ContentCallback = (response: AxiosResponse) => void;

export const fetchContent = (
    url: string,
    contentType: string | null = null,
    contentEncoding: string | null = null,
    loadingCallback: LoadingCallback | null = null,
    contentCallback: ContentCallback | null = null
): string | void => {
    if (!isValidHttpUrl(url)) {
        return url; // Return the URL as is if it's not valid
    }

    let axiosObject: AxiosInstance = axios; // Default to the main axios instance
    if (loadingCallback) {
        loadingCallback(true);
    }

    // Use a fallback value to prevent undefined errors
    let formklubFrontendDomain = (formklub.defaults.baseURL || "").replace("https://api.", "").replace("http://api.", "");

    // Initialize config with guaranteed headers
    const config: AxiosRequestConfig = {
        headers: {
            "Content-Encoding": 'base64', // Always set Content-Encoding
            "Content-Type": contentType || '', // Set Content-Type if available
            Accept: contentType || '', // Set Accept if available
        },
    };

    // Determine which axios instance to use
    if (url.includes(peer.defaults.baseURL || "")) {
        axiosObject = peer; // Use peer axios instance
    } else if (url.includes(formklub.defaults.baseURL || "") || url.includes(formklubFrontendDomain)) {
        axiosObject = formklub; // Use formklub axios instance
    } else if (contentType !== null && contentType.includes('application/pdf+base64')) {
        contentType = 'application/pdf'; // Normalize contentType
    }

    // Configure the response type and encoding for PDF
    if (contentType === 'application/pdf') {
        config.responseType = "arraybuffer"; // Expect arraybuffer response for PDFs
        config.responseEncoding = "binary"; // Set response encoding
    }

    // Make the GET request using axios
    axiosObject.get(url + "#no-region-filter", config)
        .then(response => {
            if (response.data === null) {
                return; // Early exit if response data is null
            }
            if (contentCallback) {
                contentCallback(response); // Call content callback with response
            }
            if (loadingCallback) {
                loadingCallback(false); // Stop loading
            }
        })
        .catch(() => {
            if (loadingCallback) {
                loadingCallback(false); // Stop loading on error
            }
        });
};