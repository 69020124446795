import React from "react";
import {Button, Typography} from "@mui/material";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import customClasses from "./Registration.module.css";
import i18n from "../../../i18n/i18n";
import Grid from '@mui/material/Grid2';

export default function RegistrationTabTemplate() {
    const handleSubmit =()=>{
        window.location.href='/'
    }

    return (
        <>
            <br/>
            <Typography align='center' variant="h6">{i18n.t('ACCOUNT_CREATED')}</Typography>
            <Grid container align="center" >
                <Grid size={12}>
                    <br/>
                    <AccountCircleIcon className={customClasses.registrationCompleteIcon}/>
                    <Typography align='center' variant="body2">
                        {i18n.t('ACCOUNT_CREATED_INFO')}
                    </Typography>
                    <br/>
                </Grid>
                <Grid size={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={handleSubmit}
                        style={{ textTransform: "none" }}>{i18n.t('BACK_TO_LOGIN')}</Button>
                </Grid>
            </Grid>
        </>
    )
}