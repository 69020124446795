import {fetchData} from "./dataTableAction";
import {useDispatch, useSelector} from "react-redux";
import {getScopeFormklub} from "../selectors/scopeSelector";
import {AppDispatch} from "../../@types/types";

/**
 * Custom hook to fetch devices
 */
export const useFetchDevices = () => {
    const dispatch: AppDispatch = useDispatch();
    const resourceScope = useSelector(state => getScopeFormklub(state));

    const fetchDevices = (
        size: number,
        page: number,
        sort: string | null,
        state: string,
        device_type: number[],
        hookOnly: boolean,
        serialize: boolean,
        expand?: string[],
        searchParams?: string | null,
        activeTabFilter?: string | null,
        callback?: ((data: any) => void) | null,
        routeFilter?: string | null,
    ) => {
        const queryParams = new URLSearchParams();

        queryParams.append('filter[state_id]', state);

        device_type.forEach(type => {
            queryParams.append('filter[device_type_id][]', type.toString());
        });

        if (hookOnly) {
            queryParams.append('filter[hookOnly]', 'true');
        }

        if (serialize) {
            queryParams.append('serialize', 'true');
        }

        queryParams.append('no-region-filter', '');

        if (expand && expand.length > 0) {
            queryParams.append('expand', expand.join(','));
        }

        const queryString = queryParams.toString();

        // Dispatch the fetch action
        dispatch(fetchData(
            '/device',
            'weigh_bridge',
            size,
            page,
            sort,
            queryString,
            searchParams,
            activeTabFilter,
            resourceScope?.resource ?? null,
            callback,
            routeFilter,
        ));
    };

    return {fetchDevices};
};