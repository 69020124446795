import React, {useEffect, useState} from "react";
import {Typography, Autocomplete, TextField, Alert, CircularProgress, AlertTitle} from "@mui/material";
import {useDispatch} from "react-redux";
import {fetchDataFail} from "../../../store/actions/dataTableAction";
import LoadingBar from "../../../components/Animation/LoadingBar";
import axios from "../../../api/axios-peer";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import Button from "@mui/material/Button";
import i18n from "../../../i18n/i18n";
import PropTypes from "prop-types";
import Grid from '@mui/material/Grid2';

export default function RegistrationTabFind(props) {

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [companies, setCompanies] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [skipVihb, setSkipVihb] = useState(false);
    const [companyExists, setCompanyExists] = useState(false);

    const handleTextChange = async text => {
        await searchAPIDebounced(text);
    };

    // ==========================
    // Search Company VIHB list
    // ==========================
    const search = (filter) => {
        setSearchValue(filter);
        if (filter === undefined || filter === "" || filter === 0) {
            return;
        }
        if (filter.length < 3) {
            setCompanies([]);
            return;
        }

        setLoading(true);
        axios({
            url: 'vihb?per-page=&page=1&search=' + filter + '&filterNotInUse=false',
        }).then(response => {
            setCompanies(response.data);
            setLoading(false)
        }).catch(error => {
            dispatch(fetchDataFail(error.message))
            setLoading(false)
        })
    }

    const searchAPIDebounced = AwesomeDebouncePromise(search, 500);

    useEffect(() => {
        const getSelectedCompany = props.vihb.value;
        const searchOn = 'vihb_full';

        if (companyItems !== null) {
            const selectedCompany = companyItems.find((element) => {
                return element[searchOn] === getSelectedCompany
            })

            if (!selectedCompany) {
                return
            }
            props.handleCompanySelected(selectedCompany)
        }

    }, [props.bedrijf.value, props.vihb.value])


    let noItemsLabel = i18n.t('ERROR_NO_COMPANIES_FOUND');
    if (searchValue !== undefined && searchValue.length < 3) {
        noItemsLabel = i18n.t('TYPE_AT_LEAST_TO_SEARCH');
    }

    // ==========================
    //Company exists check
    // ==========================
    useEffect(() => {
        if (!props.bedrijf.value) {
            setCompanyExists(false)
            props.setError(false)
            return;
        }
        const delayDebounceFn = setTimeout(() => {
            axios.get('/company/exists/?name=' + props.bedrijf.value, {
                responseType: 'json',
            }).then(response => {
                props.setError(response?.data?.exists)

                setCompanyExists(response?.data?.exists)
            }).catch(error => {
                dispatch(fetchDataFail(error.message))
            })
        }, 500)

        return () => clearTimeout(delayDebounceFn)
    }, [props.bedrijf.value])


    const GetCompanySelectBox = (items) => {
        return <React.Fragment>
            <Typography align='left' variant="h6">{i18n.t('ACCOUNT_CREATE')}</Typography>

            <Grid container alignItems="flex-end" justifyContent="flex-end">
                <Grid size={12}>
                    <Typography align='left' variant="body2">
                        {i18n.t('TITLE_SEARCH_IN_VIHB_LIST')}
                    </Typography>
                </Grid>
                <Grid size={12}>
                    <Autocomplete
                        id="tabFind-inputBedrijf"
                        value={props.bedrijf.value || null}
                        onChange={
                            (e, value) => {
                                props.inputChangeVihb(e, value, 'inputBedrijf')
                            }}
                        getOptionLabel={(option) => option.name + option.vihb_full || option || ""}
                        style={{marginTop: '20px'}}
                        isOptionEqualToValue={(option, value) => {
                            return option.name === value;
                        }}
                        loading={loading}
                        loadingText={i18n.t('CURRENTLY_SEARCHING')}
                        onInputChange={
                            (event) => {
                                if (event === null) {
                                    handleTextChange("");
                                    return;
                                }
                                handleTextChange(event.target.value);
                            }
                        }
                        options={items}
                        noOptionsText={noItemsLabel}
                        renderOption={(props, option) => (
                            <li {...props}>
                                <div>
                                    <Typography display='block' gutterBottom={false}>{option.name}</Typography>
                                    <Typography color="textSecondary" variant="subtitle2"
                                                gutterBottom={true}>{option.vihb_full}</Typography>
                                </div>
                            </li>
                        )}
                        renderInput={params => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label={i18n.t('COMPANY_NAME')}
                                required={props.bedrijf.required}
                                fullWidth
                                slotProps={{
                                    input: {
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {loading ? <CircularProgress color="inherit" size={20}/> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }
                                }}
                            />
                        )}/>
                </Grid>
                <Grid size={12}>
                    <Typography align='left' variant="body2">
                        {/*Heeft uw bedrijf geen VIHB nummer klik dan <strong style={{cursor:'pointer'}}><Button style={{minWidth:'0px',  textTransform: 'lowercase'}} onClick={(e)=>props.skipVihbSearch(e)} color="primary" >hier om een bedrijfsnaam in te vullen</Button></strong>*/}
                        {i18n.t('NO_VIHB')} <strong style={{cursor: 'pointer'}}><Button
                        style={{minWidth: '0px', textTransform: 'lowercase'}} onClick={() => setSkipVihb(true)}
                        color="primary"> {i18n.t('HERE')}</Button></strong>
                    </Typography>
                </Grid>
                <Grid size={12}>
                    {
                        skipVihb ? (
                            <TextField
                                id="tabFind-inputBedrijf"
                                label={i18n.t('COMPANY_NAME')}
                                type="text"
                                fullWidth
                                variant="outlined"
                                margin="normal"
                                required
                                error={Boolean(props.bedrijf.error.length)}
                                value={props.bedrijf.value}
                                onChange={props.inputChangeBedrijf}/>
                        ) : ('')
                    }
                </Grid>
                <Grid size={12}>
                    {
                        companyExists ? (
                            <Alert severity="error">
                                <AlertTitle sx={{textAlign: 'left'}}>{i18n.t('ERROR_COMPANY_ALREADY_EXISTS')}</AlertTitle>
                                {i18n.t('ERROR_CONTACT_MANAGER_IN_ORGANISATION')}
                            </Alert>
                        ) : ('')
                    }
                </Grid>
            </Grid>
        </React.Fragment>
    }

    let companyItems = [];
    if (companies !== undefined && companies.items !== undefined) {
        companyItems = companies.items;
    }

    const selectbox = GetCompanySelectBox(companyItems);

    return (
        <React.Fragment>
            {
                selectbox
            }
            {/*Small loading bar on bottom off form to check if still loading*/}
            {loading ? <LoadingBar/> : null}
        </React.Fragment>
    );
}

RegistrationTabFind.propTypes = {
    vihb: PropTypes.object,
    bedrijf: PropTypes.object,
    handleCompanySelected: PropTypes.func,
    setError: PropTypes.func,
    inputChangeVihb: PropTypes.func.isRequired,
    inputChangeBedrijf: PropTypes.func.isRequired,
}