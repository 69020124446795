import React from "react";
import {Avatar, Button, IconButton, InputAdornment, TextField, Typography} from "@mui/material";
import {Cancel, Visibility, VisibilityOff} from "@mui/icons-material";
import i18n from "../../../i18n/i18n";
import PropTypes from "prop-types";
import Grid from '@mui/material/Grid2';

export default function LoginPassword(props) {

    return (
        <React.Fragment>
            <Grid container spacing={2}>
                <Grid size={2}>
                    <Avatar>{props.email.substring(0, 2).toUpperCase()}</Avatar>
                </Grid>
                <Grid size={8}>
                    <Typography style={{ marginTop: '5px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}} variant="subtitle1">{props.email}</Typography>
                </Grid>
                <Grid size={2}>
                    <IconButton aria-label="Cancel login" onClick={props.toggleLoginAccount} size="large">
                        <Cancel/>
                    </IconButton>
                </Grid>
            </Grid>
            <Grid container spacing={2} >
                <Grid size={12}>
                    <br/>
                    <TextField
                        id="password"
                        label={i18n.t('PASSWORD')}
                        type={props.showPassword ? 'text' : 'password'}
                        value={props.password}
                        onChange={props.handlePasswordChange}
                        autoFocus
                        slotProps={{
                            input: {
                                autoComplete: 'new-password',
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={props.handleClickShowPassword}
                                            onMouseDown={props.handleMouseDownPassword}
                                            size="large">
                                            {props.showPassword ? <Visibility/> : <VisibilityOff/>}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }
                        }}
                        fullWidth
                        required
                    />
                </Grid>
            </Grid>
            <Grid container justifyContent="center" style={{marginTop: '10px'}}>
                <Button
                    onClick={props.handleSubmit}
                    variant="contained"
                    color="primary"
                    disabled={props.loading}
                    fullWidth
                    style={{textTransform: "none"}}>{props.loading ? i18n.t('LOGGING_IN') : "Login"}</Button>
            </Grid>
        </React.Fragment>
    );
}

LoginPassword.propTypes = {
    email: PropTypes.string.isRequired,
    toggleLoginAccount: PropTypes.func.isRequired,
    showPassword: PropTypes.bool.isRequired,
    password: PropTypes.string.isRequired,
    handlePasswordChange: PropTypes.func.isRequired,
    handleClickShowPassword: PropTypes.func.isRequired,
    handleMouseDown: PropTypes.func,
    handleMouseDownPassword: PropTypes.func,
    handleSubmit: PropTypes.func.isRequired,
    loading: PropTypes.bool,
}