import React from 'react'
import {getIn} from 'formik'
import {TextField} from '@mui/material'
import {styled} from "@mui/material/styles";
import PropTypes from "prop-types";

const CssTextField = styled(TextField)({
    '& .MuiInput-underline:before': {
        borderBottom: '1px dashed rgb(109, 51, 26) ',
        borderRight: '1px dashed rgb(109, 51, 26) '
    },
    '&  .Mui-disabled.MuiInput-underline:before': {
        borderBottom: '1px dashed #CECECE ',
        borderRight: '1px dashed  #CECECE '
    },
    '& .MuiInput-underline:hover::before': {
        borderBottom: '1px solid rgb(109, 51, 26)',
        borderRight: '1px solid rgb(109, 51, 26)',
        borderWidth:1
    },
    '&  .Mui-disabled.MuiInput-underline:hover::before': {
        borderBottom: '1px dashed #CECECE ',
        borderRight: '1px dashed  #CECECE '
    },
    '& .MuiInput-underline:after': {
        borderBottom: '1px solid rgb(109, 51, 26)',
        borderRight: '1px solid rgb(109, 51, 26)'
    },

    //Error input border
    '& .MuiInput-underline.Mui-error:before': {
        borderRight: '1px dashed #f44336',
    },
    '& .MuiInput-underline.Mui-error:after': {
        borderBottom: '1px dashed #f44336',
    },
});

export const FormTextField = props => {
    const {error, helperText, field, form, sizeClass, readOnly, ...rest} = props
    const isTouched = getIn(form.touched, field.name)
    const errorMessage = getIn(form.errors, field.name)

    const typeDefault = 'text';
    return (
        <CssTextField
            {...rest}
            {...field}
            // autoComplete="off"
            variant="standard"
            type={props.type || typeDefault}
            margin="none"
            slotProps={{
                input:{
                    readOnly: readOnly || false
                }
            }}
            value={props?.field?.value || ''}
            className={`${sizeClass ? sizeClass : null}`}
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
        />
    )
}

FormTextField.propTypes ={
    form : PropTypes.object,
    field: PropTypes.object,
    error: PropTypes.string,
    sizeClass: PropTypes.string,
    readOnly: PropTypes.bool,
    helperText: PropTypes.string,
    type : PropTypes.string,
}
