import React from 'react';
import {TextField} from "@mui/material";
import PropTypes from "prop-types";

export const LicensePlateInput = ({value}) => {

    return (
        <TextField
            size={'small'}
            margin={'none'}
            sx={{
                backgroundColor: '#F1BF00', borderLeft: '15px solid #3061a2', "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "rgba(0, 0, 0, 0.87)",
                }, borderTopLeftRadius: 0, borderBottomLeftRadius: 0, verticalAlign: 'middle', marginTop: '-4px'
            }}
            slotProps={{
                input: {
                    readOnly: true, style: {
                        fontSize: '14px', textTransform: 'uppercase', color: 'black', padding: 0,
                    },
                }, htmlInput: {
                    style: {
                        padding: "3px 10px",

                    },
                }
            }}
            value={value || ''}
        />
    )
}

LicensePlateInput.propTypes = {
    value: PropTypes.string,
}