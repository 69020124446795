import * as actionsTypes from './actionTypes'
import boa from '../../api/axios-auth'
import peer from '../../api/axios-peer'
import gps from '../../api/axios-gps'
import formklub from '../../api/axios-formklub'
import {jwtDecode} from "jwt-decode";
import resources from '../../resources.json'
import {resetDataTable} from "./dataTableAction";
import {isUuidValid} from "../../shared/utility";

/**
 *
 * @returns {{type: string}}
 */
export const authStart = () => {
    return {
        type: actionsTypes.AUTH_START
    }
}

/**
 *
 * @param key
 * @returns {{scope, type: string}}
 */
const authRegisterToResourceServer = (key) => {
    return {
        type: actionsTypes.AUTH_REGISTER_TO_RESOURCE_SERVER,
        scope: key
    }
}

/**
 *
 * @param token
 * @returns {{authRedirectPath: string, type: string, token}}
 */
export const authSuccess = (token) => {
    return {
        type: actionsTypes.AUTH_SUCCESS,
        token: token,
        authRedirectPath: '/Dashboard',
    }
}

/**
 *
 * @param data
 * @returns {{jwt, type: string}}
 */
export const authSetIdentity = (data) => {
    return {
        type: actionsTypes.AUTH_IDENTITY,
        jwt: data,
    }
}

/**
 *
 * @param regions
 * @returns {{data: {regionsSelected}, type: string}}
 */
export const setSelectedRegions = (regions) => {
    localStorage.setItem('regionsSelected', JSON.stringify(Object.keys(regions)));
    return {
        type: actionsTypes.SET_SELECTED_REGIONS,
        data: {regionsSelected: regions},
    }
}

/**
 *
 * @param projects
 * @returns {{data: {projectsSelected}, type: string}}
 */
export const setLocalSelectedProjects = (projects) => {
    localStorage.setItem('projectsSelected', JSON.stringify(Object.keys(projects)));
    return {
        type: actionsTypes.SET_SELECTED_PROJECTS,
        data: {projectsSelected: projects},
    }
}

/**
 *
 * @param company
 * @returns {{data: {regionsSelected: *[], companySelected}, type: string}|{data: {}, type: string}}
 */
export const setActiveCompany = (company) => {
    if (!company) {
        return {
            type: actionsTypes.SET_ACTIVE_COMPANY,
            data: {},
        }
    }
    const storedCompany = localStorage.getItem('companySelected');
    let regions = [];
    if (storedCompany !== company.UUID) {
        localStorage.setItem('companySelected', company.UUID);
        regions = company.regions;
        localStorage.setItem('regionsSelected', JSON.stringify(Object.keys(regions)));
    } else {
        const selectedKeys = JSON.parse(localStorage.getItem('regionsSelected'));
        if (selectedKeys) {
            selectedKeys.map(item => {
                regions[item] = company.regions[item]
            });
        } else {
            regions = company.regions;
        }
    }

    return {
        type: actionsTypes.SET_ACTIVE_COMPANY,
        data: {companySelected: company, regionsSelected: regions},
    }
}

/**
 *
 * @param user
 * @param activeCompany
 * @returns {boolean}
 */
export const isManagerForCompany = (user, activeCompany) => {
    if (user?.authAssignments === null) {
        return false;
    } else {
        const authAssignments = user.authAssignments.find(authAssignment => authAssignment.company_id === activeCompany.id && authAssignment.item_name === "manager");

        if (authAssignments) {
            return true;
        }
        return false;
    }
}

/**
 *
 * @param user
 * @param activeCompany
 * @returns {boolean}
 */
export const isCompanyManagerForCompany = (user, activeCompany) => {

    if (user?.authAssignments == null) {
        return false;
    } else {
        const authAssignments = user.authAssignments.find(authAssignment => authAssignment.company_id === activeCompany.id && authAssignment.item_name === "manager" && authAssignment.region_id === null);

        if (authAssignments) {
            return true;
        }
        return false;
    }
}

/**
 *
 * @param path
 * @returns {{path, type: string}}
 */
export const authResetLogin = (path) => {
    localStorage.removeItem('token')
    localStorage.removeItem('loggedin')

    return {
        type: actionsTypes.AUTH_RESET_LOGIN,
        path: path,
    }
}

/**
 *
 * @param error
 * @returns {{type: string, error}}
 */
const authFail = (error) => {
    return {
        type: actionsTypes.AUTH_FAIL,
        error: error
    }
}

/**
 *
 * @returns {(function(*): void)|*}
 */
export const logout = () => {
    return dispatch => {
        const url = '/authenticate/logout';
        boa.get(url)
            .then(() => {
                dispatch(authLogout());
                dispatch(resetDataTable());
            })
            .catch(err => {
                dispatch(authLogout());
                if (err.statusCode === 401) {
                    dispatch(resetDataTable());
                } else {
                    if (err.response === undefined) {
                        dispatch(authFail("onbekende fout"))
                    }
                    dispatch(authFail(err.response?.data?.message ?? "onbekende fout"))
                    dispatch(resetDataTable());
                }
            })
    }
}

/**
 *
 * @returns {{type: string}}
 */
const authLogout = () => {
    localStorage.removeItem('regionsSelected');
    localStorage.removeItem('loggedin');
    localStorage.removeItem('companySelected');
    localStorage.removeItem('projectsSelected');
    localStorage.removeItem('token');
    return {
        type: actionsTypes.AUTH_LOGOUT
    }
}

/**
 *
 * @param expirationTime
 * @returns {(function(*): void)|*}
 */
const setAuthTimeoutHandle = (expirationTime) => {
    return dispatch => {
        let timeout = expirationTime - 120000;
        if (timeout < 5000) {
            timeout = 5000;
        }
        setTimeout(() => {
            dispatch(authCheckSession())
        }, timeout)
    }
}

/**
 *
 * @returns {(function(*): void)|*}
 */
export const authCheckState = () => {
    return dispatch => {
        const loggedin = localStorage.getItem('loggedin');
        if (!loggedin) {
            return;
        }
        const url = '/authenticate/verify';
        boa.get(url)
            .then(response => {
                if (response.data.access_token.length < 1) {
                    dispatch(logout())
                    dispatch(authFail("er ging iets fout"))
                    return;
                }
                dispatch(authValidateToken(response.data.access_token))
            })
            .catch(err => {
                dispatch(logout())
                if (err.response === undefined) {
                    dispatch(authFail("onbekende fout"))
                    return;
                }
                dispatch(authFail("Kan sessie niet herstellen"))
            })

        dispatch(initScopes())
    }
}

/**
 *
 * @returns {(function(*): void)|*}
 */
export const authCheckSession = () => {
    return dispatch => {
        const url = '/authenticate/verify';
        boa.get(url)
            .then(response => {
                if (response.data.access_token.length < 1) {
                    dispatch(logout())
                    dispatch(authFail("er ging iets fout"))
                }
                dispatch(authValidateToken(response.data.access_token, true))
            })
            .catch(err => {
                dispatch(logout())
                if (err.response === undefined) {
                    dispatch(authFail("onbekende fout"))
                    return;
                }
                dispatch(authFail("Sessie verlopen"))
            })
    }
}

/**
 *
 * @param token
 * @param sessionActive
 * @returns {(function(*): void)|*}
 */
const authValidateToken = (token, sessionActive = false) => {
    return dispatch => {
        const decoded = jwtDecode(token);
        if (decoded.jti.length < 1) {
            dispatch(logout())
            dispatch(authFail("Gegevens konden niet gevalideerd worden"))
            return;
        }
        if (decoded.client_id !== process.env.REACT_APP_AUTH_CLIENT_ID) {
            dispatch(logout())
            dispatch(authFail("Error 1001 issuer not verified"))
            return;
        }
        const expirationDate = new Date(decoded.exp * 1000)
        if (expirationDate < new Date()) {
            dispatch(logout())
            dispatch(authFail("Huidige sessie is verlopen"))
            return;
        }
        if (!sessionActive) {
            localStorage.setItem('loggedin', true)
            dispatch(authSetIdentity(decoded))
            dispatch(getAuthorisation(token))
        }
        dispatch(authSetAuthorisation(token))
    }
}

/**
 *
 * @returns {{scopes: {}, type: string}}
 */
export const initScopes = () => {
    const config = resources;
    const scopes = {}
    config.resources.forEach(element => {
        let resource = null;
        const scope = element.scope
        switch (scope) {
            default:
            case 'PEER':
                resource = peer
                break;
            case 'GPS':
                resource = gps
                break;
            case 'FORMKLUB':
                resource = formklub
                break;
        }
        if (!resource) {
            return;
        }

        scopes[scope] = {
            ...element,
            initialized: false,
            registered: false,
            isVerified: false,
            resource: resource
        }
    });

    return {
        type: actionsTypes.AUTH_SET_SCOPES,
        scopes: scopes
    }
}

/**
 *
 * @param token
 * @returns {(function(*): void)|*}
 */
export const authSetAuthorisation = (token) => {
    return dispatch => {
        localStorage.setItem('token', token)

        var decoded = jwtDecode(token);
        dispatch(setAuthTimeoutHandle((decoded.exp * 1000) - new Date().getTime()))
        dispatch(authSuccess(token))
    }
}

/**
 *
 * @param username
 * @param password
 * @returns {(function(*): void)|*}
 */
export const auth = (username, password) => {
    return dispatch => {
        dispatch(initScopes());
        dispatch(authStart());

        const scopes = []
        resources.resources.forEach(element => {
            scopes.push(element.scope)
        });

        const authData = {
            username: username,
            password: password,
            grant_type: "password",
            client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
            client_secret: process.env.REACT_APP_AUTH_CLIENT_SECRET,
            redirect_uri: process.env.REACT_APP_AUTH_REDIRECT_URI,
            scope: scopes.join(' '),
        }
        const url = '/authenticate';

        boa.post(url, authData)
            .then(response => {
                if (response.data.access_token.length < 1) {
                    dispatch(logout())
                    dispatch(authFail("er ging iets fout"))
                    return;
                }
                dispatch(authValidateToken(response.data.access_token))
            })
            .catch(err => {
                if (err.response === undefined) {
                    dispatch(logout())
                    dispatch(authFail("onbekende fout"))
                    return;
                }

                dispatch(authFail(err.response.data.message ?? "onbekende fout"))
            })
    }
}

/**
 *
 * @param token
 * @returns {(function(*, *): void)|*}
 */
export const getAuthorisation = (token) => {
    return (dispatch, getState) => {
        const scopes = getState().auth.scopes
        Object.keys(scopes).forEach(function (key) {
            const element = scopes[key]
            dispatch(authRegisterToResourceServer(key));

            const resource = element.resource;
            if (element.introspect) {
                dispatch(introSpect(resource, token, element, key));
            } else {
                dispatch(authSetRegistered(element.scope, {}, resource, false, true, null, false))
            }
        })
    }
}

/**
 *
 * @param scope
 * @param object
 * @returns {{data, scope, type: string}}
 */
export const authSetScopeData = (scope, object) => {
    return {
        type: actionsTypes.AUTH_SET_SCOPE_DATA,
        scope: scope,
        data: object
    }
}

/**
 *
 * @param resource
 * @param token
 * @param element
 * @param key
 * @returns {(function(*): void)|*}
 */
export const introSpect = (resource, token, element, key) => {
    return (dispatch) => {
        const authData = {
            token: token
        }
        const url = '/authorize/introspect';
        resource.post(url, authData)
            .then(response => {
                const {data} = response;
                const userCompanies = data?.companies || {};
                let companySelected = null;
                let regionSelected = {}
                let extraFields = {};

                if (key === "PEER") {

                    const localCompanyUuid = localStorage.getItem('companySelected');
                    const localRegionsString = localStorage.getItem('regionsSelected');

                    const isValidUuid = (uuid) => isUuidValid(uuid);
                    const companyExists = (uuid) => userCompanies[uuid]?.UUID;

                    // Check if the selected company from localStorage is valid
                    if ((localCompanyUuid) && isValidUuid(localCompanyUuid) && companyExists(localCompanyUuid)) {
                        companySelected = userCompanies[localCompanyUuid];
                    } else {
                        localStorage.removeItem('companySelected');
                        localStorage.removeItem('regionsSelected');
                    }

                    // Validate regions in localStorage
                    if (companySelected && localRegionsString) {
                        try {
                            let localRegionsArray = JSON.parse(localRegionsString);
                            const companyRegions = Object.keys(companySelected.regions);

                            localRegionsArray = localRegionsArray.filter(region =>
                                companyRegions.includes(region) && isValidUuid(region)
                            );

                            if (!localRegionsArray.length) {
                                localRegionsArray = companyRegions;
                            }
                            localStorage.setItem('regionsSelected', JSON.stringify(localRegionsArray));

                            //Set
                            localRegionsArray.forEach(regionUuid => {
                                if (companySelected.regions[regionUuid]) {
                                    regionSelected[regionUuid] = companySelected.regions[regionUuid];  // Copy full region object
                                }
                            });
                        } catch (e) {
                            console.error("Error parsing regions:", e);
                            localStorage.setItem('regionsSelected', JSON.stringify(Object.keys(companySelected.regions)));
                        }
                    } else if (companySelected) {
                        localStorage.setItem('regionsSelected', JSON.stringify(Object.keys(companySelected.regions)));
                        regionSelected = companySelected.regions;
                    }

                    // Auto-select company if the user has only one company
                    if (!companySelected && Object.keys(userCompanies).length === 1) {
                        const singleCompanyKey = Object.keys(userCompanies)[0];
                        companySelected = userCompanies[singleCompanyKey];
                        regionSelected = userCompanies[singleCompanyKey].regions;
                        localStorage.setItem('companySelected', companySelected.UUID);
                        localStorage.setItem('regionsSelected', JSON.stringify(Object.keys(userCompanies[singleCompanyKey].regions)));
                    }

                    extraFields = {
                        companies: {...userCompanies},
                        companySelected: companySelected,
                        regionsSelected: regionSelected || companySelected?.regions || []
                    };

                    delete data.companies;
                }

                dispatch(authSetRegistered(element.scope, data, resource, data.isActive, true, extraFields, data.isActive));
            })
            .catch(err => {
                if (err.response === undefined) {
                    dispatch(authSetRegisteredFail(key, "onbekende fout", resource))
                    return;
                }
                if (err.response.status >= 400) {
                    dispatch(authSetRegistered(element?.scope, err?.response?.data, resource, false, true, null, false));
                    return;
                }
                dispatch(authSetRegisteredFail(key, err.response.data.message ?? "onbekende fout", resource))
            })
    };
}

/**
 *
 * @param key
 * @param message
 * @param resource
 * @returns {{resource, scope, registered: boolean, type: string, error}}
 */
export const authSetRegisteredFail = (key, message, resource) => {
    return {
        type: actionsTypes.SET_AUTH_RESOURCE_REGISTERED_FAILED,
        scope: key,
        registered: false,
        error: message,
        resource: resource
    }
}

/**
 *
 * @param scope
 * @param data
 * @param resource
 * @param isActive
 * @param initialized
 * @param extraFields
 * @param loginAllowed
 * @returns {{data, resource, scope, registered: boolean, initialized, type: string, isActive, loginAllowed, extraFields}}
 */
export const authSetRegistered = (scope, data, resource, isActive, initialized, extraFields, loginAllowed) => {
    return {
        type: actionsTypes.SET_AUTH_RESOURCE_REGISTERED,
        scope: scope,
        registered: true,
        resource: resource,
        data: data,
        extraFields: extraFields,
        isActive: isActive,
        initialized: initialized,
        loginAllowed: loginAllowed,
    }
}

/**
 *
 * @returns {(function(*): void)|*}
 */
export const refreshToken = () => {
    return dispatch => {
        const scopes = []
        resources.resources.forEach(element => {
            scopes.push(element.scope)
        });
        const authData = {
            grant_type: "refresh_token",
            client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
            client_secret: process.env.REACT_APP_AUTH_CLIENT_SECRET,
            scope: scopes.join(" "),
        }
        const url = '/refresh-token';

        boa.post(url, authData)
            .then(response => {
                if (response.data.access_token.length < 1) {
                    dispatch(logout())
                    dispatch(authFail("er ging iets fout"))
                    return;
                }

                dispatch(authValidateToken(response.data.access_token))
            })
            .catch(err => {
                if (err.response === undefined) {
                    dispatch(logout())
                    dispatch(authFail("onbekende fout"))
                    return;
                }
                dispatch(authFail(err.response.data.message ?? "onbekende fout"))
            })
    }
}

/**
 *
 * @returns {(function(*): void)|*}
 */
export const getAuthItemsMe = () => {
    //test
    return (dispatch) => {
        peer.get('/auth-assignment/me/')
            .then(response => {
                dispatch(setgetAuthItemsMe(response.data))
            }).catch(err => {
            dispatch(setgetAuthItemsMe({}))
            console.error(err)
        })
    }
}

/**
 *
 * @param data
 * @returns {{data, type: string}}
 */
export const setgetAuthItemsMe = (data) => {
    return {
        type: actionsTypes.SET_AUTH_ITEM_CHILD,
        data: data
    }
}

/**
 *
 * @param path
 * @returns {{path, type: string}}
 */
export const setAuthRedirect = (path) => {
    return {
        type: actionsTypes.SET_AUTH_REDIRECT_PATH,
        path: path
    }
}