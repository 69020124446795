import {PopupProps} from "../Interfaces/MapInterfaces";
import {Popup} from "react-map-gl";
import {Typography} from "@mui/material";

export default function PopupComponent({popupInfo, popupInfoContent, handlePopup}: PopupProps) {
    if (!popupInfo) {
        return;
    }
    return (
        <Popup
            anchor="top"
            longitude={Number(popupInfo?.longitude)}
            latitude={Number(popupInfo?.latitude)}
            onClose={() => handlePopup(null)}
        >
            {/*//@todo popupInfoContent here*/}
            <Typography variant={'body2'}><strong>Longitude</strong></Typography>
            <Typography variant={'body2'}>{popupInfo?.longitude}</Typography>
            <Typography variant={'body2'}><strong>Latitude</strong></Typography>
            <Typography variant={'body2'}>{popupInfo?.latitude})</Typography>
        </Popup>
    )
}