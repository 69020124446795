import {GridEditModes, useGridApiContext, useGridRootProps} from "@mui/x-data-grid-premium";
import {Checkbox} from "@mui/material";
import * as React from "react";
import PropTypes from "prop-types";

//Field
const CheckboxField = React.memo((props) => {
    const {value} = props;

    let checkState = false;
    if (value) {
        checkState = true;
    }
    return <Checkbox disabled checked={checkState} value={checkState} inputProps={{'aria-label': 'disabled checked checkbox'}}/>
});

Checkbox.propTypes = {
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.bool, PropTypes.string]),
}

//Edit Field
function EditCheckbox(props) {
    const {id, value = 0, field} = props;
    const rootProps = useGridRootProps();
    const apiRef = useGridApiContext();

    const handleChange = async () => {
        let newValue;
        if (value === 0) {
            newValue = 1;
        } else {
            newValue = 0
        }
        const isValid = await apiRef.current.setEditCellValue({id, field, value: newValue});

        if (isValid && rootProps.editMode === GridEditModes.Cell) {
            apiRef.current.stopCellEditMode({id, field, cellToFocusAfter: 'below'});
        }
    }

    return (
        <Checkbox checked={Boolean(value)} value={value}
                  inputProps={{'aria-label': 'checked checkbox'}} onChange={(e) => handleChange(e)}/>
    );
}

EditCheckbox.propTypes = {
    field: PropTypes.string.isRequired,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.bool, PropTypes.string]),
};

// ===========================
// Render Checkbox
// ===========================
export function renderCheckbox(params) {
    if (typeof params.id !== 'number') {
        return ''
    }
    return <CheckboxField value={params.value}/>;
}

// ===========================
// Render Edit Checkbox
// ===========================
export function renderEditCheckbox(params) {
    return <EditCheckbox {...params} />;
}