import * as actionTypes from './actionTypes';
import resource from "../../api/axios-gps";
import {setFormAlert} from "./dataTableAction";
import {GpsData} from "../interfaces/gpsInterface";

type Dispatch = (action: any) => void;

/**
 *
 * @returns {{index, type: string}}
 */
export const fetchDataStart = () => {
    return {
        type: actionTypes.FETCH_GPS_START,
    }
}

/**
 *
 * @returns {{type: string}}
 */
export const fetchDataFail = () => {
    return {
        type: actionTypes.FETCH_GPS_FAIL,
    }
}

/**
 *
 * @param url
 * @returns {{type: string, url}}
 */
export const fetchGpsRequest = (url : string) => {
    return ({
        type: actionTypes.FETCH_GPS_REQUEST_SAGA,
        url,
    });
};

/**
 *
 * @param url
 * @returns {(function(*): void)|*}
 */
export const fetchGPSData = (url : string) => {
    return (dispatch : Dispatch) => {
        dispatch(fetchDataStart());
        resource.get(url, {
            responseType: 'json',
        }).then(response => {
            dispatch(fetchDataSuccess(response.data));
        }).catch(error => {
            dispatch(fetchDataFail())
            dispatch(setFormAlert(error, 'error', 'GPS coördinaten kunnen niet worden opgehaald'))
        })
    };
}

/**
 *
 * @param data
 * @returns {{data, type: string}}
 */
export const fetchDataSuccess = (data : GpsData) => {
    return {
        type: actionTypes.FETCH_GPS_SUCCESS,
        data: data,
    }
}

/**
 *
 * @returns {{type: string, selected: null}}
 */
export const closeGPS = () => {
    return {
        type: actionTypes.CLOSE_GPS,
        selected: null
    };
}

/**
 *
 * @returns {{type: string}}
 */
export const resetGPS = () => {
    return {
        type: actionTypes.RESET_GPS,
    }
}