import React from "react";
import {
    Button,
    CardActions,
    CardHeader,
    Typography
} from "@mui/material";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Avatar from "@mui/material/Avatar";
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Grid from '@mui/material/Grid2';

export default function InstructionPageContact() {
    return (
        <Grid container spacing={3} sx={{marginTop: 2}}>
            <Grid size={{xs: 12, md: 3}}>
                <Card variant="elevation" raised={true} sx={{bgcolor: '#EE6E2C'}}>
                    <CardHeader
                        avatar={<Avatar sx={{bgcolor: '#FFFFFF'}}><HelpOutlineIcon color={'primary'}/></Avatar>}
                    />
                    <CardContent>
                        <Typography variant="h5" color="text.secondary" sx={{color: '#FFFFFF'}}> Heeft u
                            vragen?</Typography>
                        <Typography variant="body2" color="text.secondary" sx={{color: '#FFFFFF'}}> Als u vragen
                            heeft over FormKlub, neem dan gerust contact met ons op via een van de volgende
                            manieren.</Typography>
                    </CardContent>
                    <CardActions>
                        <Button fullWidth variant={'outlined'}> </Button>
                    </CardActions>
                </Card>
            </Grid>
            <Grid size={{xs: 12, md: 3}}>
                <Card variant="elevation" raised={true}>
                    <CardHeader
                        avatar={<Avatar sx={{bgcolor: '#EE6E2C'}}><EmailIcon/></Avatar>}
                    />
                    <CardContent>
                        <Typography variant="h5" color="text.secondary">Stuur ons een e-mail</Typography>
                        <Typography variant="body2" color="text.secondary">Onze supportafdeling is bereikbaar
                            via:</Typography>
                    </CardContent>
                    <CardActions>
                        <Button href="mailto:support@scoretrace.com" fullWidth variant={'outlined'} sx={{textTransform: 'lowercase'}}>support@scoretrace.com</Button>
                    </CardActions>
                </Card>
            </Grid>
            <Grid size={{xs: 12, md: 3}}>
                <Card variant="elevation" raised={true}>
                    <CardHeader
                        avatar={<Avatar sx={{bgcolor: '#EE6E2C'}}> <CallIcon/></Avatar>}
                    />
                    <CardContent>
                        <Typography variant="h5" color="text.secondary"> Bel ons</Typography>
                        <Typography variant="body2" color="text.secondary"> Bereikbaar van maandag tot en met vrijdag,
                            van
                            8:30 tot 17:30.</Typography>
                    </CardContent>
                    <CardActions>
                        <Button href="tel:0172436373" fullWidth variant={'outlined'}>0172-436373</Button>
                    </CardActions>
                </Card>
            </Grid>
            <Grid size={{xs: 12, md: 3}}>
                <Card variant="elevation" raised={true}>
                    <CardHeader
                        avatar={<Avatar sx={{bgcolor: '#EE6E2C'}}><WhatsAppIcon/></Avatar>}
                    />
                    <CardContent>
                        <Typography variant="h5" color="text.secondary">Stuur ons een WhatsApp-bericht</Typography>
                        <Typography variant="body2" color="text.secondary">Bereikbaar van maandag tot en met vrijdag,
                            van
                            8:30 tot 17:30.</Typography>
                    </CardContent>
                    <CardActions>
                        <Button href='https://wa.me/0172436373' target='_blank' fullWidth variant={'outlined'}>0172-436373</Button>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>
    )
}
