import Loading from "../Animation/Loading";
import { styled } from '@mui/material/styles';
import {Backdrop} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

const PREFIX = 'LoaderBackdrop';

const classes = {
    backdrop: `${PREFIX}-backdrop`
};

const StyledBackdrop = styled(Backdrop)(() => ({
    [`&.${classes.backdrop}`]: {
        zIndex: 1200,
        color: '#fff',
    }
}));

/**
 * 
 * @param props
 * @returns {Element}
 * @constructor
 */
const LoaderBackdrop = (props) => {

    return (
        <StyledBackdrop className={classes.backdrop} open={props.open}>
            <Loading size={props.size || 100} margin={props.margin} color={props.color}/>
        </StyledBackdrop>
    );
}

export default LoaderBackdrop


LoaderBackdrop.propTypes = {
    open: PropTypes.bool.isRequired,
    size: PropTypes.string,
    margin: PropTypes.string,
    color: PropTypes.string,
};