import React from 'react';
import { Navigate } from 'react-router-dom'
import PropTypes from "prop-types";

/**
 *
 * @param Component
 * @param isAuthorized
 * @returns {React.JSX.Element|*}
 * @constructor
 */
export const AuthRoute = ({ component: Component, isAuthorized: isAuthorized}) => {
    if (!isAuthorized) {
        return <Navigate to={{pathname: '/'}}/>
    }

    return Component;
}

AuthRoute.propTypes = {
    component : PropTypes.element,
    isAuthorized : PropTypes.bool.isRequired,
}