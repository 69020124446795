import Grid from "@mui/material/Grid2";
import React, {useState} from "react";
import customClasses from "./TemplateForm.module.css";
import SearchIcon from "../../Controls/SearchIcon";
import PropTypes from "prop-types";
import {FormTextField} from "./Components/FormTextField";
import {FastField} from "formik";
import SimpleDialog from "../../Dialog/SimpleDialog";
import SearchLocationAdressDialog from "../Templates/DialogDetails/SearchLocationAdressDialog";
import {Typography} from "@mui/material";
import {FormAutocompleteField} from "./Components/FormAutocompleteField";

export default function TemplateFormDestination(props) {

    const group = "locationdestination";
    const [openDialog, setOpenDialog] = useState(false);

    const {
        formField: {
            locationdestination_description,
            locationdestinationaddress_street_name,
            locationdestinationaddress_house_no,
            locationdestinationaddress_house_no_addition,
            locationdestinationaddress_postal_code,
            locationdestinationaddress_city_name,
        }
    } = props;

    // =====================
    // Handle Open and Close dialogs
    // =====================
    const openModal = () => {
        setOpenDialog(true);
    }
    const closeModal = () => {
        setOpenDialog(false);
    };

    // ==========================================
    // Handle Selected Eural
    // ==========================================
    const handleLocationSelected = (selectedLocation) => {
        props.setFieldValue(group + '.description', (selectedLocation.name || '').substring(0, 50));
        props.setFieldValue(group + '.address_street_name', selectedLocation.street_name || '');
        props.setFieldValue(group + '.address_house_no', selectedLocation.building_number || '');
        props.setFieldValue(group + '.address_house_no_addition', selectedLocation.building_number_addition || '');
        props.setFieldValue(group + '.address_postal_code', selectedLocation.postal_code || selectedLocation.postal_zone || '');
        props.setFieldValue(group + '.address_city_name', selectedLocation.city_name || '');
        selectedLocation?.id ? props.setFieldValue(group + '.id', selectedLocation?.id) : null;
        selectedLocation?.id && selectedLocation?.entity ? props.setFieldValue(group + '.entity', selectedLocation?.entity) : null;
        closeModal();
    };

    // Search Location dialog
    const dialog = () => {
        if (!props.data) {
            return;
        }
        return (
            <SimpleDialog
                title="Zoek locatie"
                fields={props}
                size="lg"
                fullWidth={true}
                open={openDialog}
                handleClose={closeModal}>
                <SearchLocationAdressDialog
                    name={'name'}
                    dataSources={['vihb', 'companies', 'weighBridge', 'relations']}
                    handleLocationSelected={handleLocationSelected}
                />
            </SimpleDialog>
        )
    }

    return <React.Fragment>
        {dialog()}
        {/*===========================================================================*/}
        {/*Block Divider*/}
        {/*===========================================================================*/}
        <Grid className={customClasses.borderDivider} container size={12}/>
        <div className={customClasses.blockIdentifier}>
            <strong>4<span className={customClasses.blockPart}>B</span></strong></div>
        {/*===========================================================================*/}
        {/*Block Content
        {/*===========================================================================*/}
        <Grid container size={12}>
            <Grid size={{xs: 11, md: 3}}>
                {locationdestination_description.label}
            </Grid>
            <Grid size={1}>
                <SearchIcon
                    color="rgb(113, 43, 1)"
                    handleClick={openModal}
                    disabled={props.disabled}
                />
            </Grid>
            <Grid size={{xs: 12, md: 8}}>
                <FastField
                    autoComplete="new-password"
                    sizeClass={customClasses.inputFull}
                    name={group + "." + locationdestination_description.name}
                    disabled={props.disabled}
                    filterField={'name'}
                    dataSources={['vihb', 'companies', 'weighBridge', 'relations']}
                    component={FormAutocompleteField}
                    handleSelected={handleLocationSelected}
                    setFieldValue={props.setFieldValue}
                />
            </Grid>
        </Grid>
        <Grid container size={12}>
            <Grid size={{xs: 11, md: 3}}>
                {locationdestinationaddress_street_name.label}
            </Grid>
            <Grid size={1}/>
            <Grid size={{xs: 12, md: 8}}>
                <FastField
                    autoComplete="new-password"
                    name={group + "." + locationdestinationaddress_street_name.name}
                    disabled={props.disabled}
                    sizeClass={customClasses.inputMedium}
                    component={FormTextField}
                />
                <FastField
                    autoComplete="new-password"
                    name={group + "." + locationdestinationaddress_house_no.name}
                    type='number'
                    disabled={props.disabled}
                    sizeClass={`${customClasses.inputSmall} ${customClasses.container}`}
                    component={FormTextField}
                />
                <FastField
                    autoComplete="new-password"
                    name={group + "." + locationdestinationaddress_house_no_addition.name}
                    disabled={props.disabled}
                    sizeClass={`${customClasses.inputSmall} ${customClasses.container}`}
                    component={FormTextField}
                />
            </Grid>
        </Grid>
        <Grid container size={12}>
            <Grid container size={{xs: 11, md: 3}} className={customClasses.noWordBreak}>
                <Grid size={6} className={customClasses.container}>
                    {locationdestinationaddress_postal_code.label}
                </Grid>
                <Grid size={6} className={customClasses.containerRequired}>
                    {locationdestinationaddress_city_name.label}
                </Grid>
            </Grid>
            <Grid size={1}/>
            <Grid size={{xs: 12, md: 8}}>
                <FastField
                    autoComplete="new-password"
                    name={group + "." + locationdestinationaddress_postal_code.name}
                    disabled={props.disabled}
                    sizeClass={`${customClasses.inputSmall} ${customClasses.container}`}
                    component={FormTextField}
                />
                <FastField
                    autoComplete="new-password"
                    name={group + "." + locationdestinationaddress_city_name.name}
                    disabled={props.disabled}
                    sizeClass={customClasses.inputLarge}
                    component={FormTextField}
                />
            </Grid>
        </Grid>
        <Grid className={`${customClasses.container} ${customClasses.subContainer}`} container size={12}>
            <Grid size={{xs: 12, md: 4}}>
                datum ontvangst transport
                <Typography sx={{
                    fontSize: 10,
                    fontStyle: 'italic',
                    textTransform: 'uppercase'
                }}>{props.values.form_type === 'B2' ? ("Datum aanvang toepassing") : null}</Typography>
            </Grid>
            <Grid size={{xs: 12, md: 8}}>
                -
            </Grid>
        </Grid>
    </React.Fragment>
}

TemplateFormDestination.propTypes = {
    disabled: PropTypes.bool.isRequired,
    formField: PropTypes.object.isRequired,
    setFieldValue: PropTypes.func,
    values: PropTypes.object,
    data: PropTypes.object,
    project: PropTypes.object,
};