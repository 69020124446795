import Grid from "@mui/material/Grid2";
import React, {useEffect, useState} from "react";
import customClasses from "./TemplateForm.module.css";
import PropTypes from "prop-types";
import RadioButton from "./Components/RadioButton";
import {FormTextField} from "./Components/FormTextField";
import {FastField} from "formik";
import SimpleDialog from "../../Dialog/SimpleDialog";
import SearchIcon from "../../Controls/SearchIcon";
import {Typography} from "@mui/material";
import {FormAutocompleteField} from "./Components/FormAutocompleteField";
import SearchLocationAdressDialog from "../Templates/DialogDetails/SearchLocationAdressDialog";

export default function TemplateFormCarrier(props) {

    const [openDialog, setOpenDialog] = useState(false);
    const [firstLoad, setFirstLoad] = useState(true);

    const group = "carrierparty";

    const {
        formField: {
            template_transported_by_type,
            carrierparty_name,
            carrierpartyaddress_street_name,
            carrierpartyaddress_house_no,
            carrierpartyaddress_house_no_addition,
            carrierpartyaddress_postal_code,
            carrierpartyaddress_city_name,
            carrierparty_identifier_value,
            template_is_route_collection,
            template_is_collection_scheme,
            template_is_repetitive_loads,
        }
    } = props;

    // ===============================================
    // Radio Buttons
    // ===============================================
    const radioOptionsTransportType = [
        {labelNumber: '1', value: 1, label: 'afzender'},
        {labelNumber: '2', value: 2, label: "ontdoener"},
        {labelNumber: '3', value: 3, label: "ontvanger"},
        {labelNumber: '4', value: 4, label: "inzamelaar"},
        {labelNumber: '5', value: 5, label: "vervoerder"},
        {labelNumber: '6', value: 6, label: "uitbesteed vervoerder"},
    ];

    const yesNoOptions = [
        {labelNumber: '1', label: 'Ja', value: 1},
        {labelNumber: '2', label: 'Nee', value: 0},
    ];

    // ============================================
    // Load default address from project if allowed
    // ============================================
    const handleCompanySelected = (selectedCompany) => {
        props.setFieldValue(group + '.name', (selectedCompany.name || '').substring(0, 50));
        props.setFieldValue(group + '.address_street_name', selectedCompany.street_name || '');
        props.setFieldValue(group + '.address_house_no', selectedCompany.building_number || '');
        props.setFieldValue(group + '.address_house_no_addition', selectedCompany.building_number_addition || '');
        props.setFieldValue(group + '.address_postal_code', selectedCompany.postal_code || selectedCompany.postal_zone || '');
        props.setFieldValue(group + '.address_city_name', selectedCompany.city_name || '');
        props.setFieldValue(group + '.identifier_value', selectedCompany.vihb_full || selectedCompany.identifier_value || '');
        selectedCompany?.id ? props.setFieldValue(group + '.id', selectedCompany?.id) : null;
        selectedCompany?.id && selectedCompany?.entity ? props.setFieldValue(group + '.entity', selectedCompany?.entity) : null;
        closeModal();
    };

    // =====================
    // Handle Modal
    // =====================
    const openModal = () => {
        setOpenDialog(true);
    }
    const closeModal = () => {
        setOpenDialog(false);
    };

    // =====================
    // Dialog
    // =====================
    const dialog = (
        <SimpleDialog
            title="Zoek locatie"
            fields={props}
            size="lg"
            fullWidth={true}
            open={openDialog}
            handleClose={closeModal}>
            <SearchLocationAdressDialog
                name={'name'}
                dataSources={['vihb', 'companies', 'weighBridge', 'relations']}
                handleLocationSelected={handleCompanySelected}
            />
        </SimpleDialog>
    )

    // ========================================
    // First load Template handle radiobuttons
    // ========================================
    useEffect(() => {
        if (props.templateFormInitialised || props.action !== 'add' ) {
            return;
        }
        if (!props.project || props.project.length === 0) {
            return;
        }

        setFirstLoad(false)
    }, [props.project])

    useEffect(() => {
        if (firstLoad) {
            return;
        }
        if (props.project.template_default_transport_by_type && !props.templateFormInitialised) {
            props.setFieldValue('transported_by_type', props.project.template_default_transport_by_type || '');
        }
        handleRadioAutoFill()
    }, [firstLoad])


    // ===============================
    // Handle radioButtons autofill
    // ===============================
    useEffect(() => {
        if (!props.values || !props.project || Object.keys(props.project).length === 0 || props.action !== 'add') {
            return;
        }
        handleRadioAutoFill()
    }, [props.values.transported_by_type])

    const handleRadioAutoFill = () => {
        //Set address
        let name;
        switch (props.values.transported_by_type) {
            case 1:
                name = 'sender'
                break;
            case 2:
                name = 'disposer'
                break;
            case 3:
                name = 'receiver'
                break;
            case 5:
                name = 'carrier'
                break;
            default:
                name = null
                break;
        }
        if (!name) {
            handleCompanySelected([])
            return;
        }
        console.log(props.project[`default_${name}_address`])
        handleCompanySelected(props.project[`default_${name}_address`])

        if (!props.templateFormInitialised) {
            props.handleSetTemplateFormInitialised(true)
        }
    }

    return <React.Fragment>
        {dialog}
        {/*===========================================================================*/}
        {/*Block Divider*/}
        {/*===========================================================================*/}
        <Grid className={customClasses.borderDividerFull} container size={12}/>
        <div className={customClasses.blockIdentifier}><strong>5</strong></div>
        {/*===========================================================================*/}
        {/*Block Content
        {/*===========================================================================*/}
        <Grid container alignItems="flex-start" size={{xs:12, md:9}} className={customClasses.removeRightPadding}>
            <Grid container size={12} style={{paddingBottom: '10px'}}>
                <Grid size={{xs:12, md:2}}>
                    Getransporteerd door
                </Grid>
                <Grid size={{xs:12, md:10}} className={customClasses.radioContainer}>
                    <RadioButton disabled={props.disabled} options={radioOptionsTransportType} label=""
                                 labelNumbers={true} name={template_transported_by_type.name}
                                 setFieldValue={props.setFieldValue}/>
                </Grid>
            </Grid>
            <Grid className={customClasses.containerMaybeRequired} size={{xs:12, md:9}}>
                <Grid container size={12}>
                    <Grid size={{xs:11, md:3}}>
                        {props.values.form_type === 'B2' ? carrierparty_name.labelB2 : carrierparty_name.label}
                        <Typography sx={{fontSize:10, fontStyle: 'italic', textTransform: 'uppercase'}}>{props.values.form_type === 'B2' ? (carrierparty_name.labelExtra) : null}</Typography>
                    </Grid>
                    <Grid size={1}>
                        <SearchIcon
                            color="rgb(113, 43, 1)"
                            handleClick={openModal}
                            disabled={props.disabled}
                        />
                    </Grid>
                    <Grid size={{xs:12, md:8}}>
                        <FastField
                            autoComplete="new-password"
                            sizeClass={customClasses.inputFull}
                            name={group + "." + carrierparty_name.name}
                            disabled={props.disabled}
                            filterField={'name'}
                            dataSources={['vihb', 'companies', 'weighBridge', 'relations']}
                            component={FormAutocompleteField}
                            handleSelected={handleCompanySelected}
                            setFieldValue={props.setFieldValue}
                        />
                    </Grid>
                </Grid>
                <Grid container size={12}>
                    <Grid size={{xs:11, md:4}}>
                        {carrierpartyaddress_street_name.label}
                    </Grid>
                    <Grid size={{xs:12, md:8}}>
                        <FastField
                            name={group + "." + carrierpartyaddress_street_name.name}
                            disabled={props.disabled}
                            sizeClass={customClasses.inputMedium}
                            autoComplete="new-password"
                            component={FormTextField}
                        />
                        <FastField
                            name={group + "." + carrierpartyaddress_house_no.name}
                            disabled={props.disabled}
                            type='number'
                            sizeClass={customClasses.inputSmall}
                            autoComplete="new-password"
                            component={FormTextField}
                        />
                        <FastField
                            autoComplete="new-password"
                            name={group + "." + carrierpartyaddress_house_no_addition.name}
                            disabled={props.disabled}
                            sizeClass={customClasses.inputSmall}
                            component={FormTextField}
                        />
                    </Grid>
                </Grid>
                <Grid container size={12}>
                    <Grid size={{xs:11, md:4}}>
                        {carrierpartyaddress_postal_code.label}
                    </Grid>
                    <Grid size={{xs:12, md:8}}>
                        <FastField
                            autoComplete="new-password"
                            name={group + "." + carrierpartyaddress_postal_code.name}
                            disabled={props.disabled}
                            sizeClass={customClasses.inputSmall}
                            component={FormTextField}
                        />
                        <FastField
                            autoComplete="new-password"
                            name={group + "." + carrierpartyaddress_city_name.name}
                            disabled={props.disabled}
                            sizeClass={customClasses.inputLarge}
                            component={FormTextField}
                        />
                    </Grid>
                    <br/>
                </Grid>

            </Grid>

            <Grid className={customClasses.containerMaybeRequired} container size={{xs:12, md:3}}>
                <Grid size={{xs:12, md:4}}>
                    {carrierparty_identifier_value.label}
                </Grid>
                <Grid size={{xs:12, md:8}}>
                    <FastField
                        autoComplete="new-password"
                        sizeClass={customClasses.inputFull}
                        name={group + "." + carrierparty_identifier_value.name}
                        disabled={props.disabled}
                        component={FormTextField}
                    />
                </Grid>
                <Grid size={{xs:12, md:4}}>
                    kenteken
                </Grid>
                <Grid size={{xs:12, md:8}}>
                    -
                </Grid>
                <Grid size={{xs:12, md:4}}>
                    oplegger
                </Grid>
                <Grid size={{xs:12, md:8}}>
                    -
                </Grid>
                <br/>
            </Grid>
        </Grid>
        <Grid container size={{xs:12, md:3}}>
            <Grid size={7}>
                route-inzameling
            </Grid>
            <Grid size={5}>
                <RadioButton options={yesNoOptions} label="" labelNumbers={false}
                             name={template_is_route_collection.name} disabled={true}
                             setFieldValue={props.setFieldValue}/>
            </Grid>
            <Grid sx={{height:10, marginTop:'-15px'}}><Typography sx={{fontSize:10, fontStyle: 'italic', textTransform: 'uppercase'}}>routelijst bijsluiten (zie toelichting)</Typography>
            </Grid>
            <Grid size={7} style={{hyphens: 'auto'}}>
                inzamelaarsregeling
            </Grid>
            <Grid size={5}>
                <RadioButton disabled={props.disabled} options={yesNoOptions} labelNumbers={false} label=""
                             name={template_is_collection_scheme.name} setFieldValue={props.setFieldValue}/>
            </Grid>
            <Grid size={7}>
                repeterende vrachten
                <Typography sx={{fontSize:10, fontStyle: 'italic', textTransform: 'uppercase'}}>zie toelichting</Typography>
            </Grid>
            <Grid size={5}>
                <RadioButton disabled={props.disabled}
                             options={yesNoOptions} labelNumbers={false} label=""
                             name={template_is_repetitive_loads.name} setFieldValue={props.setFieldValue}
                             key={props.values.is_collection_scheme}/>
            </Grid>
        </Grid>
    </React.Fragment>
}

TemplateFormCarrier.propTypes = {
    disabled: PropTypes.bool.isRequired,
    formField: PropTypes.object.isRequired,
    handleSetTemplateFormInitialised: PropTypes.func,
    autoFillReplaceData: PropTypes.bool,
    templateFormInitialised: PropTypes.bool,
    values: PropTypes.object,
    setFieldValue: PropTypes.func,
    project: PropTypes.object,
    action: PropTypes.string,
    organisationData: PropTypes.array,
};