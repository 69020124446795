import Grid from "@mui/material/Grid2";
import React, {useEffect, useState} from "react";
import customClasses from "./TemplateForm.module.css";
import SearchIcon from "../../Controls/SearchIcon";
import PropTypes from "prop-types";
import {FormTextField} from "./Components/FormTextField";
import {FastField} from "formik";
import SimpleDialog from "../../Dialog/SimpleDialog";
import SearchLocationAdressDialog from "../Templates/DialogDetails/SearchLocationAdressDialog";
import {FormAutocompleteField} from "./Components/FormAutocompleteField";
import LocationPin from "./Components/LocationPin";
import {CircularProgress, IconButton, Typography} from "@mui/material";
import MapLocationDialog from "./Components/MapLocationDialog";
import getGeoLocation from "../../../store/actions/PtvMapActions";
import dayjs from "dayjs";
import i18n from "../../../i18n/i18n";
import {getCompanyTemplateLocationCheck} from "../../../store/selectors/companySelector";
import {useSelector} from "react-redux";

export default function TemplateFormOrigin(props) {

    const group = "locationorigin";
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogContent, setDialogContent] = useState(<></>);
    const [loading, setLoading] = useState(false);
    const [dialogTitle, setDialogTitle] = useState('');
    const [dialogSize, setDialogSize] = useState('md');
    const geoCheckedOnData = props.data?.locationorigin?.geo_checked_on;
    const [locationVerified, setLocationVerified] = useState(null);
    const [initialized, setInitialized] = useState(false);
    const pinError = !locationVerified && props.errors?.locationorigin?.geo_checked_on && props.touched?.locationorigin?.geo_checked_on;
    const locationCheckEnabled = useSelector(state => getCompanyTemplateLocationCheck(state));

    useEffect(() => {
        setLocationVerified(geoCheckedOnData)
        setInitialized(true)
    }, []);

    const {
        formField: {
            locationorigin_description,
            locationoriginaddress_street_name,
            locationoriginaddress_house_no,
            locationoriginaddress_house_no_addition,
            locationoriginaddress_postal_code,
            locationoriginaddress_city_name,
            locationorigin_geo_checked_on,
        }
    } = props;

    /**
     * Open dialog
     * @param dialogType (search, location)
     * @param dialogData
     */
    const openModal = (dialogType, dialogData = []) => {
        setOpenDialog(true);

        if (dialogType === 'search') {
            setDialogTitle(i18n.t('TITLE_SEARCH_LOCATION'))
            setDialogSize('lg')
            setDialogContent(
                <SearchLocationAdressDialog
                    name={'name'}
                    dataSources={['vihb', 'companies', 'weighBridge', 'relations']}
                    handleLocationSelected={handleLocationSelected}
                />
            )
        }

        if (dialogType === 'location') {
            setDialogTitle(i18n.t('TITLE_LOCATION_ON_MAP'))
            setDialogSize('md')
            setDialogContent(
                <MapLocationDialog locations={dialogData} handleLocationVerified={handleLocationVerified} handleClose={closeModal}/>)
        }
    }

    const closeModal = () => {
        setOpenDialog(false);
    };

    const handleLocationVerified = (value, reset) => {
        let timestamp = dayjs().format('YYYY-MM-DD HH:mm:ss')
        let latitude = value?.latitude;
        let longitude = value?.longitude;

        if (reset) {
            timestamp = null;
            latitude = null;
            longitude = null;
        }

        setLocationVerified(timestamp)
        props.setFieldValue(group + '.geo_checked_on', timestamp);
        props.setFieldValue(group + '.address_latitude', latitude);
        props.setFieldValue(group + '.address_longitude', longitude);
    }

    /**
     * Handle Selected
     * @param selectedLocation
     */
    const handleLocationSelected = (selectedLocation) => {
        props.setFieldValue(group + '.description', (selectedLocation.name || '').substring(0, 50));
        props.setFieldValue(group + '.address_street_name', selectedLocation.street_name || '');
        props.setFieldValue(group + '.address_house_no', selectedLocation.building_number || '');
        props.setFieldValue(group + '.address_house_no_addition', selectedLocation.building_number_addition || '');
        props.setFieldValue(group + '.address_postal_code', selectedLocation.postal_code || selectedLocation.postal_zone || '');
        props.setFieldValue(group + '.address_city_name', selectedLocation.city_name || '');
        props.setFieldValue(group + '.address_country', selectedLocation.country_name || '');
        selectedLocation?.id ? props.setFieldValue(group + '.id', selectedLocation?.id) : null;
        selectedLocation?.id && selectedLocation?.entity ? props.setFieldValue(group + '.entity', selectedLocation?.entity) : null;
        closeModal();
    };

    const dialog = () => {
        if (!props.data) {
            return;
        }
        return (
            <SimpleDialog
                title={dialogTitle}
                fields={props}
                size={dialogSize}
                fullWidth={true}
                open={openDialog}
                handleClose={closeModal}>
                {dialogContent}
            </SimpleDialog>
        )
    }

    const data = {
        "description": props.values.locationorigin.description,
        "address_street_name": props.values.locationorigin.address_street_name,
        "address_house_no": props.values.locationorigin.address_house_no,
        "address_house_no_addition": props.values.locationorigin.address_house_no_addition,
        "address_postal_code": props.values.locationorigin.address_postal_code,
        "address_city_name": props.values.locationorigin.address_city_name,
        "address_country": props.values.locationorigin.address_country,
    }

    const retrieveGeoCoordinates = async (openPopup = false) => {
        try {
            setLoading(true)
            const results = await getGeoLocation({data});

            if (openPopup) {
                openModal('location', results)
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false)
        }
    };

    useEffect(() => {
        if (!initialized) {
            return;
        }
        handleLocationVerified(null, true);
    }, [
        data.description,
        data.address_street_name,
        data.address_house_no,
        data.address_house_no_addition,
        data.address_postal_code,
        data.address_city_name,
        data.address_country,
    ]);


    const handleGeoLocationPin = async () => {
        await retrieveGeoCoordinates(true);
    }

    return <React.Fragment>
        {dialog()}
        {/*===========================================================================*/}
        {/*Block Divider*/}
        {/*===========================================================================*/}
        <Grid className={customClasses.borderDivider} container size={12}/>
        <div className={customClasses.blockIdentifier}>
            <strong>3<span className={customClasses.blockPart}>B</span></strong></div>
        {/*===========================================================================*/}
        {/*Block Content
        {/*===========================================================================*/}
        <Grid container size={12} sx={{justifyContent: 'end'}}>
            {pinError && (
                <Typography color="error">
                    {props.errors.locationorigin?.geo_checked_on}
                </Typography>
            )}
        </Grid>
        <Grid container size={12}>
            <Grid size={{xs: 11, md: 3}}>
                {locationorigin_description.label}
            </Grid>
            <Grid size={1}>
                <SearchIcon
                    color="rgb(113, 43, 1)"
                    handleClick={() => openModal('search')}
                    disabled={props.disabled}
                />
            </Grid>
            <Grid size={{xs: locationCheckEnabled ? 11 : 12, md: locationCheckEnabled ? 7 : 8}}>
                <FastField
                    autoComplete="new-password"
                    sizeClass={customClasses.inputFull}
                    name={group + "." + locationorigin_description.name}
                    disabled={props.disabled}
                    filterField={'name'}
                    dataSources={['vihb', 'companies', 'weighBridge', 'relations']}
                    component={FormAutocompleteField}
                    handleSelected={handleLocationSelected}
                    setFieldValue={props.setFieldValue}
                />
            </Grid>
            {
                locationCheckEnabled ? (
                    <Grid size={{xs: 1, md: 1}}>

                        <IconButton
                            aria-label="delete"
                            onClick={() => {
                                handleGeoLocationPin();
                            }}
                            disabled={props.disabled}
                        >
                            <LocationPin
                                state={!locationVerified ? 'notVerified' : 'verified'}
                                error={pinError}
                                disabled={props.disabled}
                            />
                            {loading ? <CircularProgress size={20} color={"info"} sx={{marginLeft: 0}}/> : null}
                        </IconButton>
                    </Grid>
                ) : null
            }
        </Grid>
        <Grid container size={12}>
            <Grid size={{xs: 11, md: 3}}>
                {locationoriginaddress_street_name.label}
            </Grid>
            <Grid size={1}/>
            <Grid size={{xs: 12, md: 8}}>
                <FastField
                    autoComplete="new-password"
                    name={group + "." + locationoriginaddress_street_name.name}
                    disabled={props.disabled}
                    sizeClass={customClasses.inputMedium}
                    component={FormTextField}
                />
                <FastField
                    autoComplete="new-password"
                    name={group + "." + locationoriginaddress_house_no.name}
                    type='number'
                    disabled={props.disabled}
                    sizeClass={`${customClasses.inputSmall} ${customClasses.container}`}
                    component={FormTextField}
                />
                <FastField
                    autoComplete="new-password"
                    name={group + "." + locationoriginaddress_house_no_addition.name}
                    disabled={props.disabled}
                    sizeClass={`${customClasses.inputSmall} ${customClasses.container}`}
                    component={FormTextField}
                />
            </Grid>
        </Grid>
        <Grid container size={12}>
            <Grid container size={{xs: 11, md: 3}} className={customClasses.noWordBreak}>
                <Grid size={{xs: 6, md: 6}} className={customClasses.container}>
                    {locationoriginaddress_postal_code.label}
                </Grid>
                <Grid size={{xs: 6, md: 6}} className={customClasses.containerRequired}>
                    {locationoriginaddress_city_name.label}
                </Grid>
            </Grid>
            <Grid size={1}/>
            <Grid size={{xs: 12, md: 8}}>
                <FastField
                    autoComplete="new-password"
                    name={group + "." + locationoriginaddress_postal_code.name}
                    disabled={props.disabled}
                    sizeClass={`${customClasses.inputSmall} ${customClasses.container}`}
                    component={FormTextField}
                />
                <FastField
                    autoComplete="new-password"
                    name={group + "." + locationoriginaddress_city_name.name}
                    disabled={props.disabled}
                    sizeClass={customClasses.inputLarge}
                    component={FormTextField}
                />
            </Grid>
        </Grid>
        <Grid className={`${customClasses.container} ${customClasses.subContainer}`} container size={12}>
            <Grid size={{xs: 12, md: 4}}>
                datum aanvang transport
            </Grid>
            <Grid size={{xs: 12, md: 8}}>
                -
            </Grid>
        </Grid>
    </React.Fragment>
}

TemplateFormOrigin.propTypes = {
    disabled: PropTypes.bool.isRequired,
    formField: PropTypes.object.isRequired,
    setFieldValue: PropTypes.func,
    setFieldError: PropTypes.func,
    data: PropTypes.object,
    project: PropTypes.object,
    errors: PropTypes.object,
    touched: PropTypes.object
};