import axios from "../../api/axios-peer";

interface SearchItem {
    [key: string]: [];
}

interface SearchProps {
    filterValue: string;
    dataSources: Array<'vihb' | 'companies' | 'weighBridge' | 'relations'>;
    filterField: string;
    uniqueField: string;

}

/**
 *
 * @param filterValue
 * @param searchIn
 * @param filterField
 * @param uniqueField
 */
const search = async (
    {
        filterValue,
        filterField,
        dataSources,
        uniqueField,
    }: SearchProps): Promise<SearchItem[]> => {

    if (!filterValue || filterValue.length < 3) {
        return [];
    }
    const params = new URLSearchParams();

    // Search Value
    if (filterValue) {
        params.append('filterValue', filterValue);
    }

    // Search Field
    if (filterField) {
        params.append('filterField', filterField);
    }

    // Data sources
    if (Array.isArray(dataSources) && dataSources.length > 0) {
        dataSources.forEach(item => {
            params.append('dataSources[]', item);
        });
    }

    // Field that should be unique
    if (uniqueField) {
        params.append('uniqueField', uniqueField);
    }

    let searchData: SearchItem[] = [];
    try {
        await axios.get(`${process.env.REACT_APP_PEER_URL}searchservice/search?${params.toString()}`)
            .then(response => {
                searchData = response.data
            })
            .catch(error => console.error('Request failed', error));
        return searchData;
    } catch (error) {
        console.error('Error during search:', error);
        return [];
    }
};

export default search;