import React from "react";
import {
    SettingsSuggest,
    AccountTree, AltRoute,
    Assignment, Build, Business, CenterFocusWeak,
    Contacts,
    GridOn, InsertChart,
    Map,
    Person,
    Settings,
} from "@mui/icons-material";
import {states} from "../../../constants/States";

export default function SideMenuItems(scopes, companyRoles, user, companyOverloadModule) {

    const isAdmin = companyRoles.includes(states.STATE_ROLE_ADMIN);
    const isEmployee = companyRoles.includes(states.STATE_ROLE_EMPLOYEE);
    const isManager = companyRoles.includes(states.STATE_ROLE_MANAGER) || companyRoles.includes(states.STATE_ROLE_REGION_MANAGER);
    const isManagersOrEmployee = companyRoles.includes(states.STATE_ROLE_MANAGER) || companyRoles.includes(states.STATE_ROLE_REGION_MANAGER) || companyRoles.includes(states.STATE_ROLE_EMPLOYEE);

    const peerAllowed = scopes.PEER.isActive;
    const formklubAllowed = scopes.FORMKLUB.isActive;
    const gpsAllowed = scopes.GPS.isActive;

    const items = [];
    if (user !== null && companyRoles !== null) {
        if (peerAllowed && (isAdmin || isManager || isEmployee)) {
            items.push({
                icon: <Contacts/>,
                url: '/Relaties',
                key: 'Relaties',
                submenu: '',
                index: 'business_relation',
            });
        }
        if (peerAllowed) {
            items.push(
                {
                    icon: <AccountTree/>,
                    url: '/Projecten',
                    key: 'Projecten',
                    submenu: '',
                    index: 'project',
                });
            items.push({
                icon: <GridOn/>,
                url: '/Templates',
                key: 'Templates',
                submenu: '',
                index: 'template',
            });
        }

        if (formklubAllowed) {
            items.push({
                icon: <Assignment/>,
                url: '/Begeleidingsbrieven',
                key: 'Begeleidingsbrieven',
                submenu: '',
                index: 'waybill',
            });
        }
        if (peerAllowed && (isAdmin || isManagersOrEmployee)) {
            if (peerAllowed || gpsAllowed) {
                items.push({
                    icon: <Map/>,
                    url: '/Kaart',
                    key: 'Kaart',
                    submenu: '',
                    index: 'map'
                })
            }
        }
        if (formklubAllowed && (((isManagersOrEmployee) && companyOverloadModule) || isAdmin)) {
            items.push({
                icon: <AltRoute/>,
                url: '/Ritten',
                key: 'Ritten',
                submenu: '',
                index: 'rides'
            });
            items.push({
                icon: <InsertChart/>,
                url: '/Rapportage',
                key: 'Rapportage',
                submenu: '',
                index: 'report'
            });

        }

        // Settings
        if (peerAllowed && (isAdmin || isManager)) {
            const subMenu = [
                {
                    icon: <Person/>,
                    url: '/Beheer/Gebruikers',
                    key: 'Gebruikers',
                    index: 'user',
                }, {
                    icon: <CenterFocusWeak/>,
                    url: '/Beheer/Regios',
                    key: "Regio's",
                    index: 'region',
                },
                {
                    icon: <Build/>,
                    url: '/Beheer/Werkzaamhedentypes',
                    key: 'Werkzaamhedentypes',
                    index: 'order_type',
                }
            ]
            items.push({
                icon: <Settings/>,
                url: '/Beheer',
                key: 'Beheer',
                submenu: subMenu
            })

            if (isAdmin) {
                const subMenuAdmin = [
                    {
                        icon: <Business/>,
                        url: '/Admin/Organisaties',
                        key: 'Organisaties',
                        index: 'company',
                        admin: true
                    }
                ]

                items.push({
                    icon: <SettingsSuggest/>,
                    url: '/Admin/Organisaties',
                    key: 'Admin',
                    index: 'company',
                    submenu: subMenuAdmin,
                    admin: true
                })
            }
        }
    }

    return items
}