import * as React from 'react';
import PropTypes from 'prop-types';
import {
    DatePicker,
    DateTimePicker,
} from '@mui/x-date-pickers-pro';
import {format} from "date-fns";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import {nl} from "date-fns/locale/nl";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import i18n from "../../../i18n/i18n";


// ======================================================================
// Date Filter Operators
// ======================================================================
function buildApplyDateFilterFn(filterItem, compareFn, showTime = false) {
    if (!filterItem.value) {
        return null;
    }

    const filterValueMs = filterItem.value.getTime();

    return ({value}) => {
        if (!value) {
            return false;
        }

        // Make a copy of the date to not reset the hours in the original object
        const dateCopy = new Date(value);
        dateCopy.setHours(
            showTime ? value.getHours() : 0,
            showTime ? value.getMinutes() : 0,
            0,
            0,
        );

        const cellValueMs = dateCopy.getTime();

        return compareFn(cellValueMs, filterValueMs);
    };
}

export function getDateFilterOperators(showTime = false) {
    return [
        {
            value: 'is',
            getApplyFilterFn: (filterItem) => {
                return buildApplyDateFilterFn(
                    filterItem,
                    (value1, value2) => value1 === value2,
                    showTime,
                );
            },
            InputComponent: GridFilterDateInput,
            InputComponentProps: {showTime},
        },
        {
            value: 'not',
            getApplyFilterFn: (filterItem) => {
                return buildApplyDateFilterFn(
                    filterItem,
                    (value1, value2) => value1 !== value2,
                    showTime,
                );
            },
            InputComponent: GridFilterDateInput,
            InputComponentProps: {showTime},
        },
        {
            value: 'after',
            getApplyFilterFn: (filterItem) => {
                return buildApplyDateFilterFn(
                    filterItem,
                    (value1, value2) => value1 > value2,
                    showTime,
                );
            },
            InputComponent: GridFilterDateInput,
            InputComponentProps: {showTime},
        },
        {
            value: 'onOrAfter',
            getApplyFilterFn: (filterItem) => {
                return buildApplyDateFilterFn(
                    filterItem,
                    (value1, value2) => value1 >= value2,
                    showTime,
                );
            },
            InputComponent: GridFilterDateInput,
            InputComponentProps: {showTime},
        },
        {
            value: 'before',
            getApplyFilterFn: (filterItem) => {
                return buildApplyDateFilterFn(
                    filterItem,
                    (value1, value2) => value1 < value2,
                    showTime,
                );
            },
            InputComponent: GridFilterDateInput,
            InputComponentProps: {showTime},
        },
        {
            value: 'onOrBefore',
            getApplyFilterFn: (filterItem) => {
                return buildApplyDateFilterFn(
                    filterItem,
                    (value1, value2) => value1 <= value2,
                    showTime,
                );
            },
            InputComponent: GridFilterDateInput,
            InputComponentProps: {showTime},
        },
        // {
        //     value: 'isEmpty',
        //     getApplyFilterFn: () => {
        //         return ({ value }) => {
        //             return value == null;
        //         };
        //     },
        //     requiresFilterValue: false,
        // },
        // {
        //     value: 'isNotEmpty',
        //     getApplyFilterFn: () => {
        //         return ({ value }) => {
        //             return value != null;
        //         };
        //     },
        //     requiresFilterValue: false,
        // },
    ];
}

// ======================================================================
// Custom Date / Datetime filter field
// =====================================================================



function GridFilterDateInput(props) {
    const {item, showTime, applyValue} = props;
    const Component = showTime ? DateTimePicker : DatePicker;

    const [cleared, setCleared] = React.useState(false);

    React.useEffect(() => {
        if (cleared) {
            const timeout = setTimeout(() => {
                setCleared(false);
            }, 1500);

            return () => clearTimeout(timeout);
        }
        return () => {};
    }, [cleared]);

    const handleFilterChange = (newValue, clear) => {
        if(clear === true){
            applyValue({...item, value: null});
            setCleared(true)
        }
        if(!newValue || newValue == 'Invalid Date'){
            return;
        }

        const delayDebounceFn = setTimeout(() => {
            const formatValue = format(newValue, "yyyy-MM-dd");
            applyValue({...item, value: formatValue});
        }, 200)

        return () => clearTimeout(delayDebounceFn)
    }

    const handleChangeDebounce = async (date) => {
        await searchAPIDebounced(date);
    };
    const searchAPIDebounced = AwesomeDebouncePromise(handleFilterChange, 500);
    return (
        <LocalizationProvider adapterLocale={nl} dateAdapter={AdapterDateFns}>
            <Component
                value={item.value ?  new Date(Date.parse(item.value.replace(/-/g, '/'))) : null}
                slotProps={{
                    textField:{
                        variant: "outlined",
                        fullWidth: true,
                        size: "small",
                        label: i18n.t('DATE')
                    },
                    field: { clearable: true, onClear: () => handleFilterChange(null, true) },
                }}
                clearButton={true}
                clearIcon={true}
                InputAdornmentProps={{
                    sx: {
                        '& .MuiButtonBase-root': {
                            marginRight: -1,
                        },
                    },
                }}
                onChange={(date) => {
                    try {
                        handleChangeDebounce(date)
                    } catch (e) {
                        console.error(e?.message)
                    }
                }}
            />
        </LocalizationProvider>
    );
}

GridFilterDateInput.propTypes = {
    apiRef: PropTypes.shape({
        current: PropTypes.object,
    }),
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
        field: PropTypes.string.isRequired,
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        operatorValue: PropTypes.string,
        value: PropTypes.any,
    }).isRequired,
    showTime: PropTypes.bool,
};

