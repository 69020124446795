import {createSelector} from "reselect";
import {states} from "../../constants/States";
// import {getActiveRegions} from "./regionSelector";
import {getActiveCompanyId, getCompanyRoles, getPeerAuthByCompany} from "./companySelector";
import {isScoretraceAdmin} from "./userSelector";
import {getScopePeerState} from "./scopeSelector";
import {peerAuthInterface, peerAuthItemChildInterface} from "../interfaces/actionRightsInterface";

//@todo OLD CHECK RIGHTS
// export const getCanEditOrAddData = createSelector(
//     [
//         getActiveRegions,
//         getCompanyRoles,
//         // (state, projectUuid)=>getRolesByProjectUUID(state, projectUuid)
//     ],
//     (activeRegion, companyRoles) => {
//         if (!activeRegion) {
//             return;
//          }
//
//         //Check if
//         if (!activeRegion.length || (activeRegion.length === 1 && activeRegion[0].roles?.length === 1 && activeRegion[0].roles.includes(states.STATE_ROLE_TRANSPORT_SUPERVISOR))) {
//             return false;
//         }
//
//         const allowedRoles = [
//             states.STATE_ROLE_ADMIN,
//             states.STATE_ROLE_MANAGER,
//             states.STATE_ROLE_REGION_MANAGER,
//             states.STATE_ROLE_EMPLOYEE,
//         ];
//
//         return companyRoles.some(role => allowedRoles.includes(role));
//     }
// );

/**
 * Check access rights based on the provided index, action and id.
 *
 * @param {string} index - The index to check access rights for (e.g., "project", "template", "business_relation").
 * @param {string} action - The action to check access rights for (e.g., "create", "update", "delete").
 * @param {number} id_row - An number containing the relevant IDs.
 * @param {boolean} matchOnId - Should selector also match on id passed in id_row.
 *  @returns {boolean} - True if the user has access rights, false otherwise.
 */
export const getHasRights = createSelector(
    [
        (state, index) => (index),
        (state, index, action) => (action),
        (state, index, action, id_row) => (id_row),
        (state, index, action, id_row, matchOnId) => (matchOnId),
        getPeerAuthByCompany,
        getCompanyRoles,
        isScoretraceAdmin,
        getScopePeerState,
    ],
    (index, action, id_row, matchOnId, peerAuth, companyRoles, isAdmin, scopePeerActive) => {

        if (!peerAuth || !index || !action) {
            return false;
        }

        //Convert the following index
        if (index === 'goodsItem') {
            index = 'rides'
        }
        // =====================================
        // 1. Admin always has access
        // =====================================
        if (isAdmin) {
            return true;
        }
        // Reformat index if necessary
        const formattedIndex = index.replace(/_/g, '').toLowerCase();
        const getTextInFrontOfIndex = (child: string) => {
            const match = child.toLowerCase().match(new RegExp(`(.*)${formattedIndex.trim().toLowerCase()}`));
            return match ? match[1].trim() : null;
        };

        // Set project_id, template_id, client_id
        let project_id: number | null
        let template_id: number | null
        let client_id: number | null

        if (id_row !== undefined && id_row !== null && matchOnId) {
            project_id = formattedIndex.includes('project') ? id_row : undefined;
            template_id = formattedIndex.includes('template') ? id_row : undefined;
            client_id = formattedIndex.includes('businessrelation') ? id_row : undefined;
        }
        // Get auth items roles for current index (READ, UPDATE, CREATE)

        const parentTextArray = peerAuth.flatMap((data: peerAuthInterface) => {

            return data.authItemChild.filter(item => {
                // Check if 'child' property contains the substring
                const childIncludesIndex = item.child.toLowerCase().includes(formattedIndex);
                const rowIdMatch = (
                    project_id && project_id === data.project_id ||
                    client_id && client_id === data.client_id ||
                    template_id && template_id === data.template_id
                );
                if ((project_id || client_id || template_id)) {
                    return childIncludesIndex && rowIdMatch;
                } else {
                    return childIncludesIndex;
                }
            }).map(item => getTextInFrontOfIndex(item.child));
        });

        // If there are authItems check those rights, Else check on companyRoles
        if (parentTextArray.length > 0) {
            return parentTextArray.includes(action);
        } else {
            // Check if Scope peer is active.
            if (scopePeerActive) {
                const allowedRoles = [
                    states.STATE_ROLE_MANAGER,
                    states.STATE_ROLE_REGION_MANAGER,
                    states.STATE_ROLE_EMPLOYEE,
                ];

                return companyRoles.some((role: string) => allowedRoles.includes(role));
            } else {
                // Scope Peer is not active user should have no access
                return false
            }
        }
    }
);


/**
 * Formats auth rights per index
 *
 *  @returns {object | boolean} - if has auth items return object with rights per index / else return true or false;
 */
export const getHasRightsByIndex = createSelector(
        [
            getPeerAuthByCompany,
            getCompanyRoles,
            isScoretraceAdmin
        ],
        (peerAuth, companyRoles, isAdmin) => {
            if (!peerAuth || peerAuth.length === 0) {
                return false;
            }
            // =====================================
            // 1. Admin always has access
            // =====================================
            if (isAdmin) {
                return true;
            }

            // =====================================
            // 2. Check auth rights
            // =====================================
            const getTextInFrontOfIndex = (index: string, child: string) => {
                const match = child.match(new RegExp(`(.*)${index.trim()}`));
                return match ? match[1] : null;
            };

            function convertVariableName(variable: string) {
                const snakeCaseVariable = variable.replace(/[A-Z]/g, match => `_${match.toLowerCase()}`).replace(/^-/, '').toLowerCase();

                // Remove leading underscore if present
                return snakeCaseVariable.replace(/^_/, '');
            }

            const objectRights = {} as { [key: string]: any };

            peerAuth.forEach((data: peerAuthInterface) => {

                return data.authItemChild.filter(item => {
                    const match = item.child.match(/(update|create|delete|read)(.*)$/);
                    let index: string;

                    if (match) {
                        index = match[2];
                        let actionArray = [];

                        actionArray = Array.from(new Set(
                            data.authItemChild
                                .map(item => getTextInFrontOfIndex(index, item.child))
                                .filter(Boolean)
                        ));
                        index = convertVariableName(index);

                        if (!objectRights[index]) {
                            return objectRights[index] = actionArray;
                        }

                        actionArray.forEach(action => {
                            // Check if the value is already present in objectRights[index]
                            if (!objectRights[index].includes(action)) {
                                // If the value doesn't exist, push it to objectRights[index]
                                return objectRights[index].push(action);
                            }
                        });
                    }
                });
            });

            if ((objectRights) && Object.keys(objectRights).length) {
                return objectRights;
            } else {
                //Fallback old roles
                const allowedRoles = [
                    states.STATE_ROLE_MANAGER,
                    states.STATE_ROLE_REGION_MANAGER,
                    states.STATE_ROLE_EMPLOYEE,
                ];
                return companyRoles?.some((role: string) => allowedRoles.includes(role));
            }
        }
    )
;


/**
 * Formats auth rights per index and id
 *
 *  @returns {object | boolean} - if has auth items return object with rights per index / else return true or false;
 */
export const getHasRightsByIndexAndId = createSelector(
    [
        getPeerAuthByCompany,
        getCompanyRoles,
    ],
    (peerAuth, companyRoles) => {
        if (!peerAuth) {
            return false;
        }

        // =====================================
        // 1. Admin always has access
        // =====================================
        if (peerAuth.some((item: peerAuthInterface) => item.item_name === states.STATE_ROLE_ADMIN)) {
            return true;
        }

        // =====================================
        // 2. Check auth rights
        // =====================================
        const getTextInFrontOfIndex = (index: string, child: string) => {
            const match = child.match(new RegExp(`(.*)${index.trim()}`));
            return match ? match[1] : null;
        };
        const getIdOfRight = (dataParent: peerAuthInterface, index: string) => {
            const idIndex = index + '_id';
            return (dataParent as { [key: string]: any })[idIndex] || null;
        }

        function convertVariableName(variable: string) {
            const snakeCaseVariable = variable.replace(/[A-Z]/g, match => `_${match.toLowerCase()}`).replace(/^-/, '').toLowerCase();

            // Remove leading underscore if present
            return snakeCaseVariable.replace(/^_/, '');
        }

        const objectRights = {} as { [key: string]: any };

        peerAuth.forEach((data: peerAuthInterface) => {

            return data.authItemChild.filter(item => {

                const match = item.child.match(/(update|create|delete|read)(.*)$/);
                let index: string;
                if (match) {
                    index = match[2];
                    let actionArray = [];
                    actionArray = data.authItemChild.map((item: peerAuthItemChildInterface) => getTextInFrontOfIndex(index, item.child)).filter(Boolean);
                    index = convertVariableName(index);
                    const id = getIdOfRight(data, index);

                    if (id) {
                        if (!objectRights[index]) {
                            objectRights[index] = {};
                        }
                        if (!objectRights[index][id]) {
                            objectRights[index][id] = [];
                        }
                        actionArray.forEach(action => {
                            // Check if the value is already present in objectRights[index][id]
                            if (!objectRights[index][id].includes(action)) {
                                // If the value doesn't exist, push it to objectRights[index][id]
                                objectRights[index][id].push(action);
                            }
                        });
                    } else {
                        if (!objectRights[index]) {
                            objectRights[index] = [];
                        }
                        actionArray.forEach(action => {
                            // Check if the value is already present in objectRights[index]
                            if (!objectRights[index].includes(action)) {
                                // If the value doesn't exist, push it to objectRights[index]
                                objectRights[index].push(action);
                            }
                        });
                    }
                }
            });
        });

        if (objectRights) {
            return objectRights;
        } else {
            //Fallback old roles
            const allowedRoles = [
                states.STATE_ROLE_MANAGER,
                states.STATE_ROLE_REGION_MANAGER,
                states.STATE_ROLE_EMPLOYEE,
            ];
            return companyRoles.some((role: string) => allowedRoles.includes(role));
        }
    }
);


/**
 * check if user has rights
 *
 *  @returns {boolean} - if has auth items return object with rights per index / else return true or false;
 */
export const userHasRights = createSelector(
    [
        getPeerAuthByCompany,
        getActiveCompanyId
    ],
    (peerAuth, companyId) => {
        //Empty array means no rights
        if (!peerAuth?.length) {
            return false;
        }
        // if there are results check state
        return !peerAuth.some((item: peerAuthInterface) => item.state_id === '90' && item.company_id === companyId)
    }
);

export const dataHandle = createSelector(
    [
        state => state.data,
    ],
    (data) => {
        return data.handle || null;
    }
);

export const dataDisabled = createSelector(
    [
        state => state.data,
    ],
    (data) => {
        return data.isDisabled || false;
    }
);

export const dataError = createSelector(
    [
        state => state.data,
    ],
    (data) => {
        return data.error || false;
    }
);

export const dataEdited = createSelector(
    [
        state => state.data,
    ],
    (data) => {
        return data.isEdited || false;
    }
);