import React from "react";
import {
    Button,
    Divider,
    Typography
} from "@mui/material";
import Grid from '@mui/material/Grid2';
import AssignmentIcon from '@mui/icons-material/Assignment';
import i18n from "../../../i18n/i18n";
// import BasicAccordion from "../../Inputs/BasicAccordion";
// import {getFaqQuestionsBySubject} from "../../../constants/FaqQuestions";
import InstructionPageContact from "./InstructionPageContact";

export default function InstructionPage() {
    return (
        <>
            <InstructionPageContact/>
            <Divider sx={{marginTop: 10, marginBottom: 5}}/>
            <Grid container spacing={4}>
                {/*@todo later add faq questions*/}
                <Grid size={{xs: 12, md: 4}}>
                    <Typography variant={'h4'} sx={{marginBottom: 4, marginTop: 4}}>{i18n.t('DOWNLOADS')}</Typography>
                    <Divider/>
                    <Button sx={{marginBottom: 2}} startIcon={
                        <AssignmentIcon/>} variant={'contained'} fullWidth href={process.env.REACT_APP_FORMKLUB_FRONTEND_URL + '/handleidingen/formklub_stappenplan.pdf'}>{i18n.t('TITLE_MANUAL', {name: 'FormKlub'})}</Button>
                    <Button sx={{marginBottom: 2}} startIcon={
                        <AssignmentIcon/>} variant={'contained'} fullWidth href={process.env.REACT_APP_FORMKLUB_FRONTEND_URL + '/handleidingen/formklub_stappenplan_uitgebreid.pdf'}>{i18n.t('TITLE_MANUAL', {name: 'FormKlub uitgebreid'})}</Button>
                    <Button sx={{marginBottom: 2}} startIcon={
                        <AssignmentIcon/>} variant={'contained'} fullWidth href={process.env.REACT_APP_FORMKLUB_FRONTEND_URL + '/handleidingen/formklub_transporteur.pdf'}>{i18n.t('TITLE_MANUAL', {name: 'Transporteur'})}</Button>
                </Grid>
                <Grid size={{xs: 12, md: 4}}>
                    <Typography variant="h4" sx={{marginBottom: 4, marginTop: 4}}>
                        {i18n.t('TITLE_INSTRUCTION_VIDEO')}
                    </Typography>
                    <iframe width={'100%'} height="315" src="https://www.youtube.com/embed/hElc3IWOzOU?si=kYDn4Zzrsj-jI4nu"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                </Grid>
                <Grid size={{xs: 12, md: 4}}>
                    {/*<Typography variant={'h4'} sx={{marginBottom: 4, marginTop: 4}}>{i18n.t('FAQ')}</Typography>*/}
                    {/*<BasicAccordion data={getFaqQuestionsBySubject('instructions')}/>*/}
                </Grid>
            </Grid>
        </>
    )
}
