import React, {useEffect} from "react";
import {styled} from '@mui/material/styles';
import {
    AppBar,
    Toolbar,
    Typography,
    ListItemIcon,
    Drawer,
    MenuList,
    Tooltip,
    Button,
    Container
} from "@mui/material";
import Grid from '@mui/material/Grid2'
import Logo from "../../Logo/Logo";
import {
    AccountCircle,
    ArrowForwardIos,
    ArrowBackIos
} from "@mui/icons-material";
import clsx from 'clsx';
import {Divider} from "@mui/material";
import {useSelector} from "react-redux";
import SideMenu from './SideMenu'
import PropTypes from "prop-types";
import RegionSelector from "./HeaderRegionSelector";
import AccountMenu from "./HeaderAccountMenu";
import WindowDimensions from "../../WindowDimensions/WindowDimensions";
import {getUser} from "../../../store/selectors/userSelector";
import {getScopePeerState} from "../../../store/selectors/scopeSelector";
import {
    getActiveCompanyLogo,
    getActiveCompanyName,
    isDriverForCompany,
    isInactiveForCompany
} from "../../../store/selectors/companySelector";
import Box from "@mui/material/Box";
import i18n from "../../../i18n/i18n";
import HeaderHelp from "./HeaderHelp";
// import LanguageSelector from "../../Inputs/LanguageSelector";

const PREFIX = 'Header';

const classes = {
    toolbar: `${PREFIX}-toolbar`,
    appBar: `${PREFIX}-appBar`,
    drawerIconContainer: `${PREFIX}-drawerIconContainer`,
    drawerIcon: `${PREFIX}-drawerIcon`,
    drawer: `${PREFIX}-drawer`,
    drawerOpen: `${PREFIX}-drawerOpen`,
    drawerClose: `${PREFIX}-drawerClose`,
    drawerContainer: `${PREFIX}-drawerContainer`,
    grow: `${PREFIX}-grow`,
    sectionDesktop: `${PREFIX}-sectionDesktop`,
    typography: `${PREFIX}-typography`,
    menuList: `${PREFIX}-menuList`,
    menuLogoContainer: `${PREFIX}-menuLogoContainer`,
    LogoHeaderBalk: `${PREFIX}-LogoHeaderBalk`,
    accountButton: `${PREFIX}-accountButton`,
    accountIcon: `${PREFIX}-accountIcon`,
    accountIconContainer: `${PREFIX}-accountIconContainer`,
    company: `${PREFIX}-company`,
    fullName: `${PREFIX}-fullName`,
    avatar: `${PREFIX}-avatar`,
    divider: `${PREFIX}-divider`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.toolbar}`]: theme.mixins.toolbar,
    [`& .${classes.appBar}`]: {
        zIndex: theme.zIndex.drawer + 1,
        padding: 0,
        margin: 0,
        maxHeight: 64
    },

    [`& .${classes.drawerIconContainer}`]: {
        justifyContent: 'flex-end',
        flex: 1,
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        alignSelf: 'flex-end',
        marginBottom: 20,
        marginTop: 20
    },

    [`& .${classes.drawerIcon}`]: {
        color: '#D2D8DD',
        marginTop: 20,
        cursor: "pointer",
    },

    [`& .${classes.drawer}`]: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        position: 'relative',
        overflow: 'hidden',
    },

    [`& .${classes.drawerOpen}`]: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        paddingTop: '10px',
        overflowX: 'hidden',
        backgroundColor: "#395069",
        '&::-webkit-scrollbar': {width: 5, borderLeft: '2px solid #395069'},
        '&::-webkit-scrollbar-track': {background: ' #203A56', boxShadow: 'inset 0 0 5px grey'},
        '&::-webkit-scrollbar-thumb': {background: '#192e44 '},
    },

    [`& .${classes.drawerClose}`]: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7),
        [theme.breakpoints.up('xs')]: {
            width: theme.spacing(10),
        },
        paddingTop: '10px',
        backgroundColor: "#395069",
        '&::-webkit-scrollbar': {width: 5, borderLeft: '2px solid #395069'},
        '&::-webkit-scrollbar-track': {background: ' #203A56', boxShadow: 'inset 0 0 5px grey'},
        '&::-webkit-scrollbar-thumb': {background: '#192e44 '},
    },

    [`& .${classes.drawerContainer}`]: {
        overflow: 'auto',
    },

    [`& .${classes.grow}`]: {
        flexGrow: 1,
    },

    [`& .${classes.sectionDesktop}`]: {
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
        [theme.breakpoints.down('lg')]: {
            marginRight: '20px'
        },
        [theme.breakpoints.down('md')]: {
            marginRight: '10px'
        },
    },

    [`& .${classes.typography}`]: {
        padding: theme.spacing(2),
    },

    [`& .${classes.menuList}`]: {
        outline: 'none',
        padding: '0px',
        margin: '15px',
    },

    [`& .${classes.menuLogoContainer}`]: {
        paddingLeft: '15px',
        paddingRight: 0,
        flex: 1
    },

    [`& .${classes.LogoHeaderBalk}`]: {
        // display: 'flex',
        boxSizing: 'border-box',
        background: '#ffffff',
        width: '217px',
        height: '100px',
        borderRadius: '0 0 10px 10px',
        boxShadow: '0px 5px 10px rgb(0 0 0 / 20%)',
        padding: '34px 15px',
        // position: 'absolute',
        // top: '0',
        [theme.breakpoints.down('lg')]: {
            width: '170px',
        },
        [theme.breakpoints.down('md')]: {
            width: '150px',
        },

    },

    [`& .${classes.accountButton}`]: {
        maxHeight: '73px',
        textTransform: "unset",
    },

    [`& .${classes.accountIcon}`]: {
        color: '#FBE2D4',
    },

    [`& .${classes.accountIconContainer}`]: {
        padding: '12px',
        display: 'flex',
        [theme.breakpoints.down('lg')]: {
            padding: '6px',
        },
    },

    [`& .${classes.company}`]: {
        padding: 0,
        [theme.breakpoints.down('lg')]: {
            fontSize: '0.8rem',
        },
    },

    [`& .${classes.fullName}`]: {
        padding: "0",
        fontWeight: 300,
        lineHeight: 1,
        [theme.breakpoints.down('lg')]: {
            fontSize: '0.8rem',
        },
    },

    [`& .${classes.avatar}`]: {
        width: "40px",
        height: "40px",
        padding: "5px",
        borderRadius: "3px",
        backgroundColor: "white"
    },

    [`& .${classes.divider}`]: {
        background: '#FBE2D4',
        margin: '10px',
        width: '2px',
        [theme.breakpoints.down('lg')]: {
            display: 'inline-flex',
        },
    }
}));

const drawerWidth = 250;

function Header(props) {


    const localMenuState = localStorage.getItem('menuOpenState');
    const localMenuCollapseState = localStorage.getItem('menuCollapseState');
    const currentPath = window.location.pathname.split('/').pop() ?? null;
    const scopePeerState = useSelector(state => getScopePeerState(state));
    const activeCompanyName = useSelector(state => getActiveCompanyName(state));
    const activeCompanyLogo = useSelector(state => getActiveCompanyLogo(state));
    const user = useSelector(state => getUser(state));
    const isDriver = useSelector(state => isDriverForCompany(state));
    const isInactive = useSelector(state => isInactiveForCompany(state));

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [right, openRight] = React.useState(false);
    const [openMainMenu, setOpenMainMenu] = React.useState(localMenuState === 'true');
    const [selectedItem, setSelectedItem] = React.useState(currentPath ? currentPath : null);
    const [submenuState, setSubmenuState] = React.useState(JSON.parse(localMenuCollapseState) || {});
    const {height, width} = WindowDimensions();

    useEffect(() => {
        localStorage.setItem('menuOpenState', openMainMenu);
    }, [openMainMenu])

    useEffect(() => {
        localStorage.setItem('menuCollapseState', JSON.stringify(submenuState));
    }, [submenuState])

    //Drawer - Icon Toggle menu
    //=========================
    const handleToggleMenu = () => {
        setOpenMainMenu(!openMainMenu);
    }

    //Drawer - Menu Item Click handler
    //=================================
    const itemClickHandler = (key, submenu) => {
        //Handle Selected menuItem
        setSelectedItem(key);
        if (submenu) {
            setSubmenuState(prevState => ({
                ...prevState,
                [key]: {'open': !submenuState[key]?.open, 'key': key}
            }));
        }
    }

//Top menu - Icon account
//======================
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

//Top menu - toggle notifications
//===============================
    const toggleDrawerRight = () => () => {
        openRight(!right);
    };

// Toggle icon Drawer Menu
//========================
    const ToggleIcon = () => {
        let iconTooltip;
        let icon;
        if (!openMainMenu) {
            iconTooltip = i18n.t("TOOLTIP_OPEN")
            icon = <ArrowForwardIos/>
        } else {
            iconTooltip = i18n.t("TOOLTIP_CLOSE")
            icon = <ArrowBackIos/>
        }
        return (
            <div className={classes.drawerIconContainer}>
                <Tooltip
                    placement="right"
                    title={iconTooltip}>
                    <ListItemIcon className={classes.drawerIcon} aria-label="Toggle Menu" color="inherit"
                                  onClick={handleToggleMenu}>
                        {icon}
                    </ListItemIcon>
                </Tooltip>
            </div>
        )
    }

    const regionWidth = width - 600;

    return (
        <Root>
            {/*<CssBaseline/>*/}
            <AppBar position="fixed" className={classes.appBar}>
                <Container maxWidth={width.toString()} disableGutters sx={{margin: 0}}>
                    <Toolbar disableGutters sx={{height: 64}}>
                        <>
                            <div className={classes.menuLogoContainer}>
                                <Logo class={classes.LogoHeaderBalk} linkTo/>
                            </div>
                            {/*<div className={classes.grow}/>*/}

                            {/*<div className={classes.sectionDesktop}>*/}
                            {
                                !props.headerOnly ? (
                                    <>

                                        <Box
                                            sx={{display: 'flex', maxWidth: regionWidth, justifyContent: 'flex-start'}}>
                                            {
                                                ((props.headerOnly || scopePeerState) && !isDriver && !isInactive) ?
                                                    <>
                                                        {/*<HeaderProjectSelector/> //@todo Future general project filter*/}
                                                        <RegionSelector/>
                                                    </> : ''
                                            }
                                        </Box>
                                        <Box sx={{display: 'flex'}}>
                                            {
                                                width >= 768 ? (
                                                    <>
                                                        <Divider
                                                            className={classes.divider}
                                                            orientation="vertical"
                                                            flexItem
                                                            variant="middle"
                                                            sx={{ opacity: 0.8 }}
                                                        />
                                                        <HeaderHelp/>
                                                        <Divider
                                                            className={classes.divider}
                                                            orientation="vertical"
                                                            flexItem
                                                            variant="middle"
                                                            sx={{ opacity: 0.8}}
                                                        />
                                                    </>
                                                ) : ''
                                            }

                                            {/*//@todo Language dropdown */}
                                            {/*<LanguageSelector/>*/}

                                            <Button
                                                onClick={handleClick}
                                                className={classes.accountButton}
                                                edge="end"
                                                fullWidth
                                                aria-label="account of current user"
                                                aria-haspopup="true"
                                                color="inherit">
                                                <Tooltip title="Account">
                                                    {(activeCompanyName && activeCompanyLogo) ?
                                                        <div className={classes.avatar}>
                                                            <img alt="company_logo"
                                                                 src={activeCompanyLogo}
                                                                 height={32} width={32}/>
                                                        </div> :
                                                        <div className={classes.accountIconContainer}><AccountCircle
                                                            className={classes.accountIcon}/></div>}
                                                </Tooltip>
                                                {width >= 768 ?
                                                    <Grid container>
                                                        <Grid size={12}>
                                                            <Typography className={classes.company} variant={"h6"}>
                                                                {
                                                                    activeCompanyName ? activeCompanyName : ''
                                                                }
                                                            </Typography>
                                                        </Grid>
                                                        <Grid size={12}>
                                                            <Typography className={classes.fullName}
                                                                        variant={"h6"}>{user ? user.name : ''}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                    : null
                                                }
                                            </Button>
                                        </Box>
                                    </>

                                ) : (
                                    ''
                                )
                            }
                            {/*</div>*/}
                            <AccountMenu handleClose={handleClose} anchorEl={anchorEl}/>
                        </>
                    </Toolbar>
                </Container>
            </AppBar>
            {
                !props.headerOnly && (props.headerOnly || scopePeerState) && !isDriver && !isInactive ? (
                    <>
                        <Drawer
                            variant="permanent"
                            className={clsx(classes.drawer, openMainMenu ? classes.drawerOpen : classes.drawerClose)}
                            classes={{
                                paper: openMainMenu ? classes.drawerOpen : classes.drawerClose,
                            }}>
                            <Toolbar/>
                            <MenuList className={classes.menuList}>
                                <SideMenu
                                    itemClickHandler={itemClickHandler}
                                    selectedItem={selectedItem}
                                    setSelectedItem={setSelectedItem}
                                    setLocalMenuState={handleToggleMenu}
                                    // openCollapse={openCollapse}
                                    openCollapse={submenuState}
                                    // setLocalMenuCollapseState={setLocalMenuCollapseState}
                                    openMainMenu={openMainMenu}
                                />

                            </MenuList>
                            <ToggleIcon/>
                        </Drawer>
                        {/*<RightDrawer open={right} toggleDrawerRight={toggleDrawerRight}/>*/}
                    </>
                ) : (
                    ''
                )
            }
        </Root>
    );
}

export default Header

Header.propTypes = {
    headerOnly: PropTypes.bool,
};
