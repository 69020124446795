import {GridEditModes, useGridApiContext, useGridRootProps} from "@mui/x-data-grid-premium";
import * as React from "react";
import PropTypes from "prop-types";
import {TimePicker} from "@mui/x-date-pickers-pro";
import {format} from "date-fns";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';

//Edit Field
function EditTime(props) {
    const {id, value, field} = props;
    const rootProps = useGridRootProps();
    const apiRef = useGridApiContext();

    let valueDate = null;

    if(value){
        const chars = value.split(':');
        valueDate =  new Date(null,null,null,chars[0],chars[1])
    }


    const handleChange = async (newValue) => {
        if(isNaN(newValue)){
            return;
        }
        const isValid = await apiRef.current.setEditCellValue({id, field, value: format(newValue, 'HH:mm')});

        if (isValid && rootProps.editMode === GridEditModes.Cell) {
            apiRef.current.stopCellEditMode({id, field, cellToFocusAfter: 'below'});
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
                sx={{height:'100%'}}
                label="hh:mm"
                ampm={false}
                value={valueDate}
                slotProps={{
                    textField:{
                        variant: "outlined",
                        fullWidth: true,
                        size: "small"
                    }
                }}
                onChange={handleChange}
            />
        </LocalizationProvider>
    );
}

EditTime.propTypes = {
    field: PropTypes.string.isRequired,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
};

// ===========================
// Render Time
// ===========================
export function renderTime(params) {
    if (params.value == null) {
        return '';
    }
    return <></>;
}

// ===========================
// Render edit Time
// ===========================
export function renderEditTime(params) {
    return <EditTime{...params} />;
}