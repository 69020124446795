import * as actionTypes from "./actionTypes";
import axios from "../../api/axios-peer";

/**
 *
 * @param auth_key
 * @returns {(function(*): void)|*}
 */
export const getInfo = (auth_key) => {
    return dispatch => {
        dispatch(setLoading());

        axios.get('/templateaudit/info?auth_key=' + auth_key, {
            responseType: 'json',
        }).then(response => {
            dispatch(findInfoSuccess(response.data));

        }).catch(error => {
            dispatch(setError(error.message))
        })
    }
}

/**
 *
 * @param auth_key
 * @param commentData
 * @returns {(function(*): void)|*}
 */
export const sendComment = (auth_key, commentData) => {
    return dispatch => {
        dispatch(setLoading());

        axios.post('/templateaudit/comment?auth_key=' + auth_key, commentData, {
            responseType: 'json',
        }).then(response => {
            dispatch(sendCommentSuccess(response.data));
        }).catch(error => {
            dispatch(setError(error.message))
        })
    }
}

/**
 *
 * @param data
 * @returns {{data, type: string}}
 */
export const setCommentData = (data) => {
    return {
        type: actionTypes.TEMPLATE_AUDIT_SET_COMMENT_DATA,
        data: data
    }
}

/**
 *
 * @param data
 * @returns {{data, type: string}}
 */
export const findInfoSuccess = (data) => {
    return {
        type: actionTypes.TEMPLATE_AUDIT_SET_INFO,
        data: data
    }
}

/**
 *
 * @param data
 * @returns {{data, type: string}}
 */
export const sendCommentSuccess = (data) => {
    return {
        type: actionTypes.TEMPLATE_AUDIT_SEND_COMMENT_SUCCESS,
        data: data
    }
}

/**
 *
 * @param error
 * @returns {{type: string, error}}
 */
export const setError = (error) => {
    return {
        type: actionTypes.TEMPLATE_AUDIT_SET_ERROR,
        error: error
    }
}

/**
 *
 * @returns {{type: string}}
 */
export const setLoading = () => {
    return {
        type: actionTypes.TEMPLATE_AUTH_START_LOADING
    }
}
