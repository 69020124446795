import React, {useMemo} from "react";
import {Marker} from "react-map-gl";
import Pin from "./Pin";
import {Markers} from "../Interfaces/MapInterfaces";

/**
 *
 * @param markers
 * @param handlePopup
 * @param isRoute
 */
export default function markers({markers = [], handlePopup, isRoute}: Markers) {

    return useMemo(
        () => markers.map((item, index) => {
            let type = item.type;
            let color = item.color;
            let size = 30

            if (isRoute) {
                if (index === 0) {
                    type = 'start';
                    color = '#FF6600';
                    size = 50;
                } else if (index === markers.length - 1) { // Check if it's the last index
                    type = 'end';
                    color = '#3333FF';
                    size = 50;
                } else if (index > 0 && index < markers.length - 1) { // Ensures it's not the first or last index
                    type = 'gps';
                    color = '#406AED';
                    size = 18;
                }
            }
            return <Marker
                key={`marker-${index}`}
                longitude={item.longitude}
                latitude={item.latitude}
                onClick={(e: any) => {
                    e?.originalEvent?.stopPropagation();
                    handlePopup(item)
                }}
            >
                <Pin size={size} type={type} color={color}/>
            </Marker>
        }),
        [markers]
    );
}