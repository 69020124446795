import * as Yup from 'yup';
import formModel from "./formModel";

const {
    formField: {
        disposer_type,
        senderparty_name,
        senderpartyaddress_street_name,
        senderpartyaddress_house_no,
        senderpartyaddress_house_no_addition,
        senderpartyaddress_postal_code,
        senderpartyaddress_city_name,
        senderparty_identifier_value,
        invoiceaddress_description,
        invoiceaddress_street_name,
        invoiceaddress_house_no,
        invoiceaddress_house_no_addition,
        invoiceaddress_postal_code,
        invoiceaddress_city_name,
        disposerparty_name,
        disposerpartyaddress_street_name,
        disposerpartyaddress_house_no,
        disposerpartyaddress_house_no_addition,
        disposerpartyaddress_postal_code,
        disposerpartyaddress_city_name,
        locationorigin_description,
        locationoriginaddress_street_name,
        locationoriginaddress_house_no,
        locationoriginaddress_house_no_addition,
        locationoriginaddress_postal_code,
        locationoriginaddress_city_name,
        locationorigin_geo_checked_on,
        locationorigin_address_latitude,
        locationorigin_address_longitude,
        agentparty_name,
        agentpartyaddress_street_name,
        agentpartyaddress_house_no,
        agentpartyaddress_house_no_addition,
        agentpartyaddress_postal_code,
        agentpartyaddress_city_name,
        locationdestination_description,
        locationdestinationaddress_street_name,
        locationdestinationaddress_house_no,
        locationdestinationaddress_house_no_addition,
        locationdestinationaddress_postal_code,
        locationdestinationaddress_city_name,
        template_transported_by_type,
        carrierparty_name,
        carrierpartyaddress_street_name,
        carrierpartyaddress_house_no,
        carrierpartyaddress_house_no_addition,
        carrierpartyaddress_postal_code,
        carrierpartyaddress_city_name,
        carrierparty_identifier_value,
        template_is_route_collection,
        template_is_collection_scheme,
        template_is_repetitive_loads,
        template_waste_stream_id,
        gooditem_description,
        gooditem_eural_code,
        gooditem_processing_method,
        gooditem_estimated_unit_value,
        template_project_description,
        // template_logo_image,
        order_type,
        gooditem_unit_type_id,
        template_show_color,
        template_weight_bridge_uuid,
        template_reference,
        senderpartyaddress_mail,
        disposerpartyaddress_mail,
        locationdestinationaddress_mail,
        remarks,
        remarks_driver,
        remark,
        type,
        document_number,
        organisation_name,
        form_type,
        share_template
    }
} = formModel;

const identifierValueValidation = '^[a-zA-Z]{4}\\d{6}$'
const specialCharsCheck = /^(?!^[.!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+$).*/;

export const getValidationSchema = (locationCheckEnabled) => [
    Yup.object().shape({
        [disposer_type.name]: Yup.number().nullable(),
        [template_project_description.name]: Yup.string().nullable(),
        [template_waste_stream_id.name]: Yup.string().max(12, `${template_waste_stream_id.maxErrorMsg}`).nullable(),
        [template_transported_by_type.name]: Yup.number().nullable(),
        [order_type.name]: Yup.string().nullable().max(100),
        [form_type.name]: Yup.string().nullable().max(2),
        [share_template.name]: Yup.array().nullable(),
        [gooditem_unit_type_id.name]: Yup.string().nullable().max(11),
        [template_show_color.name]: Yup.string().nullable().max(10),
        [remarks.name]: Yup.string().nullable(),
        [remarks_driver.name]: Yup.string().nullable(),
        [template_weight_bridge_uuid.name]: Yup.string().nullable().max(45),
        [template_reference.name]: Yup.string().nullable().max(36, `${template_reference.maxLength}`),
        [template_is_route_collection.name]: Yup.number().typeError(`${template_is_route_collection.requiredErrorMsg}`).required(`${template_is_route_collection.requiredErrorMsg}`),
        [template_is_collection_scheme.name]: Yup.number().typeError(`${template_is_collection_scheme.requiredErrorMsg}`).required(`${template_is_collection_scheme.requiredErrorMsg}`),
        [template_is_collection_scheme.name]: Yup.number().typeError(`${template_is_collection_scheme.requiredErrorMsg}`).required(`${template_is_collection_scheme.requiredErrorMsg}`),
        [template_is_repetitive_loads.name]: Yup.number().typeError(`${template_is_repetitive_loads.requiredErrorMsg}`).required(`${template_is_repetitive_loads.requiredErrorMsg}`),

        //================================
        // Blok 1
        //================================
        senderparty: Yup.object().shape({
            [senderparty_name.name]: Yup
                .string()
                .trim()
                .required(`${senderparty_name.requiredErrorMsg}`)
                .matches(specialCharsCheck, `${senderparty_name.incorrectName}`),
            [senderpartyaddress_street_name.name]: Yup
                .string()
                .trim()
                .required(`${senderpartyaddress_street_name.requiredErrorMsg}`)
                .matches(specialCharsCheck, `${senderpartyaddress_street_name.incorrectAddress}`),
            [senderpartyaddress_city_name.name]: Yup
                .string()
                .trim()
                .required(`${senderpartyaddress_city_name.requiredErrorMsg}`)
                .matches(specialCharsCheck, `${senderpartyaddress_city_name.incorrectCityName}`),
            [senderpartyaddress_house_no.name]: Yup
                .number()
                .required(`${senderpartyaddress_house_no.requiredErrorMsg}`)
                .positive(`${senderpartyaddress_house_no.positiveErrorMsg}`)
                .nullable(),
            [senderpartyaddress_house_no_addition.name]: Yup
                .string()
                .nullable(),
            [senderpartyaddress_postal_code.name]: Yup
                .string()
                .trim()
                .max(10, `${senderpartyaddress_postal_code.maxLength}`)
                .required(`${senderpartyaddress_postal_code.requiredErrorMsg}`)
                .matches(specialCharsCheck, `${senderpartyaddress_postal_code.incorrectPostalCode}`),
            [senderparty_identifier_value.name]: Yup
                .string()
                .min(10, `${senderparty_identifier_value.minErrorMsg}`)
                .max(10, `${senderparty_identifier_value.maxErrorMsg}`)
                .matches(identifierValueValidation, `${senderparty_identifier_value.vihbIncorrectMsg}`)
                .matches(specialCharsCheck, `${senderparty_identifier_value.requiredErrorMsg}`),
            [senderpartyaddress_mail.name]: Yup.array().nullable()
                .transform(function (value, originalValue) {
                    if (this.isType(value) && value !== null) {
                        return value;
                    }
                    return originalValue ? originalValue.split(/[,]+/) : [];
                })
                .of(Yup.string().trim().email(`${senderpartyaddress_mail.emailErrorMsg}`)),
        }),

        //================================
        // Blok 2
        //================================
        invoiceaddress: Yup.object().nullable().shape({
            [invoiceaddress_description.name]: Yup.string().nullable(),
            [invoiceaddress_street_name.name]: Yup.string().nullable(),
            [invoiceaddress_house_no.name]: Yup.number().nullable().positive(`${invoiceaddress_house_no.positiveErrorMsg}`),
            [invoiceaddress_house_no_addition.name]: Yup.string().nullable(),
            [invoiceaddress_postal_code.name]: Yup.string().nullable().max(10, `${invoiceaddress_postal_code.maxLength}`),
            [invoiceaddress_city_name.name]: Yup.string().nullable(),
        }),

        //================================
        // Blok 3a
        //================================

        disposerparty: Yup.object().shape({
            [disposerparty_name.name]: Yup.string().trim().required(`${disposerparty_name.requiredErrorMsg}`).matches(specialCharsCheck, `${disposerparty_name.incorrectName}`),
            [disposerpartyaddress_street_name.name]: Yup.string().trim().required(`${disposerpartyaddress_street_name.requiredErrorMsg}`).matches(specialCharsCheck, `${disposerpartyaddress_street_name.incorrectAddress}`),
            [disposerpartyaddress_house_no.name]: Yup.number().required(`${disposerpartyaddress_house_no.requiredErrorMsg}`).nullable().positive(`${disposerpartyaddress_house_no.positiveErrorMsg}`),
            [disposerpartyaddress_house_no_addition.name]: Yup.string().nullable(),
            [disposerpartyaddress_postal_code.name]: Yup.string().trim().required(`${disposerpartyaddress_postal_code.requiredErrorMsg}`).matches(specialCharsCheck, `${disposerpartyaddress_postal_code.incorrectPostalCode}`).max(10, `${disposerpartyaddress_postal_code.maxLength}`),
            [disposerpartyaddress_city_name.name]: Yup.string().trim().required(`${disposerpartyaddress_city_name.requiredErrorMsg}`).matches(specialCharsCheck, `${disposerpartyaddress_city_name.incorrectCityName}`),
            [disposerpartyaddress_mail.name]: Yup.array().nullable()
                .transform(function (value, originalValue) {
                    if (this.isType(value) && value !== null) {
                        return value;
                    }
                    return originalValue ? originalValue.split(/[,]+/) : [];
                })
                .of(Yup.string().trim().email(`${disposerpartyaddress_mail.emailErrorMsg}`)),
        }),

        //================================
        // Blok 3e
        //================================
        locationorigin: Yup.object().shape({
            [locationorigin_description.name]: Yup.string().trim().required(`${locationorigin_description.requiredErrorMsg}`).matches(specialCharsCheck, `${locationorigin_description.incorrectName}`).max(50, `${locationorigin_description.maxLength}`),
            [locationoriginaddress_street_name.name]: Yup.string().trim().required(`${locationoriginaddress_street_name.requiredErrorMsg}`).matches(specialCharsCheck, `${locationoriginaddress_street_name.incorrectAddress}`),
            [locationoriginaddress_house_no.name]: Yup.number().nullable().positive(`${locationoriginaddress_house_no.positiveErrorMsg}`),
            [locationoriginaddress_house_no_addition.name]: Yup.string().nullable(),
            [locationoriginaddress_postal_code.name]: Yup.string().nullable().max(10, `${locationoriginaddress_postal_code.maxLength}`),
            [locationoriginaddress_city_name.name]: Yup.string().trim().required(`${locationoriginaddress_city_name.requiredErrorMsg}`).matches(specialCharsCheck, `${locationoriginaddress_city_name.incorrectCityName}`),
            [locationorigin_geo_checked_on.name]: locationCheckEnabled
                ? Yup.string().required(`${locationorigin_geo_checked_on.requiredErrorMsg}`)
                : Yup.string().nullable(),
            [locationorigin_address_longitude.name]: Yup.number().nullable(),
            [locationorigin_address_latitude.name]: Yup.number().nullable(),
        }),

        //================================
        // Blok 4a
        //================================
        agentparty: Yup.object().nullable().shape({
            [agentparty_name.name]: Yup.string().nullable(),
            [agentpartyaddress_street_name.name]: Yup.string().nullable(),
            [agentpartyaddress_house_no.name]: Yup.number().nullable().positive(`${agentpartyaddress_house_no.positiveErrorMsg}`),
            [agentpartyaddress_house_no_addition.name]: Yup.string().nullable(),
            [agentpartyaddress_postal_code.name]: Yup.string().nullable().max(10, `${agentpartyaddress_postal_code.maxLength}`),
            [agentpartyaddress_city_name.name]: Yup.string().nullable(),
        }),

        //================================
        // Blok 4e
        //================================
        locationdestination: Yup.object().shape({
            [locationdestination_description.name]: Yup.string().trim().required(`${locationdestination_description.requiredErrorMsg}`).matches(specialCharsCheck, `${locationdestination_description.incorrectName}`).max(50, `${locationdestination_description.maxLength}`),
            [locationdestinationaddress_street_name.name]: Yup.string().trim().required(`${locationdestinationaddress_street_name.requiredErrorMsg}`).matches(specialCharsCheck, `${locationdestinationaddress_street_name.incorrectAddress}`),
            [locationdestinationaddress_house_no.name]: Yup.number().nullable().positive(`${locationdestinationaddress_house_no.positiveErrorMsg}`),
            [locationdestinationaddress_house_no_addition.name]: Yup.string().nullable(),
            [locationdestinationaddress_postal_code.name]: Yup.string().nullable().max(10, `${locationdestinationaddress_postal_code.maxLength}`),
            [locationdestinationaddress_city_name.name]: Yup.string().trim().required(`${locationdestinationaddress_city_name.requiredErrorMsg}`).matches(specialCharsCheck, `${locationdestinationaddress_city_name.incorrectCityName}`),
            [locationdestinationaddress_mail.name]: Yup.array().nullable()
                .transform(function (value, originalValue) {
                    if (this.isType(value) && value !== null) {
                        return value;
                    }
                    return originalValue ? originalValue.split(/[,]+/) : [];
                })
                .of(Yup.string().trim().email(`${locationdestinationaddress_mail.emailErrorMsg}`)),
        }),

        //================================
        // Blok 5
        // ================================
        carrierparty: Yup.object().shape({
            [carrierparty_name.name]: Yup.string().nullable(),
            [carrierpartyaddress_street_name.name]: Yup.string().nullable(),
            [carrierpartyaddress_house_no.name]: Yup.number().nullable().positive(`${carrierpartyaddress_house_no_addition.positiveErrorMsg}`),
            [carrierpartyaddress_house_no_addition.name]: Yup.string().nullable(),
            [carrierpartyaddress_postal_code.name]: Yup.string().nullable().max(10, `${carrierpartyaddress_postal_code.maxLength}`),
            [carrierpartyaddress_city_name.name]: Yup.string().nullable(),
            [carrierparty_identifier_value.name]: Yup.string()
                .min(10, `${carrierparty_identifier_value.minErrorMsg}`)
                .max(10, `${carrierparty_identifier_value.maxErrorMsg}`)
                .nullable()
                .matches(identifierValueValidation, `${carrierparty_identifier_value.vihbIncorrectMsg}`),
        }).nullable(true),

        //================================
        // Blok 6
        //================================
        gooditem: Yup.object().shape({
            [gooditem_description.name]: Yup.string().trim().required(`${gooditem_description.requiredErrorMsg}`).matches(specialCharsCheck, `${gooditem_description.incorrectDescription}`),
            [gooditem_eural_code.name]: Yup.string().trim().required(`${gooditem_eural_code.requiredErrorMsg}`).max(10),
            [gooditem_processing_method.name]: Yup.string().trim().required(`${gooditem_processing_method.requiredErrorMsg}`).matches(specialCharsCheck, `${gooditem_processing_method.incorrectProcessingMethod}`).max(10),
            [gooditem_estimated_unit_value.name]: Yup.number().nullable().min(0, `${gooditem_estimated_unit_value.minErrorMsg}`).max(999999, `${gooditem_estimated_unit_value.maxErrorMsg}`)
        }),

        //================================
        // Blok 7 report number
        //================================
        // reportnumber_remark
        reportStatement: Yup.object().shape({
            [remark.name]: Yup.string().trim().nullable(),
            [type.name]: Yup.string().trim().nullable(),
            [document_number.name]: Yup.string().trim().nullable(),
            [organisation_name.name]: Yup.string().trim().nullable(),
        }),
    })
];


