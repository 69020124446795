import {GridEditModes, useGridApiContext, useGridRootProps} from "@mui/x-data-grid-premium";
import * as React from "react";
import PropTypes from "prop-types";
import i18n from "../../../i18n/i18n";
import {useDispatch} from "react-redux";
import {openDialogData, updateDataTableSingleValue} from "../../../store/actions/dataTableAction";
import Paper from "@mui/material/Paper";
import {IconButton, InputBase} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";

//Edit Field
function EditDialogSearch(props) {
    const {id, value, field, formattedValue} = props;
    const rootProps = useGridRootProps();
    const apiRef = useGridApiContext();

    const dispatch = useDispatch();

    const openDialog = (state) => {
        const idRow = apiRef.current.getRowIndexRelativeToVisibleRows(id)
        dispatch(updateDataTableSingleValue('goodsItem', idRow, 'editField', field))
        dispatch(openDialogData(state, 'goodsItem', false, apiRef.current.getRowIndexRelativeToVisibleRows(id)))
    };

    const clearInput = () => {
        // apiRef.current.setEditCellValue({id, field, value: ''});
        const idRow = apiRef.current.getRowIndexRelativeToVisibleRows(id)
        dispatch(updateDataTableSingleValue('goodsItem', idRow, field, ''))
        dispatch(updateDataTableSingleValue('goodsItem', idRow, field + '_edited', true))
    };

    if (value !== formattedValue) {
        const isValid = apiRef.current.setEditCellValue({id, field, value: formattedValue});
        if (isValid && rootProps.editMode === GridEditModes.Cell) {
            apiRef.current.stopCellEditMode({id, field, cellToFocusAfter: 'below'});
        }
    }

    return (
        <>
            <Paper
                component="form"
                sx={{
                    p: '2px 4px',
                    display: 'flex',
                    alignItems: 'center',
                    width: 400,
                    background: '#FBE2D4'
                }}
            >
                <IconButton sx={{p: '10px'}} aria-label="search" onClick={() => openDialog(true)}>
                    <Tooltip title={i18n.t('TOOLTIP_CLICK_TO_SEARCH_COMPANY')}>
                        <SearchIcon/>
                    </Tooltip>
                </IconButton>
                <InputBase
                    key={formattedValue}
                    sx={{
                        ml: 1,
                        flex: 1,
                        '&.MuiInputBase-input': {
                            // avoid the use of !important
                            fontFamily: '-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif',
                            fontWeight: 400,
                            fontSize: '1rem',
                            lineHeight: '1.4375em',
                            "&:disabled": {
                                color: '#000000',
                                cursor: 'pointer',
                            },
                        },
                    }}
                    onClick={() => openDialog(true)}
                    value={formattedValue || ''}
                    inputProps={{
                        onKeyPress: (event) => {
                            if (event.key === "Enter") {
                                event.preventDefault();
                            }
                        }
                    }}
                />
                <IconButton sx={{p: '10px'}} aria-label="clear" onClick={() => clearInput()}>
                    <Tooltip title={i18n.t('TOOLTIP_CLEAR_FIELD')}>
                        <ClearIcon/>
                    </Tooltip>
                </IconButton>

            </Paper>
        </>
    );
}

EditDialogSearch.propTypes = {
    field: PropTypes.string.isRequired,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    formattedValue: PropTypes.string
};


// ===========================
// Render Edit DialogSearch
// ===========================
export function renderEditDialogSearch(params) {
    return <EditDialogSearch {...params} />;
}