import React from 'react';
import {ThemeProvider} from '@mui/material/styles';
import theme from './theme';
import Layout from './hoc/Formklub/Layout/Layout'
import {useSelector} from "react-redux";
import customClasses from './App.module.css';
import LoginLayout from "./hoc/Login/LoginLayout";
import {StyledEngineProvider} from '@mui/material/styles';
import ErrorBoundary from './ErrorBoundary'
import {SnackbarProvider} from 'notistack';
import {LicenseInfo} from "@mui/x-license";
import {isLoggedIn} from "./store/selectors/userSelector";

/**
 *
 * @returns {Element}
 * @constructor
 */
function App() {
    const loggedIn = useSelector(state => isLoggedIn(state));

    let page;

    const classes = [customClasses.App]
    LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_X_LICENSE_PRO);

    if (!loggedIn) {
        classes.push(customClasses.darkOverlay, customClasses.Login);
        page = <LoginLayout/>
    } else {
        page = <Layout/>
    }

    return (
        <ErrorBoundary>
            <div className={classes.join(' ')}>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={theme}>
                        <SnackbarProvider maxSnack={3}>
                            {page}
                        </SnackbarProvider>
                    </ThemeProvider>
                </StyledEngineProvider>
            </div>
        </ErrorBoundary>
    );
}

export default App;
