import axios, {InternalAxiosRequestConfig} from 'axios';


const instance = axios.create({
    baseURL: process.env.REACT_APP_GPS_URL,
    headers: {
        'Access-Control-Allow-Credentials': true,
        "Content-Type": `application/json`,
        Accept: `application/json`,

    }
});

instance.interceptors.request.use(
    function (config: InternalAxiosRequestConfig) {

        config.headers = config.headers ?? {};
        config.url = config.url ?? '';
        const token = localStorage.getItem('token')

        if (token != null) {
            config.headers.Authorization = `Bearer ${token}`;
        } else {
            console.log('No token given!');
        }

        if (config.url !== '/authorize/introspect') {
            const questionMarked = config.url.includes("?");
            if (config.url.includes('no-region-filter') === false) {
                const regionsSelected = localStorage.getItem("regionsSelected") ?? '[]';
                console.log(regionsSelected)
                const selectedRegions: [] = JSON.parse(regionsSelected);
                if (selectedRegions != null) {
                    config.url += (questionMarked ? "&" : '?') + "selected_regions=" + selectedRegions.join(',');
                }
            }
        }
        return config;
    }, function (err) {
        return Promise.reject(err);
    });

export default instance;