import {GridEditModes, useGridApiContext, useGridRootProps} from "@mui/x-data-grid-premium";
import * as React from "react";
import PropTypes from "prop-types";
import {updateDataTableSingleValue} from "../../../store/actions/dataTableAction";
import Select from "@mui/material/Select";
import {MenuItem} from "@mui/material";
import {useDispatch} from "react-redux";

//Field
const StateChip = React.memo(() => {
    return ''
});

StateChip.displayName = "StateChip"
StateChip.propTypes = {
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
}

//Edit Field
function EditStateChip(props) {
    const {id, value, field, colDef, index, row} = props;
    const rootProps = useGridRootProps();
    const apiRef = useGridApiContext();
    const dispatch = useDispatch();

    if(typeof value === 'string' && colDef.valueOptions[value]?.state){
        apiRef.current.setEditCellValue({id, field, value: colDef.valueOptions[value].state});
    }

    const handleChange = async (event) => {
        const getState = Object.values(colDef.valueOptions).find(item => item.state === event.target.value)
        const isValid = await apiRef.current.setEditCellValue({id, field, value: event.target.value});

        dispatch(updateDataTableSingleValue(index, apiRef.current.getRowIndexRelativeToVisibleRows(id), 'state_label', getState?.state_label || ''))
        dispatch(updateDataTableSingleValue(index, apiRef.current.getRowIndexRelativeToVisibleRows(id), 'state', typeof getState?.state === 'number' ? getState?.state : ''))
        dispatch(updateDataTableSingleValue(index, apiRef.current.getRowIndexRelativeToVisibleRows(id), 'state_background', getState?.state_background || ''))
        dispatch(updateDataTableSingleValue(index, apiRef.current.getRowIndexRelativeToVisibleRows(id), 'state_edited', true))

        if (isValid && rootProps.editMode === GridEditModes.Cell) {
            apiRef.current.stopCellEditMode({id, field, cellToFocusAfter: 'below'});
        }
    };

    return (
        <Select
            value={value}
            onChange={handleChange}
            size="small"
            fullWidth
            name={'selectState'}
            defaultValue={value}
        >
            {
                Object.keys(colDef.valueOptions).map((item, i) => {
                    const option = colDef.valueOptions[item];
                    return <MenuItem key={option.state} style={{width: '100%'}}
                                     value={option.state}>{option.state_label}</MenuItem>
                })
            }
        </Select>

    );
}

EditStateChip.propTypes = {
    field: PropTypes.string.isRequired,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    colDef: PropTypes.object,
    index: PropTypes.string,
};

// ===========================
// Render State chip
// ===========================
export function renderStateChip(params) {
    if (params.value == null) {
        return '';
    }
    return <StateChip value={params.value}/>;
}

// ===========================
// Render edit stateChip
// ===========================
export function renderEditStateChip(params, index) {
    // console.log(params)
    return <EditStateChip {...params} index={index}/>;
}