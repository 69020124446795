import PropTypes from "prop-types";
import WrongLocationIcon from '@mui/icons-material/WrongLocation';
import GoodLocationIcon from '@mui/icons-material/WhereToVote';
import UnknownLocationIcon from '@mui/icons-material/NotListedLocation';
import React from "react";
import {Tooltip} from "@mui/material";
import {useTheme} from '@mui/material/styles';
import i18n from "../../../../i18n/i18n";

interface locationVerified {
    state: 'unknown' | 'notVerified' | 'verified' | 'wrong'
    error: boolean;
    disabled: boolean;
}

export default function LocationPin(props: locationVerified) {
    const theme = useTheme();

    const locationIcon = () => {
        const iconMap = {
            unknown: <UnknownLocationIcon/>,
            notVerified: <UnknownLocationIcon/>,
            wrong: <WrongLocationIcon/>,
            verified: <GoodLocationIcon/>
        };

        const colorMap = {
            unknown: theme.palette.text.primary,
            notVerified: theme.palette.info.main,
            wrong: theme.palette.error.main,
            verified: theme.palette.success.main
        };

        const tooltipMap = {
            unknown: i18n.t('TOOLTIP_LOCATION_UNKNOWN'),
            notVerified: i18n.t('TOOLTIP_LOCATION_NOT_VERIFIED'),
            wrong: i18n.t('TOOLTIP_LOCATION_WRONG'),
            verified: i18n.t('TOOLTIP_LOCATION_VERIFIED')
        };

        const IconComponent = iconMap[props.state] || <UnknownLocationIcon/>;
        const color = (() => {
            if (props.disabled) return 'grey';
            if (props.error) return 'red';
            return colorMap[props.state] || 'grey';
        })();
        const tooltipText = tooltipMap[props.state] || i18n.t('TOOLTIP_LOCATION_UNKNOWN');

        return (
            <Tooltip title={tooltipText}>
                <div style={{color}}>
                    {IconComponent}
                </div>
            </Tooltip>
        );
    };

    return (
        <div style={{alignContent: 'space-evenly', textAlign: 'center'}}>
            {locationIcon()}
        </div>
    );
}


LocationPin.propTypes = {
    state: PropTypes.string,
}