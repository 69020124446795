import React from "react";
import {FormControlLabel, Switch, TextField, Typography} from "@mui/material";
import i18n from "../../../i18n/i18n";
import PropTypes from "prop-types";
import Grid from '@mui/material/Grid2';

export default function RegistrationTabInvoiceAddress(props) {

    return (
        <div>
            <Grid container alignItems="flex-end" justifyContent="flex-end">
                <Grid size={{xs:12, sm:5}}>
                    <Typography align='left' variant="h6">{i18n.t('TITLE_WHAT_IS_INVOICE_ADDRESS')}</Typography>
                </Grid>
                <Grid size={{xs:12, sm:7}}>
                    <FormControlLabel
                        sx={{float: 'right'}}
                        control={<Switch
                            id="tabInvoiceAddress-inputSwitchCopyAddress"
                            checked={(props.switchCopyAddress.value)}
                            onChange={(e) => props.copyAddress(e)}
                            name="inputChangeSwitchCopyAddress"
                            color="primary"
                        />}
                        value={props.switchCopyAddress.value}
                        label={<Typography variant="body2">{i18n.t('INVOICE_SAME_AS_ADDRESS')}</Typography>}
                    />
                </Grid>
                <Grid size={{xs:12, sm:6}}>
                    <TextField
                        id="tabInvoiceAddress-inputAddress"
                        label={i18n.t('ADDRESS')}
                        type="text"
                        fullWidth
                        autoFocus
                        variant="outlined"
                        margin="normal"
                        error={Boolean(props.address.error.length)}
                        value={props.address.value}
                        onChange={props.inputChangeAddress}/>
                </Grid>
                <Grid size={{xs:12, sm:3}}>
                    <TextField
                        id="tabInvoiceAddress-inputHouseNumber"
                        label={i18n.t('HOUSE_NUMBER')}
                        type="number"
                        name="street_number"
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        error={Boolean(props.houseNumber.error.length)}
                        value={props.houseNumber.value}
                        onChange={props.inputChangeHouseNumber}/>
                </Grid>
                <Grid size={{xs:12, sm:3}}>
                    <TextField
                        id="tabInvoiceAddress-inputHouseNumberAddition"
                        label={i18n.t('HOUSE_NUMBER_ADDITION')}
                        type="text"
                        name="street_number_addition"
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        value={props.houseNumberAddition.value}
                        onChange={props.inputChangeHouseNumberAddition}/>
                </Grid>
                <Grid size={12}>
                    <TextField
                        id="tabInvoiceAddress-inputZipCode"
                        label={i18n.t('ZIPCODE')}
                        type="text"
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        error={Boolean(props.zipcode.error.length)}
                        value={props.zipcode.value}
                        onChange={props.inputChangeZipCode}/>
                </Grid>
                <Grid size={12}>
                    <TextField
                        id="tabInvoiceAddress-inputPlace"
                        label={i18n.t('CITY')}
                        type="text"
                        name="city"
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        error={Boolean(props.place.error.length)}
                        value={props.place.value}
                        onChange={props.inputChangePlace}/>
                </Grid>
                <Grid size={12}>
                    <TextField
                        id="tabInvoiceAddress-inputCountry"
                        label={i18n.t('COUNTRY')}
                        type="text"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        error={Boolean(props.country.error.length)}
                        value={props.country.value}
                        onChange={props.inputChangeCountry}/>
                    <br/>
                </Grid>
            </Grid>
        </div>
    )
}

RegistrationTabInvoiceAddress.propTypes = {
    switchCopyAddress: PropTypes.object,
    copyAddress: PropTypes.func,
    address: PropTypes.object,
    houseNumber: PropTypes.object,
    houseNumberAddition: PropTypes.object,
    zipcode: PropTypes.object,
    place: PropTypes.object,
    country: PropTypes.object,
    inputChangeAddress: PropTypes.func.isRequired,
    inputChangeHouseNumber: PropTypes.func.isRequired,
    inputChangeHouseNumberAddition: PropTypes.func.isRequired,
    inputChangeZipCode: PropTypes.func.isRequired,
    inputChangePlace: PropTypes.func.isRequired,
    inputChangeCountry: PropTypes.func.isRequired,
}