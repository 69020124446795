import React from "react";
import Grid from "@mui/material/Grid2";
import customClasses from "./TemplateForm.module.css";
import PropTypes from "prop-types";
import {FormTextField} from "./Components/FormTextField";
import {FastField} from "formik";
import {Typography} from "@mui/material";
import templateFooterMark from './../../../assets/images/templateFooterMark.webp';

export default function TemplateFormFooter(props) {

    const {
        formField: {
            senderpartyaddress_mail,
            disposerpartyaddress_mail,
            locationdestinationaddress_mail
        }
    } = props;

    return <React.Fragment>
        <Grid className={customClasses.footerWhite} size={{xs: 12, sm: 3}}>
            <img src={templateFooterMark} alt="logo" width="auto" height="40px"
                 draggable="false" style={{display: 'inline-block', paddingTop: 5, paddingRight: 10}}/>
            <div style={{display: 'inline-block'}}>
                Auteursrecht:<br/>
                sVa / Stichting Vervoeradres,<br/>
                Den Haag
            </div>
        </Grid>
        <Grid className={customClasses.footerWhite} size={{xs: 12, sm: 6}}>
            Het vervoer geschiedt op de door sVa / Stichting Vervoeradres ter griffie van de arr.rechtbank te Amsterdam
            en Rotterdam gedeponeerde algemene voorwaarden voor het afvalstoffenvervoer over de weg, laatste versie.
            voor aanspakelijkheid vervoerder z.o.z. <span style={{float: 'right'}}>in de vracht is verzekering net begrepen</span>
        </Grid>
        <Grid className={customClasses.footerWhite} size={{xs: 12, sm: 3}}>
            WIXXXXXXXXXX
        </Grid>
        <Grid className={customClasses.footerWhite} size={{xs: 12, sm: 3}}>
            <Grid size={12}>
                Handtekening afzender
            </Grid>
            <Grid container className={customClasses.footerInput} size={12}>
                <Grid size={4}>
                    {senderpartyaddress_mail.label}
                </Grid>
                <Grid size={8}>
                    <FastField
                        autoComplete="new-password"
                        name={"senderparty." + senderpartyaddress_mail.name}
                        disabled={props.disabled}
                        sizeClass={customClasses.inputFull}
                        component={FormTextField}
                    />
                </Grid>
            </Grid>
        </Grid>
        <Grid className={customClasses.footerOrange} size={{xs: 12, sm: 3}}>
            <Grid size={12}>
                Handtekening ontdoener
                <Typography sx={{
                    fontSize: 10,
                    fontStyle: 'italic',
                    textTransform: 'uppercase'
                }}>{props.form_type === 'B2' ? "HANDtekening toepasser Eigenaar" : null}</Typography>
            </Grid>
            <Grid container className={customClasses.footerInput} size={12}>
                <Grid size={4}>
                    {disposerpartyaddress_mail.label}
                </Grid>
                <Grid size={8}>
                    <FastField
                        autoComplete="new-password"
                        name={"disposerparty." + disposerpartyaddress_mail.name}
                        disabled={props.disabled}
                        sizeClass={customClasses.inputFull}
                        component={FormTextField}
                    />
                </Grid>
            </Grid>
        </Grid>
        <Grid className={customClasses.footerOrange} size={{xs: 12, sm: 3}}>
            <Grid size={12}>
                Handtekening transporteur voor ontvangst der zending met gelijkgenummerde vrachtbrief
            </Grid>
            <Grid container className={customClasses.footerInput} size={12}/>
        </Grid>
        <Grid className={customClasses.footerOrange} size={{xs: 12, sm: 3}}>
            <Grid size={12}>
                Handtekening ontvanger (geadressserde) voor goede ontvangst der zending met gelijk genummerde
                vrachtbrief
            </Grid>
            <Grid container className={customClasses.footerInput} size={12}>
                <Grid size={4}>
                    {locationdestinationaddress_mail.label}
                </Grid>
                <Grid size={8}>
                    <FastField
                        autoComplete="new-password"
                        name={"locationdestination." + locationdestinationaddress_mail.name}
                        disabled={props.disabled}
                        sizeClass={customClasses.inputFull}
                        component={FormTextField}
                    />
                </Grid>
            </Grid>
        </Grid>
        <p className={customClasses.footerText}>Dit document is automatisch gegenereerd en daarom niet ondertekend.</p>
    </React.Fragment>
}

TemplateFormFooter.propTypes = {
    disabled: PropTypes.bool.isRequired,
    formField: PropTypes.object.isRequired,
    form_type: PropTypes.string.isRequired
};